import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import HelpBox from "../../UI-component/HelpBopx";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import Privacy from "../_Partials/Privacy";
import DatiSegnalazione from "../_Partials/DatiSegnalazione";
import SegnalazioneRiepilogo from "../_Partials/SegnalazioneRiepilogo";

export default function SegnalazioneDisservizio(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  
  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}> 

        <WorkPath workPathInfo={{
          base: ["Home","https://stellanello.nemea.cloud/"],
          medium: ["Servizi", "http://localhost:3000/"],
          end: "Segnalazione disservizio"
        }}/>

        <div className="container" id="main-container">
          <div className="row justify-content-center" >

            <DynamicStepContent  service={props.code}
              successTitle={"Segnalazione disservizio"}
              successDesc={`
              I campi contraddistinti dal simbolo asterisco sono obbligatori.
              `}
              stepList={["INFORMATIVA SULLA PRIVACY", "DATI DI SEGNALAZIONE", "RIEPILOGO"]} steps={[
              {component:Privacy, info: {
                description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
              gestisce i dati personali forniti e
              liberamente comunicati sulla base dell’articolo 13 del
              Regolamento (UE) 2016/679 General data protection regulation
              (Gdpr) e degli articoli 13 e successive modifiche e
              integrazione del decreto legislativo (di seguito d.lgs)
              267/2000 (Testo unico enti locali).`,
              linkPrivacy: "" + JSON.parse(localStorage.getItem("configuration")).data.current.linkPrivacy,
            }},
              {component:DatiSegnalazione,info: {code: props.code}},
              {component:SegnalazioneRiepilogo,info: {code: props.code}},
            ]}/>

          </div>
        </div>
   
        <HelpBox helpBoxInfo={{
            muniQuestionLink: "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "05 0505",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: ""
          }}/>
    </AuthenticatedTenant>
  );
}


