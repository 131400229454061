import React,{useEffect, useState} from "react";
import SideList from "../../UI-component/SideList";
import { useSelector } from "react-redux";
import { dynamicRenderer } from "../../util/Dynamics";
import ErrorAlert from "../../UI-component/ErrorAlert";

export default function ServiziPagamentoDatiSpecifici(props) {
  const preferencesCtx = useSelector(state => state.stepSlice?.data?.preferenze);
  const indexer = [];
  const [error,setError] = useState({
    school: false,
  });

  // filter the form rendering with the given options
  // build the forms dynamically based on the given data
  // const dynamicRenderer = ({type,title,options}) => {
  //   switch(type) {
  //     case "Select":
  //       return  <SelectionFormBox key={uniqueIdBuilder()}
  //           title={title}
  //           description="Scegli l’istituto a cui vuoi iscrivere il minore"
  //           choices={options}
  //           defaultValue={preferencesCtx.scuola}
  //           />
  //     case "Choice":
  //       return <ChoiceFormBox key={uniqueIdBuilder()} type="radio"
  //         title={title}
  //         description="Indica la tariffa di esonero a cui ha diritto il beneficiario"
  //         choices={options}
  //         defaultValue={preferencesCtx.tariffa_riduzione}
  //         indexer={indexer}
  //         />
  //     case "Checkbox":
  //       return <ChoiceFormBox key={uniqueIdBuilder()} type="checkbox"
  //         title={title}
  //         description="Indica la tariffa di esonero a cui ha diritto il beneficiario"
  //         choices={options}
  //         defaultValue={preferencesCtx.dieta_diversificata}
  //         />
  //     default: break;    
  //   }
  // }

  const errorChecker = () => {
    if((props.info.code === "RTS" || props.info.code === "RMS") 
      && (preferencesCtx.scuola  === "" || preferencesCtx.classe === "")) {
      setError(prev => {
        return {...prev, school: true}
      });
      document.getElementById('step1Next').setAttribute("disabled","disabled");         
    }else {
      setError(prev => {
        return {...prev, school: false}
      });
      document.getElementById('step1Next').removeAttribute("disabled");

    }
  }

  useEffect(() => {
    errorChecker();
    return () => {
      document.getElementById('step1Next').removeAttribute("disabled");
    }
  },[])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Scuola", id: "#school-choice" },
          { element: "Tariffa riduzione", id: "#outings-choice" },
          { element: "Dieta diversificata", id: "#different-diet" },
        ]}
      />

      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              <ErrorAlert errors={[{ name: "Scuola", id: "#isee-info" }]} err={error.school}/>
            }

            {
              props.info.data.components.map(itm => {
                const id = itm.title.replaceAll(" ","_").toLowerCase();
                if(id === "scuola") {
                  return dynamicRenderer(itm,preferencesCtx[id],indexer,"Preferenze",preferencesCtx.classe);
                }
                return dynamicRenderer(itm,preferencesCtx[id],indexer,"Preferenze");
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}
