import React from "react";
import "../../global.css";


function PopupTextField(props) {
    return (
        <div className="form-group cmp-input top-move">
            <label className={`cmp-input__label ${props.defaultValue ? "active" : ""} ${(props.fieldType === "date" || props.fieldType === "file") && "up-lbl"}`}
                htmlFor={props.id}>{props.fieldTitle}</label>
            
            {
                <input style={{fontWeight: "600"}} onBlur={props.onBlur} onChange={props.onChange} type={props.fieldType} 
                    className={`form-control ${props.disabled ? "disabled": ""}`} 
                    id={props.id} name="vehicle-use" required
                    data-dashlane-rid="f6746310e853864c" data-form-type="other" defaultValue={props.defaultValue} itr={props.itr}/> 

            }
            
            <div className="d-flex">
                <span className="form-text cmp-input__text">{props.description ? props.description : "Inserire l’informazione per proseguire con la richiesta"}</span>
            </div>
        </div>
    )

}

export default PopupTextField;

