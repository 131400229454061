import React, { useState } from "react";
import RegistryEditPopup from "../editForms/RegistryEditPopup";
import { titleExtractor, uniqueIdBuilder } from "../../util/Dynamics";

function CustomerRegistry(props) {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState({
    anagrafica: false,
    indirizzi: false,
    contatti: false,
    documenti: false,
  });

  const dynamicRegistryFormBuilder = () => {
    return Object.keys(props.registryData).map((k) => {
      if(k === "type" || k === "selected") return;
      return (
        <div key={uniqueIdBuilder()}>
          <div className="accordion-collapse">
            <div className="accordion-body p-0">
              <div className="cmp-info-summary bg-white has-border">
                <div className="card">
                  <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                    <h4 className="title-large-semi-bold mb-3">{titleExtractor(k)}</h4>

                    {/* TODO TEST */}
                    {
                      titleExtractor(k) === "Anagrafica"  &&
                      <p
                        className="text-decoration-none"
                        onClick={editData}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          data-type={k}
                          className="text-button-sm-semi t-primary"
                        >
                          Modifica
                        </span>
                      </p>

                    }


                  </div>
                  <div className="card-body p-0">
                    {
                      
                      Object.keys(props.registryData[k]).map((i) => {
                        let changed = "";

                        if(i === "Domicilio") 
                          changed = "Comune residenza";

                        if(i === "Residenza")
                          changed = "Indirizzo residenza";

                        if(i === "Cognome")
                          changed = "Cognome (denominazione, rag.sociale)";

                        if(i.includes("Carta"))
                          changed = "Carta di identità";

                          console.log(i.toLowerCase());
                        if(i.toLowerCase().includes("fiscale"))
                          changed = "Codice Fiscale";
                          
                          
                        
                        if(i.includes("base64")) return;
                        return (
                          <React.Fragment key={uniqueIdBuilder()}>
                            <div className="single-line-info border-light">
                              <div className="text-paragraph-small">{changed !== "" ? changed :  titleExtractor(i)}</div>
                              <div className="border-light">
                                <p className="data-text">
                                  {props.registryData[k][i]}
                                </p>
                                {
                                  k.toLocaleLowerCase() === "documenti" ?
                                    props.registryData[k][i] !== "" ?
                                      (
                                        <p className="fw-semibold pb-2 pt-2 data-text description-success d-flex align-items-center">
                                          <span className="d-flex align-items-center">
                                            <svg
                                              className="icon icon-sm icon-success"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle" />
                                            </svg>
                                            Documento inserito con successo
                                          </span>
                                        </p>
                                      ):
                                    
                                      (
                                        <p className="fw-semibold pb-2 pt-2 data-text description-success d-flex align-items-center">
                                          <span className="d-flex align-items-center">
                                            <svg
                                              className="icon icon-sm icon-warning"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets//bootstrap-italia/dist/svg/sprites.svg#it-check-circle" />
                                            </svg>
                                            Documento non inserito
                                          </span>
                                        </p>
                                      ) :
                                      <></>
                                  }
                              </div>
                            </div>
                            <div className="card-footer p-0"></div>
                          </React.Fragment>
                        );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  const showRegistry = () => {
    setShow((prev) => !prev);
  };

  const editData = (e) => {
    switch (e.target.dataset.type) {
      case "anagrafica":
        setEdit((prev) => {
          return {
            ...prev,
            anagrafica: true,
            indirizzi: false,
            contacts: false,
            documents: false,
          };
        });
        break;
      case "indirizzi":
        setEdit((prev) => {
          return {
            ...prev,
            anagrafica: false,
            indirizzi: true,
            contacts: false,
            documents: false,
          };
        });
        break;
      case "contatti":
        setEdit((prev) => {
          return {
            ...prev,
            anagrafica: false,
            indirizzi: false,
            contatti: true,
            documenti: false,
          };
        });
        break;
      case "documenti":
        setEdit((prev) => {
          return {
            ...prev,
            anagrafica: false,
            indirizzi: false,
            contatti: false,
            documenti: true,
          };
        });
        break;
      default:
        setEdit((prev) => {
          return {
            ...prev,
            anagrafica: false,
            indirizzi: false,
            contatti: false,
            documenti: false,
          };
        });
        break;
    }
  };

  const closeEdit = () => {
    setEdit((prev) => {
      return {
        ...prev,
        anagrafica: false,
        indirizzi: false,
        contatti: false,
        documenti: false,
      };
    });
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" id="heading-collapse-benef-1">
        <button
          className="collapsed accordion-button"
          type="button"
          onClick={showRegistry}
        >
          <span className="d-flex align-items-center">
            Mostra tutto
            <svg className="icon icon-primary icon-sm">
              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
            </svg>
          </span>
        </button>
      </div>
      {show && dynamicRegistryFormBuilder()}

      {
        edit.anagrafica &&
          <RegistryEditPopup 
            close={closeEdit}
            id={props.id}
            taxID={props.taxID}
            title={props.title}
            service={props.service}/>
      }
    {/* 
      {edit.indirizzi && <AddressesEditPopup close={closeEdit} id={props.id} taxID={props.taxID} service={props.service}/>}

      {edit.contatti && <ContactEditPopup close={closeEdit} id={props.id} taxID={props.taxID} service={props.service}/>}

      {edit.documenti && <DocumentEditPopup close={closeEdit} id={props.id} taxID={props.taxID} service={props.service}/>} */}
    </div>
  );
}

export default CustomerRegistry;
