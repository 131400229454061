import React, { useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import TabOpener from "../ui-el/TabOpener";
import PopupSelectField from "../ui-el/PopupSelectField";
import { checkValidity, onDataChange2, onSelectData2 } from "../../util/Dynamics";
import stepSlice from "../../context/StepsContext";
import { useDispatch } from "react-redux";


function PropertyEditPopup(props) {
    const dispatcher = useDispatch();
    const [_,setSelection] = useState("");

    const [propertyData,setPropertyData] = useState({
        dati_immobile: {
            tipologia_immobile: props?.immData?.dati_immobile?.tipologia_immobile,
            catastale: props?.immData?.dati_immobile?.catastale,
            rendita_catastale: props?.immData?.dati_immobile?.rendita_catastale,
            percentuale_di_possesso: props?.immData?.dati_immobile?.percentuale_di_possesso,
            numero_conviventi: props?.immData?.dati_immobile?.numero_conviventi,
            immobile_variato: props?.immData?.dati_immobile?.immobile_variato,
            mese_inizio: props?.immData?.dati_immobile?.mese_inizio,
            mese_fine: props?.immData?.dati_immobile?.mese_fine, 
        },
        dati_comunali: {
            immobile_esente: props?.immData?.dati_comunali?.immobile_esente,
            immobile_storico_inagibile: props?.immData?.dati_comunali?.immobile_storico_inagibile,
            riduzione_pensionato_estero: props?.immData?.dati_comunali?.riduzione_pensionato_estero,
            immobile_presente: props?.immData?.dati_comunali?.immobile_presente,
            comune_montano: props?.immData?.dati_comunali?.comune_montano
        },
        pertinenza_1: {
            rendita_catastale: props?.immData?.pertinenza_1?.rendita_catastale,
            percentuale_di_possesso: props?.immData?.pertinenza_1?.percentuale_di_possesso,
            mese_inizio: props?.immData?.pertinenza_1?.mese_inizio,
            mese_fine: props?.immData?.pertinenza_1?.mese_fine,
            immobile_storico_inagibile: props?.immData?.pertinenza_1?.immobile_storico_inagibile
        },
        pertinenza_2: {
            rendita_catastale: props?.immData?.pertinenza_2?.rendita_catastale,
            percentuale_di_possesso: props?.immData?.pertinenza_2?.percentuale_di_possesso,
            mese_inizio: props?.immData?.pertinenza_2?.mese_inizio,
            mese_fine: props?.immData?.pertinenza_2?.mese_fine,
            immobile_storico_inagibile: props?.immData?.pertinenza_2?.immobile_storico_inagibile
        },
        pertinenza_3: {
            rendita_catastale: props?.immData?.pertinenza_3?.rendita_catastale,
            percentuale_di_possesso: props?.immData?.pertinenza_3?.percentuale_di_possesso,
            mese_inizio: props?.immData?.pertinenza_3?.mese_inizio,
            mese_fine: props?.immData?.pertinenza_3?.mese_fine,
            immobile_storico_inagibile: props?.immData?.pertinenza_3?.immobile_storico_inagibile
        }
        
    });
    const [error,setError] = useState(false);

    const dynamicTitleFieldHandler = () => {
        let type = propertyData.dati_immobile.tipologia_immobile; 

        if(type.includes("14")) 
            return "Valore mercato superficie"
        else if (type.includes("15","16","17"))
            return "Reddito dominicale";
        
        return "Rendita catastale";
    }

    const dynamicOptionsdHandler = () => {
        let type = propertyData.dati_immobile.tipologia_immobile; 

        if(type.includes("14","15","16","17","20")) 
            return [];
        else if (type.includes("24")) 
            return ["no","storico","comodato 50% immobile","storico + comodato 50%"];
        
        return ["no","storico","inagibile"];
    }

    const save = () => {
        // if(checkValidity(propertyData)) {
            dispatcher(stepSlice.actions.dynamicEdit({id: "Immobili",internalID: props.immData.id,data: propertyData}));
        // }
        // else
        //     setError(true);
    }

    return (
        <PopupBox close={props.close} save={save} title="Immobile"> 
            {
                error &&
                    <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
            }
            <TabOpener title={"Dati Immobile"}>
                <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_immobile",setPropertyData,setSelection)}}
                    defaultValue={propertyData?.dati_immobile.tipologia_immobile} id="tipologia_immobile" fieldTitle={"Tipologia immobile"} 
                    options={
                    [
                        "0 - Abitazione principale e assimilate (Cat. da A2 ad A7) e pertinenze (C/2, C/6, C/7)", 
                        "1 - Abitazione principale di pregio (Cat. A/1, A/8, A/9) e pertinenze (C/2, C/6, C/7)", 
                        "24 - Abitazione concessa in comodato gratuito a condizioni di Legge (riduzione 50% base imponibile)", 
                        "2 - Abitazioni assegnate dagli Istituti Autonomi Case Popolari (ex IACP/ARES/ALER)", 
                        "25 - Immobili locati a canone concordato (riduzione al 75%)", 
                        "3 - Altre abitazioni - immobili Cat. A (tranne A/10)", 
                        "4 - Cat. A/10 - Uffici e studi privati", 
                        "5 - Cat. C/1 - Negozi e botteghe", 
                        "6 - Cat. C/2 - Magazzini e locali di deposito", 
                        "7 - Cat. C/3 - Laboratori per arti e mestieri", 
                        "8 - Cat. B, C/4, C/5 - Fabbricati comuni", 
                        "9 -  Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie", 
                        "10 -  Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali", 
                        "11 - Cat. D/5 - Istituti di credito ed assicurazioni",
                        "12 -  Fabbricati rurali ad uso strumentale allattivita agricola (D/10)", 
                        "13 - Fabbricati rurali ad uso strumentale allattivita agricola (Cat. A, C/2, C/6, C/7)", 
                        "20 - Beni Merce - Realizzati da Imprese Edili, destinati alla vendita e rimasti invenduti", 
                        "14 - Aree fabbricabili", 
                        "15 - Terreni", 
                        "16 - Terreni agricoli", 
                        "17 - Terreni agricoli posseduti e condotti da operatori iscritti a previdenza agricola"
                    ]
                    }/>
                <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_immobile",setPropertyData,setSelection)}}
                    id="catastale" fieldTitle={"Catastale"} defaultValue={propertyData?.dati_immobile.catastale}
                    disabled={propertyData?.dati_immobile?.tipologia_immobile.includes("20") ? false : true} 
                    description={propertyData.dati_immobile.tipologia_immobile.includes("20") ? "" : " "}
                    options={
                    [
                        "3 - Altre abitazioni - immobili Cat. A (tranne A/10)", 
                        "4 - Cat. A/10 - Uffici e studi privati", 
                        "5 - Cat. C/1 - Negozi e botteghe", 
                        "6 - Cat. C/2 - Magazzini e locali di deposito", 
                        "7 - Cat. C/3 - Laboratori per arti e mestieri", 
                        "8 - Cat. B, C/4, C/5 - Fabbricati comuni", 
                        "9 -  Cat. C/6, C/7 - Stalle, scuderie, rimesse, autorimesse - Tettoie", 
                        "10 -  Cat. D, tranne D/5 e D/10 - Immobili industriali e commerciali", 
                        "11 - Cat. D/5 - Istituti di credito ed assicurazioni",
                    ]
                    }/>
                <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                    defaultValue={propertyData?.dati_immobile.numero_conviventi} id="numero_conviventi"
                    fieldType="number" fieldTitle="Numero conviventi"
                    disabled={propertyData?.dati_immobile.numero_conviventi.includes("1") ? true : false}/>
                <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} defaultValue={propertyData?.dati_immobile.rendita_catastale}
                    fieldType="number" fieldTitle={`${dynamicTitleFieldHandler()}`} id={`${dynamicTitleFieldHandler()}`} itr="dati_immobile"/>
                <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                    defaultValue={propertyData?.dati_immobile.percentuale_di_possesso} id="percentuale_di_possesso" 
                    fieldType="text" fieldTitle="Percentuale di possesso" itr="dati_immobile"/>
                <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                    defaultValue={propertyData?.dati_immobile.mese_inizio} id="mese_inizio" 
                    fieldType="date" fieldTitle="Mese inizio" itr="dati_immobile"/>
                <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                    defaultValue={propertyData?.dati_immobile.mese_fine} id="mese_fine" 
                    fieldType="date" fieldTitle="Mese fine" itr="dati_immobile"/>
            </TabOpener>
            <TabOpener title={"Dati Comunali"}>
                {

                    propertyData?.dati_comunali?.comune_montano === 0 || propertyData?.dati_comunali?.comune_montano === 1 ?
                            <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_comunali",setPropertyData,setSelection)}}
                                defaultValue={""} id="comune_montano" fieldTitle={"Comune montano"}
                                options={[]}/> :
                            <PopupSelectField 
                                onChange={(e) => {onSelectData2(e,"dati_comunali",setPropertyData,setSelection)}}
                                defaultValue={propertyData.dati_comunali.comune_montano} id="comune_montano" fieldTitle={"Comune montano"}
                                options={
                                    [
                                        "si",
                                        "no"
                                    ]
                                }/>    
                    }

                    <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_comunali",setPropertyData,setSelection)}}
                        id="immobile_presente" fieldTitle={"Immobile presente"} defaultValue={propertyData.dati_comunali.immobile_presente}
                        options={
                        [
                            "presente",
                            "non presente"
                        ]
                        }/>


                    <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_comunali",setPropertyData,setSelection)}}
                        defaultValue={propertyData.dati_comunali.immobile_esente} id="immobile_esente" fieldTitle={"Immobile esente"}
                        options={
                            [
                                "Esente",
                                "Non esente"
                            ]
                        }/>
                    <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_comunali",setPropertyData,setSelection)}} 
                        defaultValue={propertyData.dati_comunali.immobile_storico_inagibile} id="immobile_storico_inagibile" 
                        fieldTitle={"Immobile storico inagibile"} options={dynamicOptionsdHandler()}/>
                    <PopupSelectField onChange={(e) => {onSelectData2(e,"dati_comunali",setPropertyData,setSelection)}} 
                            defaultValue={propertyData.dati_comunali.riduzione_pensionato_estero} id="riduzione_pensionato_estero" 
                            fieldTitle={"Riduzione pensionato estero"} 
                            disabled={propertyData.dati_immobile.tipologia_immobile.includes("3") ? false : true}
                            description={propertyData.dati_immobile.tipologia_immobile.includes("3") ? "" : " "}
                            options={
                                propertyData.dati_immobile.tipologia_immobile.includes("3") ?
                                [
                                    "si",
                                    "no"
                                ] :
                                []
                }/>
            </TabOpener>

            {
                propertyData?.dati_immobile.tipologia_immobile.includes("0") ||
                propertyData?.dati_immobile.tipologia_immobile.includes("1") ||
                propertyData?.dati_immobile.tipologia_immobile.includes("24") ||
                propertyData?.dati_immobile.tipologia_immobile.includes("25") ?
                    <>
                        <TabOpener title="Pertinenza C/2">
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_1.rendita_catastale} itr="pertinenza_1"
                                id="rendita_catastale" fieldType="number" fieldTitle="Rendita catastale"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_1.percentuale_di_possesso}  itr="pertinenza_1"
                                id="Percentuale di possesso" fieldType="number" fieldTitle="Percentuale di possesso"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)}  itr="pertinenza_1"
                                defaultValue={propertyData?.pertinenza_1.mese_inizio} id="mese_inizio" 
                                fieldType="date" fieldTitle="Mese inizio"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_1.mese_fine} id="mese_fine" itr="pertinenza_1"
                                fieldType="date" fieldTitle="Mese fine"/>
                            <PopupSelectField onChange={(e) => {onSelectData2(e,"pertinenza_1",setPropertyData,setSelection)}} 
                                defaultValue={propertyData?.pertinenza_1.immobile_storico_inagibile}
                                id="Immobile_storico_inagibile" fieldTitle={"Immobile storico inagibile"} options={
                                [
                                    "storico",
                                    "inagibile",
                                    "no"
                                ]
                            }/>
                        </TabOpener>
                        <TabOpener title="Pertinenza C/6">
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_2.rendita_catastale}  itr="pertinenza_2"
                                id="rendita catastale" fieldType="number" fieldTitle="Rendita catastale"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_2.percentuale_di_possesso}  itr="pertinenza_2"
                                id="Percentuale Di possesso" fieldType="number" fieldTitle="Percentuale di possesso"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_2.mese_inizio}  itr="pertinenza_2"
                                id="mese_inizio" fieldType="date" fieldTitle="Mese inizio"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_2.mese_fine}  itr="pertinenza_2"
                                id="mese_fine" fieldType="date" fieldTitle="Mese fine"/>
                            <PopupSelectField onChange={(e) => {onSelectData2(e,"pertinenza_2",setPropertyData,setSelection)}} 
                                defaultValue={propertyData?.pertinenza_2.immobile_storico_inagibile}
                                id="immobile storico_inagibile" fieldTitle={"Immobile storico inagibile"} options={
                                [
                                    "storico",
                                    "inagibile",
                                    "no"
                                ]
                            }/>
                        </TabOpener>
                        <TabOpener title="Pertinenza C/7">
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_3.rendita_catastale}  itr="pertinenza_3"
                                id="Rendita_catastale" fieldType="number" fieldTitle="Rendita catastale"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_3.percentuale_di_possesso}  itr="pertinenza_3"
                                id="Percentuale di Possesso" fieldType="number" fieldTitle="Percentuale di possesso"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_3.mese_inizio}  itr="pertinenza_3"
                                id="mese_inizio" fieldType="date" fieldTitle="Mese inizio"/>
                            <PopupTextField onChange={(e) => onDataChange2(e,setPropertyData)} 
                                defaultValue={propertyData?.pertinenza_3.mese_fine}  itr="pertinenza_3"
                                id="mese_fine" fieldType="date" fieldTitle="Mese fine"/>
                            <PopupSelectField onChange={(e) => {onSelectData2(e,"pertinenza_3",setPropertyData,setSelection)}} 
                                defaultValue={propertyData?.pertinenza_3.immobile_storico_inagibile}
                                id="immobile_storico inagibile" fieldTitle={"Immobile storico inagibile"} options={
                                [
                                    "storico",
                                    "inagibile",
                                    "no"
                                ]
                            }/>
                        </TabOpener>
                    </> : 
                    <></>
            }
        </PopupBox>
    )
}

export default PropertyEditPopup;