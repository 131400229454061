import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy"; 
import PagamentiIMUDatiPersonali from "../_Partials/PagamentiIMUDatiPersonali";
import PagamentiIMURiepilogo from "../_Partials/PagamentiIMURiepilogo";
import PagamentiIMUAnteprimaF24 from "../_Partials/PagamentiIMUAnteprimaF24";
import PagamentiIMUPreferenze from "../_Partials/PagamentiIMUPreferenze";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";


export default function PagamentoIMU(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const location = useLocation();
  const dispatcher = useDispatch();

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }

    if(location.state) {
      console.log(location.state.data)
      dispatcher(stepSlice.actions.onDraftData({data: location.state.data}));
    }
  }, []);
 
  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      {/* primry work path in the page ex: Home/ services/ payments */}
      <WorkPath workPathInfo={{
        base: ["Home","https://stellanello.nemea.cloud/"],
        medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
        end: "Pagamento tributi IMU"
      }}/>


      <div className="container" id="main-container">
        <div className="row justify-content-center">
          {/* dinamically creates the pages giving them step properties */}
          <DynamicStepContent service={props.code}
            successTitle={"Pagamento tributi IMU"}
            successDesc={`
                Inserire le informazioni necessarie a scaricare l’F24 per il pagamento dell’IMU.<br />
                Potrai ricontrollare tutti i dati nel riepilogo, prima di
                inviare la richiesta.`}
            stepList={["INFORMATIVA SULLA PRIVACY","DATI GENERALI","RIEPILOGO","PREFERENZE","ANTEPRIMA F24"]} steps={[
            {component:Privacy,info: {user,description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
            gestisce i dati personali forniti e
            liberamente comunicati sulla base dell’articolo 13 del
            Regolamento (UE) 2016/679 General data protection regulation
            (Gdpr) e degli articoli 13 e successive modifiche e
            integrazione del decreto legislativo (di seguito d.lgs)
            267/2000 (Testo unico enti locali).`,
            linkPrivacy: "" + JSON.parse(localStorage.getItem("configuration")).data.current.linkPrivacy,
          }},
            {component:PagamentiIMUDatiPersonali,info: {user,code: props.code}},
            {component:PagamentiIMURiepilogo,info: {user,code: props.code}},
            {component:PagamentiIMUPreferenze,info: user},
            {component:PagamentiIMUAnteprimaF24,info: user},
          ]}/>
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
