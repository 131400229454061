import React,{useState,useEffect} from "react";
import StepHeader from "./StepHeader";
import BackForwardButton from "./ui-el/BackForwardButton";
import SavingForm from "./SavingForm";
import { ServiceRequestAPI } from "../Apis/ServiceRequestAPI";
import { useDispatch, useSelector } from "react-redux";
import SuccessfullBox from "../UI-component/SuccessfullBox";
import { uniqueIdBuilder } from "../util/Dynamics";
import CompactInfo from "./CompactInfo";
import SuccessfullPopup from "./SuccessfullPopup";
import stepSlice from "../context/StepsContext";

function DynamicStepContent(props) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data);
    const [step, setStep] = useState({
        currentStep: 0,
      });
    const [email,setEmail] = useState("");
    const [success,setSuccess] = useState(false);
    const [saved,setSaved] = useState(false);
    const [fail,setFail]  = useState(false);
    const [link,setLink] = useState("");

    const [successData,setSuccessData] = useState({
        title: "",
        message: ""
    })

    const onNext = () => {
        setStep(prev => {
            return {
                currentStep: prev.currentStep + 1
            } 
        });

        if(props.service === "SSD" && props.currentStep === 1)
            dispatcher(stepSlice.actions.switchError({error: true}))
    }

    const onBack = () => {
        setStep(prev => {
            return {
                currentStep:  prev.currentStep > 0 ? prev.currentStep - 1 : prev.currentStep
            } 
        });
    }

    const saveRequest = async () => {
        try{
            const res = await ServiceRequestAPI.sendServiceRequest(
                { service_id: props.service, status: "Bozza", data: ctx },
                false
              );
            
          if(!res.success) return; 
        //   alert("La richiesta e' stata salvata correttamente");
        setSaved(true);
        reset();
        }catch(err) {
            setFail(true);
            reset();

            console.log(err);
        }
    }

    const send = async () => {
        try{ 
            if(props.service === "PTI") {
                setSuccess(true);
            }

            const res = await ServiceRequestAPI.sendServiceRequest(
                { service_id: props.service, status: "Inviato", data: ctx },
                false
            );
            
          if(!res.success) {
            setFail(true);
            reset();
            return;  
          }

          setSuccess(true);

          if(res.data?.receipt)
            setLink(res.data.receipt);

          //set the success data
          setSuccessData(prev => {
            return {...prev, title: res.data.title,message: res.data.message};
          })
        }catch(err) {
            setFail(true);
            reset();
        }
    }

    const findEmail = () => {
        let email = "";
        switch(props.service) {
            case 'RTS':
                for(let i of ctx.richiedente) {
                    if(i.selected) 
                        email = i?.contatti?.Email;
                }
                break;
            case 'RMS':
                for(let i of ctx.richiedente) {
                    if(i.selected)
                        email = i?.contatti?.Email;
                }
                break;
            case 'PTI':
                for(let i of ctx.imu?.contribuente) {
                    if(i.selected) 
                        email = i.contatti?.Email;
                }
                break;
            case 'SSD': 
                for(let i of ctx.autore_della_segnalazione) {
                    if(i.selected) 
                    email = i?.contatti?.Email;
                }
                break;
            case 'SPA': 
                email = ctx.ufficio?.richiedente?.Email;
                break;
            default : 
                break;
        }

        setEmail(email);
    }

    useEffect(() => {
        findEmail();
    },[ctx]);

    useEffect(() => {
        window.scroll(0,0);
    },[step])

    const reset = () => {
        setTimeout(() => {
            setFail(false);
            setSaved(false);
        }, 5000);
    }
    
    return (
        <>
            {
                step.currentStep < props.steps.length - 2  &&
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <CompactInfo title={props.successTitle} description={props.successDesc}/>
                        </div>
                    </div>
            }
            
            {
                success ?
                    <SuccessfullBox 
                        title={props.successTitle}
                        description={props.successDesc}
                        emailToSend={email}
                        service={props.service} link={link}
                        successData={successData} /> :
                    <>
                        <StepHeader step={step?.currentStep} stepList={props.stepList}/>

                        {
                            props.steps.map((itm,idx) => {
                                if(step.currentStep === idx) {
                                    if(typeof itm.component !== "function")
                                        return itm.component;
                                        
                                    if(itm?.info)
                                        return <itm.component info={itm.info} key={uniqueIdBuilder()}/>
                                    else
                                        return <itm.component info={itm.info} key={uniqueIdBuilder()}/>
                                }
                            })
                        }
                        <div className={`ttt ${step?.currentStep === (props.stepList.length - 1) ? "ending" : "" }`}>
                            <div className={`${step.currentStep === props.steps.length - 1 ? "btn-spacer-reduct" : "btn-spacer"}`}>
                                {/* the button on the bottom of the page when we can go forward or backwards */}
                                <BackForwardButton 
                                    noPriv={props.noPriv} 
                                    onNext={onNext} 
                                    onBack={onBack} 
                                    step={step.currentStep} 
                                    totalSteps={props.steps.length} 
                                    service={props.service}/>
                                <SavingForm 
                                    saveRequest={saveRequest} 
                                    send={send} 
                                    step={step.currentStep} 
                                    totalSteps={props.steps.length}/>
                            </div>
                        </div>
                    </>
            }
            {
                saved &&
                    <SuccessfullPopup/>
            }

            {
                fail &&
                    <SuccessfullPopup isError={true}/>

            }
        </>
    )
}


export default DynamicStepContent;