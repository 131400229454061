import { apiAuth } from "./Configs/AxiosConfigs"
import { defineCancelApiObject } from "./Configs/AxiosUtils"

export const OfficeAPI = {

    getOffice: async function (cancel = false) {
        const response = await apiAuth.request({
          url: "/office/",
          method: "GET",
          signal: cancel ? cancelApiObject[this?.getOffice?.name]?.handleRequestCancellation()?.signal : undefined,
        })  
        return response.data;
      },

      getOffices: async function () {
        const response = await apiAuth.request({
          url: "/offices/",
          method: "GET",
          // signal: cancel ? cancelApiObject[this?.getOffices?.name]?.handleRequestCancellation()?.signal : undefined,
        })  
        return response.data;
      },

      getOfficesById: async function (id) {
        const response = await apiAuth.request({
          url: `/offices/${id}`,
          method: "GET",
        })  
        return response.data;
      },

      getAppointments: async function (officeID,monthID) {
        const response = await apiAuth.request({
          url: `/get-appointment?office_id=${officeID}&day_of_year=${monthID}`,
          method: "GET",
        })  
        return response.data;
      },

      getReasons: async function (officeID) {
        const response = await apiAuth.request({
          url: `/reason-by-office/${officeID}`,
          method: "GET",
        })  
        return response.data;
      },


 
}

// defining the cancel API object for OfficeAPI
const cancelApiObject = defineCancelApiObject(OfficeAPI)