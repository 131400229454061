import React, { useEffect, useState } from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import IseeFormBox from "../../UI-component/registryForms/IseeFormBox";
import SideList from "../../UI-component/SideList";
import ErrorAlert from "../../UI-component/ErrorAlert";
import { useSelector,useDispatch } from "react-redux";
import { uniqueIdBuilder } from "../../util/Dynamics";
import stepSlice from "../../context/StepsContext";

export default function ServiziPagamentoDatiPersonali(props) {
  const dispatcher = useDispatch();
  const dataCtx = useSelector(state => state.stepSlice.data);
  const [error,setError] = useState({
    richiedente: false,
    beneficiario: false,
    isee: false
  });

  // Dynamically renders the box, checking if 
  // any data resides in the context. If data 
  // is present in the context it is gonna retrieve that.
  // "It is gonna be swapping between api data and context data"
  const dyanmicRenderer = (itm) => {
    switch(itm.type) {
      case "Person":
        return(
          <CustomerFormBox 
            key={uniqueIdBuilder()}
            id={itm?.title}
            cards={dynamicData(itm?.title)}
            description={itm?.title === "Beneficiario" ? "Informazioni sulla persona che utilizzerà il servizio" : "Informazioni su di te"}
            type={false}
            title={itm?.title}
            service={props.info.code}
          />
        )
      case "Isee": 
        return (
          <IseeFormBox 
            id={itm?.title}
            cards={dynamicData(itm?.title)}
            key={uniqueIdBuilder()}/>
        )
      default: 
        return <></>;
    }
  }
  // Dynamically checks the key of the given object
  // and return the context object filtered by the title
  const dynamicData = (title) => {
    return Object.keys(dataCtx).map(key => {
      if(dataCtx[key] !== undefined && dataCtx[key] !== [] ) {
        if(title.toLowerCase() === key) {
          if(Array.isArray(dataCtx[key])) 
            return dataCtx[key].map(itm => itm)
          else
            return dataCtx[key];
        }
      }
    })
  }

  const errorChecker = () => {    
    let isError = 0;

    for(let i of dataCtx?.richiedente) {
      if(i.selected) {
        setError(prev => {
          return {...prev, richiedente: true};
        })
        isError += 1;
        break;
      }
    }

    for(let i of dataCtx?.beneficiario) {
      if(i.selected) {
        setError(prev => {
          return {...prev, beneficiario: true};
        })
        isError += 1;

        break;
      } 
    }

    // if(dataCtx.isee.length <= 0) {
    //   setError(prev => {return {...prev,isee: false}})
    // }else
    // {
    //   setError(prev => {return {...prev,isee: true}})
    //   isError +=1;
    // }

    const err =  isError === 2 ? false : true;
  
    dispatcher(stepSlice.actions.switchError({error: err}))
  }

  useEffect(() => {
    errorChecker();
  },[dataCtx])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#applicant-info" },
          { element: "Benediciario", id: "#beneficiary-info" },
          { element: "ISEE", id: "#isee-info" },
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1">
          {
              <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={!error.richiedente}/>
          }

          {
              <ErrorAlert errors={[{ name: "Beneficiario", id: "#isee-info" }]} err={!error.beneficiario}/>
          }

          <ErrorAlert errors={[{ name: "ISEE", id: "#isee-info" }]} />

        <div className="it-page-sections-container">
          {
            props.info.data.components.map(itm => dyanmicRenderer(itm))
          }
        </div>
      </div>
    </div>
  );
}
