import React, { useState } from "react";
import ReactDOM  from "react-dom";

import PopupTextField from "../ui-el/PopupTextField";
import PopupBox from "../PopupBox";
import "../../global.css";

import stepSlice from "../../context/StepsContext";
import { useDispatch } from "react-redux";
import { checkValidity, onDataChange2, onDirectDataChange, onFileSet2 } from "../../util/Dynamics";

// Popup that gets created to add to that specific form box
function IseeAddPopup(props) {
    const dispatcher = useDispatch();
    const [error,setError] = useState(false);

    const [iseeData,setIseeData] = useState({
        valore_isee: '',
        scadenza_isee: "",
        anno_protocollo: "",
        numero_protocollo: "",
        codice_filiale_protocollo: "",
        base64_certificato_isee: "",
        certificato_isee: "",
        selected: true
    });

    const save = () => {
        if(checkValidity(iseeData,true)) {
            dispatcher(stepSlice.actions.dynamicSave({id: "ISEE",data: iseeData}));
        }
        else
            setError(true);
    }

    return (
        <>
            <PopupBox close={props.close} save={save} title={"ISEE"}> 
                {
                    error &&
                        <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                }
                <PopupTextField 
                    onChange={(e) => {onDirectDataChange(e,setIseeData)}}
                    fieldType="number" 
                    id="valore_isee"
                    fieldTitle="Valore ISEE*"/>
                <PopupTextField 
                    onChange={(e) => {onDirectDataChange(e,setIseeData)}}
                    fieldType="date"
                    id="scadenza_isee"
                    fieldTitle="Scadenza ISEE*"/>
                <PopupTextField 
                    onChange={(e) => {onDirectDataChange(e,setIseeData)}}
                    fieldType="number"
                    id="anno_protocollo"
                    fieldTitle="Anno protocollo*"/>
                <PopupTextField 
                    onChange={(e) => {onDirectDataChange(e,setIseeData)}}
                    fieldType="number"
                    id="numero_protocollo"
                    fieldTitle="Numero protocollo*"/>
                <PopupTextField 
                    onChange={(e) => {onDirectDataChange(e,setIseeData)}}
                    fieldType="text"
                    id="codice_filiale_protocollo"
                    fieldTitle="Codice filiale protocollo*"/>
                <PopupTextField 
                    onChange={(e) => {onFileSet2(e,setIseeData)}} 
                    fieldType="file"
                    id={"certificato_isee"}  
                    fieldTitle={"Certificato ISEE*"}/>
            </PopupBox>
        </>
    )
}

const IseeAddPopupPortal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<IseeAddPopup 
                    close={props.close} id={props.id} typeData={props.typeData} service={props.service}/>,
                    document.getElementById("overlay-root"))
            }
        </React.Fragment>
    )
}

export { IseeAddPopupPortal as IseeAddPopup };