import React, { useEffect, useRef } from "react";
import { uniqueIdBuilder } from "../util/Dynamics";


function ErrorAlert(props) {
    const refDiv = useRef(null);

    const switchClass = () => {      
      if(props.err) {
        refDiv.current.classList.remove("popping-out");
        refDiv.current.classList.add("popping-up");
      }else {
        refDiv.current.classList.remove("popping-up");   
        refDiv.current.classList.add("popping-out");
      }
    }   

    useEffect(() => {
      switchClass();
    },[props.err]);

    return (
        <div ref={refDiv} className={`cmp-warning-box border-start border-warning border-2 mb-50`}>
          <div className="warning-box-icon">
            <svg className="icon icon-warning" aria-hidden="true">
              <use
                href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-info-circle"
                xlinkHref="../assets/bootstrap-italia/dist/svg/sprites.svg#it-warning"
              />
            </svg>
            <span className="t-alert title-small-semi-bold">ATTENZIONE</span>
          </div>
          <div className="d-flex">
            <p className="description description-warning mb-1">
              Ci sono alcune informazioni mancanti o errate
            </p>
          </div>

          <ul>
            {
                props.errors.map(error => {
                    return (
                        <React.Fragment key={uniqueIdBuilder()}>
                            <p className="t-primary subtitle-small pb-1 pt-1" style={{color: "#a66300"}}>
                                {error.name}
                            </p>
                            <br/>           
                        </React.Fragment>
                    )
                })
            }
          </ul>
        </div>
    )
}

export default ErrorAlert;