import ChoiceFormBox from "../UI-component/ChoiceFormBox";
import SelectionFormBox from "../UI-component/SelectionFormBox";


//dinamically filters the data and sets the 
//data based on the given function.
//returns the modofied objects
//used to edit purposes
export const onDataChange2 = (e,func) => {
    const id = titleNormalizer(e.target.id);
    const itr = e.target.getAttribute("itr");

    func(prev => {
        let modified = {...prev};
        
        Object.keys(modified).map(key => {
          Object.keys(modified[key]).map(iKey => {
            if(id === titleNormalizer(iKey)) {

              if(id === "codicefiscale") {
                const reg = new RegExp("^[a-zA-Z]{6}[a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$");

                if(reg.test(e.target.value)) 
                  modified[key][iKey] = e.target.value;
              }else if(id === "email") {
                const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

                if(reg.test(e.target.value)) 
                  modified[key][iKey] = e.target.value;
              }else {

                if(itr)
                  modified[itr][iKey] = e.target.value;
                else
                  modified[key][iKey] = e.target.value;
              }
            }
          })
        })

        return modified;
    })
}

//dinamically filters the data and sets the 
//data based on the given function.
//returns the modofied objects
//used to edit purposes
export const onDirectDataChange = (e,func) => {
  const id = titleNormalizer(e.target.id);

  func(prev => {
      let modified = {...prev};
      Object.keys(modified).map(key => {
        if(id === titleNormalizer(key)) {

          if(titleNormalizer(id) === "email") {
            const reg = new RegExp(`[\\w\\D]+.[\\w\\D]+@[\\w\\D]{3,20}[.][a-zA-Z\\D]{2,10}`);

            if(reg.test(e.target.value)) 
              modified[key] = e.target.value;
          }else 
            modified[key] = e.target.value;
        }
      });

      return modified;
  })
}

//dinamically filters the data and sets the 
//data based on the given function.
//returns the modofied objects
//used to (selection) edit purposes
export const onSelectData2 = (e,mainID,func,selectFunc) => {
    func(prev => {
        const newObj = {...prev};

        if(newObj[mainID])
          newObj[mainID][e.target.id] = e.target.options[e.target.selectedIndex].text;
        else
          newObj[e.target.id] = e.target.options[e.target.selectedIndex].text;

        return newObj;
    })
    

    selectFunc(e.target.options[e.target.selectedIndex].text);
}

export const onFileSet2 = async (e,func) => {
  let base64File = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
  });

  const file = await base64File;

  func(prev => {
    const newObj = {...prev};

    if(e.target.id.includes("isee")) {
      const id = `base64_${titleNormalizer(e.target.id)}`;
      newObj[id] = file;
      newObj[e.target.id] = e.target.files[0].name;
      
      return newObj;
    }

    if(e.target.id === "immagini") {
      const id = `base64_${titleNormalizer(e.target.id)}`;
      newObj["disservizio"][id] = file;
      newObj["disservizio"][e.target.id] = e.target.files[0].name;
      return newObj;
    }

    const id = `base64_${titleNormalizer(e.target.id)}`;
    newObj["documenti"][id] = file;
    newObj["documenti"][e.target.id] = e.target.files[0].name;
    
    return newObj;
  });

    // dispatcher(stepSlice.actions.dynamicSave({id: "disservizio",internalId: id, data: disserviceData.disservizio[id.toLowerCase()]})); 
}

// filter the form rendering with the given options
// build the forms dynamically based on the given data
export const dynamicRenderer = ({type,title,options,description = ""},defaultValue,indexer = [],oKey,classe) => {
  switch(type) {
    case "Select":
    return  <SelectionFormBox key={uniqueIdBuilder()}
          showTitle={title + "*"}
          title = {title}
          description = {description}
          choices = {options}
          defaultValue = {defaultValue}
          classDefaultValue={title === "SCUOLA" ? classe : ""}
          oKey={oKey}
          />
    case "Choice":
      return <ChoiceFormBox key={uniqueIdBuilder()} type="radio"
        title = {title}
        description = {description}
        choices={options}
        defaultValue = {defaultValue}
        indexer = {indexer}
        />
    case "Checkbox":
      return <ChoiceFormBox key={uniqueIdBuilder()} type="checkbox"
        title = {title}
        description = {description}
        choices = {options}
        defaultValue = {defaultValue}
        />
    default: break;    
  }
}

//builds a unique identifier
//used for uniqueness of components
export const uniqueIdBuilder = () => {
  return parseInt(Date.now() * ((Math.random() * 31) * Math.random() * 9));
}

//normalizes the given string
//so that can be used for filtering purposes
export const titleNormalizer = (title) => {
  return title.replaceAll(" ", "_").toLowerCase();
}

export const titleExtractor = (title) => {
  if(title === "CodiceFiscale")
    return "Codice fiscale";

    if(title) {
      const extractedTitle = title.replaceAll("_", " ").toLowerCase();
      return extractedTitle[0].toUpperCase() + extractedTitle.slice(1);;
    }
    return;
}

//checks the validity of an object
//used for (form) purposes
export const checkValidity = (obj, half = false) => {
  let res = true;

  if(half){
    Object.keys(obj).forEach(key => {
        if(obj[key] === "") 
          res = false;
    })
    return res;
  }

  Object.keys(obj).forEach(key => {
    Object.keys(obj[key]).forEach(itm => {
      if(itm.includes("disabilita") || itm.includes("dieta")) {
      }else {
        if(obj[key][itm] === "") 
          res = false;
      }
    })
  })

  return res;
}


// const onMultiChange = (e) => {
//   let modified = [];

//   for(let i of e.target.options) {
//       if(i.selected) 
//           modified.push(i.text);
//   }

//   setData(prev => {
//       const newObj = {...prev};
//       newObj[e.target.id] = modified;

//       return newObj;
//   })
// }
