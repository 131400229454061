import React from "react";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import { useSelector } from "react-redux";
import { uniqueIdBuilder } from "../../util/Dynamics";

export default function PagamentiIMURiepilogo(props) {

  const ctx = useSelector(state => state.stepSlice.data);


  return (
    <div className="row">
    <div className="col-12 col-lg-8 offset-lg-4">
      <div className="steppers-content" aria-live="polite">
        <div className="it-page-sections-container">
        <h2 className="title-xxlarge mb-4 mt-40">Dati generali</h2>

          {
            ctx.imu.contribuente.map(ctr => {
              if(ctr.selected) {
                return (
                  <SummaryCard key={uniqueIdBuilder()} mod={true} cardTitle={ctr.anagrafica.Nome +" " +  ctr.anagrafica.Cognome} 
                    type="Contribuenti" taxID={ctr.anagrafica.CodiceFiscale} service={props.info.code}>
                    <FormBoxField title="Nome" value={ctr.anagrafica.Nome} />
                    <FormBoxField title="Cognome" value={ctr.anagrafica.Cognome} />
                    <FormBoxField title="Codice Fiscale" value={ctr.anagrafica.CodiceFiscale} />
                    <FormBoxField title="Data di nascita" value={ctr.anagrafica["Data Di Nascita"]} />
                    <FormBoxField title="Luogo di nascita" value={ctr.anagrafica["Luogo Di Nascita"]} />
                    <FormBoxField title="Telefono" value={ctr.contatti.Telefono} />
                    <FormBoxField title="Email" value={ctr.contatti.Email} />
                    <FormBoxField title="Indirizzo residenza" value={ctr.indirizzi.Residenza} />
                    <FormBoxField title="Comune residenza" value={ctr.indirizzi.Domicilio} />
                    <FormBoxField title="Carta di identità" value={ctr.documenti["Carta Di Identita"]} />
                  </SummaryCard>
                )
              }
            })
          }
      
        <SummaryCard mod={true} cardTitle="" type="Coobligato" 
          cobData={{codice_fiscale: ctx.imu.coobbligato?.codice_fiscale, identificativo_coobbligato: ctx.imu.coobbligato?.identificativo_coobbligato}}>
          <FormBoxField title="Codice Fiscale" value={ctx.imu.coobbligato?.codice_fiscale || "Non Applicato"} />
          <FormBoxField title="Identificativo coobligato" value={ctx.imu.coobbligato?.identificativo_coobbligato || "Non Applicato"} />
        </SummaryCard>

        {
          ctx.imu.immobili.map(ctr => {
            const itm = ctr.dati_immobile;
              return (
                <SummaryCard 
                key={uniqueIdBuilder()}
                  mod={true} 
                  cardTitle={JSON.parse(localStorage.getItem("configuration"))?.data?.current?.description}
                  type="Immobili" immData={ctr}>
                  <FormBoxField title="Tipologia immobile" value={itm?.tipologia_immobile} />
                  {
                    itm?.catastale !== "" &&
                      <FormBoxField title="Catastale" value={itm?.catastale} />
                  }
                  <FormBoxField title="Numero conviventi" value={itm?.numero_conviventi} />
                  <FormBoxField title="Rendita catastale" value={itm?.rendita_catastale} />
                  <FormBoxField title="Percentuale di possesso" value={itm?.percentuale_di_possesso} />
                  <FormBoxField title="Immobile variato" value={itm?.immobile_variato} />
                  <FormBoxField title="Mese inizio" value={itm?.mese_inizio} />
                  <FormBoxField title="Mese fine" value={itm?.mese_fine} />

                  {
                    ctr.pertinenza_1.rendita_catastale !== "" &&
                      <div key={uniqueIdBuilder()}>
                         <div>
                            <h6 style={{color: "black", padding: "20px",fontSize: "25px", borderBottom: "solid 1px lightgrey",textAlign: "left"}}>Pertinenza 1</h6>
                        </div>
                        <FormBoxField title="Rendita catastale" value={ctr.pertinenza_1?.rendita_catastale} />
                        <FormBoxField title="Percentuale di possesso" value={ctr.pertinenza_1?.percentuale_di_possesso} />
                        <FormBoxField title="Mese inizio" value={ctr.pertinenza_1?.mese_inizio} />
                        <FormBoxField title="Mese fine" value={ctr.pertinenza_1?.mese_fine} />
                        <FormBoxField title="Immobile storico inagibile" value={ctr.pertinenza_1?.immobile_storico_inagibile} />

                      </div>
                  }

                  {
                    ctr.pertinenza_2.rendita_catastale !== "" &&
                      <div key={uniqueIdBuilder()}>
                         <div>
                            <h6 style={{color: "black", padding: "20px",fontSize: "25px", borderBottom: "solid 1px lightgrey",textAlign: "left"}}>Pertinenza 1</h6>
                        </div>
                        <FormBoxField title="Rendita catastale" value={ctr.pertinenza_2?.rendita_catastale} />
                        <FormBoxField title="Percentuale di possesso" value={ctr.pertinenza_2?.percentuale_di_possesso} />
                        <FormBoxField title="Mese inizio" value={ctr.pertinenza_2?.mese_inizio} />
                        <FormBoxField title="Mese fine" value={ctr.pertinenza_2?.mese_fine} />
                        <FormBoxField title="Immobile storico inagibile" value={ctr.pertinenza_2?.immobile_storico_inagibile} />

                      </div>
                  }

                  {
                    ctr.pertinenza_3.rendita_catastale !== "" &&
                      <div key={uniqueIdBuilder()}>
                         <div>
                            <h6 style={{color: "black", padding: "20px",fontSize: "25px", borderBottom: "solid 1px lightgrey",textAlign: "left"}}>Pertinenza 1</h6>
                        </div>
                        <FormBoxField title="Rendita catastale" value={ctr.pertinenza_3?.rendita_catastale} />
                        <FormBoxField title="Percentuale di possesso" value={ctr.pertinenza_3?.percentuale_di_possesso} />
                        <FormBoxField title="Mese inizio" value={ctr.pertinenza_3?.mese_inizio} />
                        <FormBoxField title="Mese fine" value={ctr.pertinenza_3?.mese_fine} />
                        <FormBoxField title="Immobile storico inagibile" value={ctr.pertinenza_3?.immobile_storico_inagibile} />

                      </div>
                  }
                </SummaryCard>
              )
            })
          }
          </div>
          </div>
      </div>
    </div>
  );
}
