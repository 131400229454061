import React, {useState } from "react";
import FormBoxField from "../ui-el/FormBoxField";
import IseeEditPopup from "../editForms/IseeEditPopup";
import { useSelector } from "react-redux";
import { titleExtractor, uniqueIdBuilder } from "../../util/Dynamics";

function IseeRegistry(props) {
    const iseeCtx = useSelector(state => state.stepSlice.data.isee);

    const [show,setShow] = useState(false);
    const [edit,setEdit] = useState(false);

    const showRegistry = () => {
        setShow(prev => !prev);
    }

    const editData = () => {
        setEdit(prev => !prev);
    }

    const close = () => {
        setEdit(false);
    }


    return (
        <div className="accordion-item">
            <div className="accordion-header" id="heading-collapse-benef-1">
                <button className="collapsed accordion-button" type="button" onClick={showRegistry}>
                    <span className="d-flex align-items-center">Mostra tutto
                    <svg className="icon icon-primary icon-sm">
                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-expand" />
                    </svg>
                    </span>
                </button>
                {
                    show &&
                        <>
                            <div className="accordion-collapse">
                                <div className="accordion-body p-0">
                                    <div className="cmp-info-summary bg-white has-border">
                                        <div className="card">
                                            <div className="card-header border-bottom border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                                                <h4 className="title-large-semi-bold mb-3">Dati ISEE</h4>
                                                <p onClick={editData} className="text-decoration-none" style={{cursor: "pointer"}}><span className="text-button-sm-semi t-primary">Modifica</span></p>
                                            </div>
                                            <div className="card-body p-0">
                                                {
                                                        iseeCtx.length > 0 ?
                                                            iseeCtx.map(itm => {
                                                                return Object.keys(itm).map(el => {
                                                                    if(el === "selected")
                                                                        return;

                                                                    if(el.includes("base64")) 
                                                                        return;
                                                                    
                                                                    return (
                                                                            <FormBoxField 
                                                                                title={titleExtractor(el)}
                                                                                value={itm[el] ? itm[el] : "Non Compilato" }
                                                                                key={uniqueIdBuilder()}
                                                                                />
                                                                    )
                                                                })
                                                            }):
                                                            <>
                                                                <FormBoxField
                                                                    title='Valore ISEE'
                                                                    value={props.card?.valore_isee ? props.card.valore_isee : "Non Compilato" }/>
                                                                <FormBoxField 
                                                                    title='Scadenza ISEE' 
                                                                    value={props.card?.scadenze_isee ? props.card.scadenze_isee : "Non Compilato" }/>
                                                                <FormBoxField 
                                                                    title=' Anno protocollo' 
                                                                    value={props.card?.anno_protocollo ? props.card.anno_protocollo : "Non Compilato"}/>
                                                                <FormBoxField 
                                                                    title='Numero protocollo / base IMU' 
                                                                    value={props.card?.numero_protocollo ? props.card.numero_protocollo : "Non Compilato"}/>
                                                                <FormBoxField 
                                                                    title='Codice filiale protocollo' 
                                                                    value={props.card?.codice_filiale_protocollo ? props.card.codice_filiale_protocollo : "Non Compilato"}/>
                                                                <div className="single-line-info border-light">
                                                                <div className="text-paragraph-small">Certificato ISEE</div>
                                                                <div className="border-light">
                                                                    <p className="data-text">{props.info?.doc ? props.info.doc : "Non Compilato"}</p>
                                                                    {
                                                                        props.info?.doc &&
                                                                            <p className="fw-semibold pb-2 pt-2 data-text description-success d-flex align-items-center">
                                                                                <span className="d-flex align-items-center">
                                                                                    <svg className="icon icon-sm icon-success" aria-hidden="true">
                                                                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle" />
                                                                                    </svg>
                                                                                    Documento inserito con successo
                                                                                </span>
                                                                            </p>
                                                                    }
                                                                </div>
                                                            </div> 
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }

                {
                    edit && 
                        <IseeEditPopup id={props.id} close={close}/>
                }

            </div>
        </div>  

    )
}

export default IseeRegistry;