import React, { useEffect, useState } from "react";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import { useLocation } from "react-router-dom";

function PaymentPage(props) {
    const [header, setHeader] = useState("");
    const [user, setUser] = useState("");
    const location = useLocation();
    const [importo,setImporto] = useState(location.state.data.amount);

    useEffect(() => {
        //inizioalize first view first step
        if(localStorage.getItem("configuration")) {
          const config = JSON.parse(localStorage.getItem("configuration"));
          setHeader(config.data.current);
        }
        if(localStorage.getItem("user")) {
          const user = JSON.parse(localStorage.getItem("user"));
          setUser(user);
        }
    }, []);

    const change = (e) => {
        setImporto(e.target.value);
    }

    return (
        <AuthenticatedTenant auth={user} errors={props?.errors} header={header}>

            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                            <div style={{marginLeft: "-100px"}}>
                                <WorkPath workPathInfo={{
                                    base: ["Home","https://stellanello.nemea.cloud/"],
                                    medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
                                    end: "Pagamento"
                                    }}/>
                            </div>
                    <div className="steppers-content" aria-live="polite">
                        <div className="it-page-sections-container">
                            <h1 style={{borderBottom: "solid 1px lightgrey", marginBottom: "10px", padding: "30px 0 30px 0"}}>Pagamento</h1>
                            <br></br>
                            <SummaryCard cardTitle="Dati" type={location.state.data.serviceNameLink}>
                                <FormBoxField
                                    title="Pratica"
                                    value={location.state.data.fileN} />
                                <FormBoxField
                                    title="Data di pagamento" 
                                    value={location.state.data.date} />
                                <FormBoxField
                                    title="Importo totale dovuto" 
                                    value={importo + " €"} />
                                <FormBoxField
                                    title="Descrizione pagamento" 
                                    value={location.state.data.title} />
                                    <br></br>
                                <SummaryCard cardTitle="Importo pagamento" type="">
                                    <PopupTextField
                                        defaultValue={importo}
                                        onChange={change}
                                        fieldType="number"
                                        id="importo"
                                        fieldTitle="Importo*"
                                        />
                                </SummaryCard>
                            </SummaryCard>
                            <div style={{display: "flex", flexDirection:"column", marginBottom: "30px"}}>
                                <button type="button" className="btn btn-icon btn-re square mb-2" data-focus-mouse="false">
                                    <img src="../assets/images/pagopa.svg" alt="paga con pagoPA" className="me-2"/>
                                    <span className="">paga con pagoPA</span>
                                </button>
                                <a className="simple-link" href="#" data-focus-mouse="false">Che cos’è pagoPA <span className="visually-hidden">Che cos’è pagoPA</span></a>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
        </AuthenticatedTenant>
    )
}

export default PaymentPage;