import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { onDirectDataChange } from "../../util/Dynamics";


function IseeEditPopup(props) {
    const dispatcher = useDispatch();
    const iseeFormData = useSelector(state => state.stepSlice.data.isee);

    const [iseeData,setIseeData] = useState({
        "valore_isee": "",
        "scadenza_isee": "",
        "anno_protocollo": "",
        "numero_protocollo": "",
        "codice_filiale_protocollo": "",
        "certificato_isee": "",
    });

    useEffect(() => {
        for(let i of iseeFormData) {
            setIseeData(i);
            break;
        }
    },[])


    const save = () => {
        dispatcher(stepSlice.actions.dynamicEdit({id: props.id,data: iseeData}));
    }

    return (
        <PopupBox close={props.close} save={save} title="ISEE"> 
        <br></br>
            <PopupTextField defaultValue={iseeData?.valore_isee} onChange={(e) => {onDirectDataChange(e,setIseeData)}} id="valore_isee" fieldType="number" fieldTitle="Valore ISEE*"/>
            <PopupTextField defaultValue={iseeData?.scadenza_isee} onChange={(e) => {onDirectDataChange(e,setIseeData)}} id="scadenza_isee" fieldType="date" fieldTitle="Scadenza ISEE*"/>
            <PopupTextField defaultValue={iseeData?.anno_protocollo} onChange={(e) => {onDirectDataChange(e,setIseeData)}} id="anno_protocollo" fieldType="number" fieldTitle="Anno Protocollo*"/>
            <PopupTextField defaultValue={iseeData?.numero_protocollo} onChange={(e) => {onDirectDataChange(e,setIseeData)}} id="numero_protocollo" fieldType="number" fieldTitle="Numero Protocollo*"/>
            <PopupTextField defaultValue={iseeData?.codice_filiale_protocollo} onChange={(e) => {onDirectDataChange(e,setIseeData)}} id="codice_filiale_protocollo" fieldType="text" fieldTitle="Codice Filiale Protocollo*"/>
            <PopupTextField defaultValue={iseeData?.certificato_isee} onChange={(e) => {onDirectDataChange(e,setIseeData)}} id="certificato_isee" fieldType="file" fieldTitle="Certificato ISEE*"/>
        </PopupBox>
    )
}

export default IseeEditPopup;