import { apiAuth } from "./Configs/AxiosConfigs"

export const DisserviceApi = {

    getDisservices: async function () {
        const response = await apiAuth.request({
          url: "/disservices/",
          method: "GET",
        })  
        return response.data;
      }
 
}
