import { useEffect, useState } from "react";

import BaseFooter from "../UI-component/BaseFooter";
import Logout from "../UI-component/Logout";
import LinkSkipper from "../UI-component/ui-el/LinkSkipper";
import LowerHeader from "../UI-component/LowerHeader";
import { ConfigurationAPI } from "../Apis/ConfigurationAPI";

export default function AuthenticatedTenant(props) {
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");

  function setPrimary(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--primary", newColor);
  }

  function setSecondary(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--secondary", newColor);
  }

  function setBsPrimaryRgb(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--bs-primary-rgb", newColor);
  }

  function setBsSecondaryRgb(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--bs-secondary-rgb", newColor);
  }

  useEffect(() => {    
    try {
      if (props.auth && props.auth.data) 
          setUser(JSON.parse(props.auth?.data));
    } catch (e) {
    } 
 
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
      setPrimary(config.data.current.theme?.primary);
      setSecondary(config.data.current.theme?.secondary);
      setBsPrimaryRgb(config.data.current.theme?.primaryRGB);
      setBsSecondaryRgb(config.data.current.theme?.secodaryRGB);
    } else {
      (async () => {
        try {
          await ConfigurationAPI.getConfiguration(false).then((config) => {
            localStorage.setItem("configuration", JSON.stringify(config));
            setHeader(config.data.current);
            setPrimary(config.data.current.theme?.primary);
            setSecondary(config.data.current.theme?.secondary);
            setBsPrimaryRgb(config.data.current.theme?.primaryRGB);
            setBsSecondaryRgb(config.data.current.theme?.secodaryRGB);
          });
        } catch (err) {
        }
      })();
    }


  }, [props.auth, user.familyName, user.name]);

  return (
    <div>
      <LinkSkipper/>
      <header className="it-header-wrapper" data-bs-target="#header-nav-wrapper">
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <a className="d-lg-block navbar-brand" target="_blank" href="#" aria-label="Vai al portale {Nome della Regione} - link esterno - apertura nuova scheda">
                    {props.header?.country}
                  </a>
                  <Logout familyName={user.familyName} name={user.name} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <LowerHeader description={props.header?.description} links={[]}/>
      </header>

      <main>
        {props.children}
      </main>

      <BaseFooter/>
    </div>
  );
}




