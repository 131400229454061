import React, { useEffect, useState } from "react";
import { ServiceRequestAPI } from "../Apis/ServiceRequestAPI";
import { useNavigate } from "react-router-dom";


function CompletitionBox(props) {
    const [isOpen,setIsOpen] = useState(false);
    const [image,setImage] = useState("");
    const navigate = useNavigate();


    const toggleOpen = () => {
        setIsOpen(prev => !prev);
    }

    const svgFilter = () => {
        switch(props.doc.state) {
            case "to_complete":
                setImage("folder-incomplete");
                break;
            case "completed":
                setImage("folder-concluded");
                break;
            case "waiting":
                setImage("folder-wating");
                break;
                ////
            case "in_progress":
                setImage("icone-stati-in-corso");
                break;
            case "draft":
                setImage("icone-stati-in-bozza");
                break;
            case "error":
                setImage("icone-stati-non-conclusa");
                break;
            default: break;                                                                      
        };
    }

    useEffect(() => {
        svgFilter();
    },[])
    

    const savedApi = async (e) => {
        console.log(props.doc);
        try{
            const res = await ServiceRequestAPI.getSaved(props.doc.practiceNumber);
            console.log(res.data);
            navigate(`/${props.doc.serviceLink}`,{state: res.data});
        }catch(err) {

        }

        //send the data to the service u need with useNavigate useLocation
        //and then saved it once u are in that page
    }

    return (
        <div className="accordion-item" onClick={toggleOpen}>
            {
                isOpen ?
                    <>
                        <div className="accordion-header" id="heading2">
                        <button className="accordion-button collapsed title-snall-semi-bold" type="button">
                            <div className="button-wrapper">
                            {props.doc.name}
                            <div className="icon-wrapper">
                                <img className="icon-folder icon-size" src={`../assets/images/${image}.svg`} alt="folder Conclusa"/>
                                <span className="">{props.doc.italian_state}</span>
                            </div>
                            </div>
                        </button>
                        <p className="accordion-date title-xsmall-regular mb-0">
                            {props.doc.date}
                        </p>
                        </div>
                    <div>
                        <div className="accordion-body">

                        <p className="mb-2 fw-normal">
                            Pratica: 
                            <span className="label"> {props.doc.practiceNumber || " Non presente"}</span>
                        </p>

                        <a style={{marginRight: "10px"}} href={props.doc.link} className="mb-2">
                            <span className="t-primary">
                                Scheda servizio
                            </span>
                        </a>
                        {/* <a className="chip chip-simple" href={props.doc.serviceLink}>
                            <span className="chip-label">
                            {props.doc.serviceNameLink}
                            </span>
                        </a> */}
                        <div className="cmp-icon-list">
                               <div className="link-list-wrapper">
                                 <ul className="link-list">
                                    {
                                        props.doc.state === "completed" &&
                                            <li style={{  width: "442px",marginLeft: "-22px"}} className="shadow p-0">
                                                <a
                                                className="list-item icon-left t-primary title-small-semi-bold"
                                                href="#"
                                                aria-label="Scarica la Ricevuta richiesta"
                                                >
                                                <span className="list-item-title-icon-wrapper">
                                                    <svg
                                                    className="icon icon-sm align-self-start icon-color"
                                                    aria-hidden="true"
                                                    >
                                                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                                    </svg>
                                                    <span style={{padding: "10px"}} className="list-item-title title-small-semi-bold">
                                                    Ricevuta richiesta
                                                    </span>
                                                </span>
                                                </a>
                                            </li>
                                    }
                                 </ul>
                                 </div>
                                {
                                    props.doc.state === "draft" &&
                                        <button onClick={savedApi} style={{padding: "8px", background: "var(--primary)"}} type="button" className="btn btn-icon btn-re square" data-focus-mouse="false">Perfeziona pratica</button>                                  
                                }
                            </div>
                        </div>
                    </div> 
                    </>
                    :
                        <div className="accordion-header" id="heading2">
                        <button className="accordion-button collapsed title-snall-semi-bold" type="button">
                            <div className="button-wrapper">
                            {props.doc.name}
                            <div className="icon-wrapper">
                                <img className="icon-folder icon-size" src={`../assets/images/${image}.svg`} alt="folder Conclusa"/>
                                <span className="">{props.doc.italian_state}</span>
                            </div>
                            </div>
                        </button>
                        <p className="accordion-date title-xsmall-regular mb-0">
                            {props.doc.date}
                        </p>
                        </div>
            }
        </div>
        
    )
}

export default CompletitionBox;