import React from "react";
import IseeRegistry from "./IseeRegistry";
import { useSelector } from "react-redux";
import { uniqueIdBuilder } from "../../util/Dynamics";

function IseeCard(props) {
    const iseeCtx = useSelector(state => state.stepSlice.data.isee);

    return ( 
        <div className="cmp-info-button-card radio-card">
                <>
                    <div className="card p-3 p-lg-4">

                        <div className="card-header mb-0 p-0">
                            <h3 className="big-title mb-0">{props.id + ": " + props.card?.valore_isee}</h3> 
                        </div>
                            <div className="card-body p-0">
                            <label htmlFor="second">Scadenza Isee</label>
                                <p style={{fontWeight: "bold"}} className="card-info">{props.card?.scadenza_isee ? props.card?.scadenza_isee : "Non Compilato" }</p>
                                <IseeRegistry id={props.id} card={props.card} key={uniqueIdBuilder()}/>
                            </div>
                    </div>
                    
                </>
        </div>
  )
}

export default IseeCard;