import React, { useEffect, useState } from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";

import { useSelector } from "react-redux";
import { OfficeAPI } from "../../Apis/OfficeAPI";

//OFFICE SELECTOR
function Fifth() {
    const ctx = useSelector(state => state.stepSlice.data.ufficio);
    const [officeDetails,setOfficeDetails] = useState({});


    const getOfficeDetails = async () => {
        try{
            const res = await OfficeAPI.getOfficesById(ctx.ufficio.id);

            if(!res.success) {
                return;
            }
            console.log(res.data);
            setOfficeDetails(res.data)
        }catch(err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getOfficeDetails();
    },[])

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 col-lg-8 offset-lg-2">
                    <div className="steppers-content" aria-live="polite">
                        <div className="it-page-sections-container">

                                <SummaryCard cardTitle="Ufficio" type="Riepilogo">
                                    <FormBoxField title="Indirizzo" value={ctx.ufficio?.value ? ctx.ufficio.value : "Non Compilato"} />
                                    <FormBoxField title="Apertura" value={officeDetails.timetables} />
                                </SummaryCard>

                                <SummaryCard cardTitle="Dettagli appuntamento" >
                                    <FormBoxField title="Motivo" value={ctx?.motivo ? ctx.motivo : "Non Compilato"} />
                                    <FormBoxField title="Dettagli" value={ctx?.dettagli ? ctx.dettagli : "Non Compilato"} />
                                </SummaryCard>
                                
                                <SummaryCard cardTitle="Data e orario" > 
                                    <FormBoxField title="Data" value={ctx.appuntamenti_disponibili?.value ? ctx.appuntamenti_disponibili?.value : "Non Compilato"}/>
                                    <FormBoxField title="Orario" value={ctx.disponibilita?.value ? ctx.disponibilita.value : "Non Compilato"} />
                                </SummaryCard>
                                

                               
                                <SummaryCard mod={true} cardTitle="Richiedente" title="ufficio" id="richiedente" service={"SPA"} type="richiedente"> 
                                    <FormBoxField title="Nome" value={ctx.richiedente?.Nome ? ctx.richiedente.Nome : "Non Compilato"} />
                                    <FormBoxField title="Cognome" value={ctx.richiedente?.Cognome ? ctx.richiedente.Cognome : "Non Compilato"} />
                                    <FormBoxField title="Email" value={ctx.richiedente?.Email ? ctx.richiedente.Email : "Non Compilato"} /> 
                                </SummaryCard> 
                                </div>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    )
}


export default Fifth;