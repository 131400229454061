import React, { useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import { checkValidity, onDirectDataChange } from "../../util/Dynamics";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";
import ErrorAlert from "../../UI-component/ErrorAlert";

//OFFICE SELECTOR
function Fourth() {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data.ufficio);
    const ctxErr = useSelector(state => state.stepSlice.data);
    
    let user = JSON.parse(JSON.parse(localStorage.getItem("user")).data);


    const [applicant,setApplicant] = useState({
        Nome:  user.name,
        Cognome: user.familyName,
        Email: ctx.richiedente?.Email ? ctx.richiedente?.Email : ""
    });

    const [error,setError] = useState({
        applicant: false,
    })

    const errorChecker = () => {  
        let isError = false;

        if(ctxErr.error){ 
            if(!checkValidity(applicant,true) ) {
                isError = true;
                setError(prev => {
                    return {...prev, applicant: isError}
                }) 
            }
            else {
                setError(prev => {
                    return {...prev, applicant: false}
                }) 
                dispatcher(stepSlice.actions.switchError({error: false}))
                dispatcher(stepSlice.actions.dynamicSave({id: "Ufficio",external: "richiedente", data: applicant})); 
                isError = false;            
            }
        }else {
            if(!checkValidity(applicant,true) ) {
                isError = true;
                setError(prev => {
                    return {...prev, applicant: isError}
                });
                dispatcher(stepSlice.actions.dynamicSave({id: "Ufficio",external: "richiedente", data: applicant})); 
                dispatcher(stepSlice.actions.switchError({error: true}))
            }else {
                isError = false;
                setError(prev => {
                    return {...prev, applicant: isError}
                }) 
            }
        }
    }

    useEffect(() => {
        errorChecker();
    },[applicant])

    return (
        <React.Fragment>
            <SideList infoList={[{element: "Richiedente", id: "#applicant" }]}/>
                <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                    {
                        <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant} />
                    }
                <SummaryCard mod={false} type="Richiedente">
                    <PopupTextField 
                        defaultValue={user.name} 
                        onChange={(e) => {onDirectDataChange(e,setApplicant)}}
                        fieldType="text" 
                        id="Nome" fieldTitle="Nome*" description="Inserisci il nome"
                        />
                    <PopupTextField 
                        defaultValue={user.familyName} 
                        onChange={(e) => {onDirectDataChange(e,setApplicant)}}
                        fieldType="text" 
                        id="Cognome" fieldTitle="Cognome*"  description="Inserisci il cognome"
                        />
                    <PopupTextField 
                        defaultValue={applicant.Email ? applicant.Email :  ctx.richiedente.Email} 
                        onBlur={(e) => {onDirectDataChange(e,setApplicant)}} 
                        fieldType="text" 
                        id="Email" fieldTitle="Email*"  description="Inserisci la mail"/>
                </SummaryCard>
            </section>
        </React.Fragment>
    )
}

export default Fourth;
