import React, { useEffect, useState } from "react";
import CardContainer from "../../UI-component/registryForms/CardContainer";
import PreferenceInput from "../../UI-component/preferencesForms/PreferenceInput";
import PreferenceContainer from "../../UI-component/preferencesForms/PreferenceContainer";
import AdvancePayment from "../../UI-component/AdvancePayment";
import Balance from "../../UI-component/Balance";
import TotalPaymentInfo from "../../UI-component/TotalPaymentInfo";
import SideList from "../../UI-component/SideList";
import { ImuApi } from "../../Apis/ImuApi";
import { useSelector,useDispatch } from "react-redux";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import { uniqueIdBuilder } from "../../util/Dynamics";

export default function PagamentiIMUPreferenze(props) {
  const dispatcher = useDispatch();

  const ctx = useSelector(state => state.stepSlice.data.imu);
  const [data,setData] = useState([]);
  const [fail,setFail]  = useState({
    error: false,
    msg: ""
  });

  useEffect(() => {
    getData();
  },[]);

  const getData = async () => {
    try{  
      const res = await ImuApi.sendRequest({imu: ctx,f_24: false});

      if(!res.success) {
        // alert(res.message);
        setFail(prev => {
          return {...prev, error: true, msg: `ERRORE:  ${res?.message ? res.message : "QUALCOSA E' ANDATO STORTO" }`}
        });

        setTimeout(() => {
          document.querySelector(".bkw-btn").click();
          return;
        }, 5000);
      }
      //TODO SAVE DATA IN THE CTX
      setData(res.data);
    }catch(err) {
      setFail(prev => {
        return {...prev, error: true, msg: `ERRORE:  ${"QUALCOSA E' ANDATO STORTO"}`}
      });

      setTimeout(() => {
        document.querySelector(".bkw-btn").click();
        return;
      }, 5000);
      console.log(err);
    }
  }

  return (
    <>
      <div className="row justify-content-center">
        <SideList
          infoList={[
            { element: "Immobili", id: "#report-place" },
          ]}/>
          <div className="col-12 col-lg-8 offset-lg-1">

          {
            Array.isArray(data) &&
              data?.map(itm => {
                const asset = itm?.imposta_immobili;

                return (
                  <PreferenceContainer key={uniqueIdBuilder()}>
                    <CardContainer title={asset.nome_tipologia}>
                      <AdvancePayment
                        expiryDate={asset?.data_di_scadenza_acconto}
                        timeLeft={asset?.giorni_dalla_scadenza_acconto}
                        total={asset?.totale_da_versare}
                      />
                      <Balance
                        expiryDate={asset?.data_di_scadenza_saldo}
                        timeLeft={asset?.giorni_dalla_scadenza_saldo}
                        total={asset?.totale_da_versare}
                      />
                      <TotalPaymentInfo totalToBePaid={asset?.totale_da_versare + "€"} />
                    </CardContainer>
                  </PreferenceContainer>
                )
              })
          }
          {
            fail.error &&
                <SuccessfullPopup isError={true} msg={fail.msg}/>
          }
        </div>
      </div>
    </>
  );
}
