import React, { useState } from "react";
import { uniqueIdBuilder } from "../util/Dynamics";

function StepHeader(props) {
    const [isNum,setIsNum] = useState(window.innerWidth <= 985);

    window.addEventListener("resize", () => {
        if(window.innerWidth <= 985 && !isNum)
            setIsNum(true);
        else if(window.innerWidth > 985 && isNum)
            setIsNum(false);
    })

    const dynamicStepHeader = () => {
        return (
            <>
            <ul>
                {
                    props?.stepList.map((element, idx) => {
                        if(props.step === 0){
                            return (
                                <React.Fragment key={uniqueIdBuilder()}>
                                    {
                                        idx === 0 ?
                                            <li className="active">{element}
                                                <span className="visually-hidden">Attivo</span>
                                            </li> :
                                            <li>{element}</li>       
                                    }
                                </React.Fragment>
                                
                            )
                        } 
                        else {
                           return (
                                <React.Fragment key={uniqueIdBuilder()}>
                                    {
                                        props.step > idx &&
                                            <li className="confirmed">{element}
                                                <svg className="icon steppers-success" aria-hidden="true">
                                                    <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check" />
                                                </svg>
                                                <span className="visually-hidden">Confermato</span>
                                            </li>
                                    }

                                    {
                                        props.step === idx &&
                                            <li className="active">{element}
                                                <span className="visually-hidden">Attivo</span>
                                            </li> 
                                    }

                                    {
                                        props.step < idx &&
                                            <li>{element}</li>
                                    }
                                </React.Fragment>
                           )
                        }
                    })
                }
            </ul>
            {
                isNum &&
                    <strong>{`${props.step + 1}/${props.stepList.length}`}</strong>
            }
            </>
        )
    }


    return (
        <div className="steppers">
            <div className="steppers-header">
                { 
                    dynamicStepHeader()
                }

         
            </div>
        </div>    
    )
}

export default StepHeader;