import React from "react";
import { uniqueIdBuilder } from "../../util/Dynamics";

function PopupSelectField(props) {

    const isSelected = (option) => {
        if(props.defaultValue === option || 
            (Array.isArray(props.defaultValue) && props.defaultValue?.get(0) === option)) {
            return (
                <option selected="selected" value={option} key={uniqueIdBuilder()}>
                    {option}
                </option>
            )
        } else {
            return (
                <option value={option} key={uniqueIdBuilder()}>
                    {option}
                </option>
            )
        }
    }

    return (
        <div className="select-wrapper mt-30 mb-40 bg-transparent p-0">
            <label htmlFor={props.id} className="" mb-3="">{props.fieldTitle}*</label>
            {
                props.multi ? 
                    <select onBlur={props.onBlur} onChange={props.onChange} multiple id={props.id} className={`bg-transparent long ${props.disabled ? "disabled" : ""}`} required="" data-dashlane-rid="49781564a800cfad" data-form-type="other" style={{cursor: "pointer"}}>
                        <option selected="selected" value="" disabled>Seleziona opzione</option>
                        {
                            props.options.map((option) => {
                                return <option value={option}>{option}</option>
                            })
                        }
                    </select> :
                    <select onBlur={props.onBlur} onChange={props.onChange} id={props.id} className={`bg-transparent ${props.disabled ? "disabled" : ""}`} required="" data-dashlane-rid="49781564a800cfad" data-form-type="other" style={{cursor: "pointer"}}>
                        <option selected="selected" value="" disabled>{props.disabled ? "Non selezionabile" : "Seleziona opzione" }</option>
                        {
                            props.options.map((option) => {
                                return isSelected(option);
                            }) 
                        }
                   </select>
            }
         
            <div className="d-flex mb-30">
                <span className="form-text cmp-input__text">
                {props.description ? props.description : "Inserire l’informazione per proseguire con la richiesta" }</span>
            </div>
        </div>
    )
}

export default PopupSelectField;