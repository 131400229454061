import React, { useEffect, useState } from "react";
import {useSelector} from "react-redux";

function BackForwardButton(props){
    const [pvt,setPvt] = useState(false);
    const pvtCtx = useSelector(state => state.stepSlice.privacy);
    const errorGlobal = useSelector(state => state.stepSlice.data.error);
        
    useEffect(() => {
        if(props.noPriv)
            setPvt(true);
        else 
            setPvt(pvtCtx);
        
    }, [pvtCtx]);

    console.log( `${props.step === 0 ? "frw-btn" : "n-frw-btn"}`)
    return (
        <div className="btn-lyster">
            {
                props.step > 0 &&
                    <button onClick={props.onBack} 
                        type="button" className={`bkw-btn ${props.step <= props.totalSteps - 2 ? "bkw-btn-non-summ" : ""}`}>
                        <svg className="icon icon-primary icon-sm" aria-hidden="true">
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-chevron-left" />
                        </svg>
                        <span className="text-button-sm t-primary">Indietro</span>
                    </button>
            }
            {
                props.step < props.totalSteps -1 &&
                    <button
                        id="step1Next"
                        type="submit"
                        className={`${props.service !== "SPA"  && props.step === 0  ? "check-mover" : ""} btn-right btn btn-primary btn-sm steppers-btn-confirm btn-next-step ${errorGlobal || !pvt ? "disabled-btn-pvt" : ""}   ${props.step === 0 ? "frw-btn" : "n-frw-btn"} ${props.service === "SPA" && props.step === 0  ? "cpa-mover" : ""} ${props.step <= props.totalSteps - 2 && props.step > 1 ? "frw-btn-non-summ" : ""}`}
                        data-bs-validate="validate"
                        onClick={props.onNext}>
                        <span className="text-button-sm">Avanti</span>
                        <svg className="icon icon-white icon-sm" aria-hidden="true">
                        <use href="#it-chevron-right"></use>
                        </svg>
                    </button>
            }
            
  
        </div>
    )
}


export default BackForwardButton;


