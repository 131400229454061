import React from "react";
import ReminderBox from "./ReminderBox";
import { uniqueIdBuilder } from "../util/Dynamics";
import MessasgeTable from "./MessagesTable";


function ReminderTable(props) {
    return (
        <div className="it-page-section mb-40 mb-lg-60" id="latest-posts">
            <div style={{cursor: "pointer"}} className="cmp-card">
                <div className="card">
                <div className="card-body p-0">
                    {
                        props.type === "services" &&
                            props.reminders.map(itm => {
                                return <ReminderBox 
                                    date={itm.service_type.replaceAll(".","")} 
                                    link={itm.path} 
                                    isDoc={props.isDoc}
                                    serviceName={itm?.title} 
                                    description={itm?.description}
                                    key={uniqueIdBuilder()}/>
                            }) 
                    }

                    {
                        props.type === "messages" &&
                            props.reminders.map(itm => {
                                return <MessasgeTable 
                                    date={itm.date} 
                                    link={itm.link} 
                                    linkName={itm?.linkName}
                                    subTitle={itm.sub_title}
                                    title={itm.title}
                                    message={itm.message}
                                    messageShort={itm.messageShort} 
                                    key={uniqueIdBuilder()}/>
                            })
                    }
                </div>
                </div>
            </div>
        </div>
    )
}


export default ReminderTable;