import React, { useEffect, useRef } from "react";
import ReactDOM  from "react-dom";


function MessagePopup(props) {
    const divRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            divRef.current.focus();
        }, 10);
    },[]);

    return (
        <div className="s-modal">
            <div onBlur={props.close} ref={divRef}
                className="modal-content modal-dimensions h-100 absolute-popup dims">
                <div  className="cmp-modal__header modal-header pb-0">
                    <div className="internal-els">
                        <button style={{transform: "translateX(410px) scale(1.25)"}} className="btn-close btn-mover"type="button" onClick={props.close}>
                        </button>
                        <p style={{transform: "translateY(35px)"}}>{props.date}</p>
                        <div className="cmp-modal__header mt-30 mt-lg-50">
                            <h3 className="title-xxxlarge mt-2 mb-0">{props.title}</h3>
                        </div>
                        <div className="modal-body">
                            <div className="mb-60 mb-lg-80">
                                <p style={{fontSize: "25px", fontWeight: "800",marginLeft: "-20px", color: "black"}}>{props.subTitle}</p>
                                <p style={{marginLeft: "-20px",width: "415px"}} className="text-paragraph mb-4 fw-normal">{props.message}</p>
                                {
                                    props.link !== "" &&
                                        <a style={{marginLeft: "-20px"}} href={props.link} className="text-paragraph t-primary text-decoration-underline">{props.linkName}</a>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>  
    )
}

const MessagePopupPortal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<MessagePopup 
                    close={props.close} 
                    subTitle={props.subTitle}
                    message={props.message} 
                    link={props.link} 
                    linkName={props.linkName} 
                    date={props.date}
                    title={props.title}/>,
                    document.getElementById("overlay-root"))
            }
        </React.Fragment>
    )
}

export {MessagePopupPortal as MessagePopup};