import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import Privacy from "../_Partials/Privacy";
import ServiziPagamentoDatiPersonali from "../_Partials/ServiziPagamentoDatiPersonali";
import ServiziPagamentoDatiSpecifici from "../_Partials/ServiziPagamentoDatiSpecifici";
import ServiziPagamentoRiepilogo from "../_Partials/ServiziPagamentoRiepilogo";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import {ServiceDetailsAPI} from "../../Apis/ServiceDetailsAPI";

import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { useLocation } from "react-router-dom";


export default function MensaScolastica(props) {
  const dispatcher = useDispatch();
  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [data,setData] = useState({});
  const location = useLocation();

  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
      clearStore();
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
      clearStore();
    }
    (async () => {
      await getData();
    })()

    if(location.state) {
      console.log(location.state.data)
      dispatcher(stepSlice.actions.onDraftData({data: location.state.data,saved: false}));
    }
    if(!location.state){
      dispatcher(stepSlice.actions.onDraftData({data: JSON.parse(localStorage.getItem("user")).preference,saved: true}));
    }
  }, []);

  //Clears the context session
  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  } 

  //Retrieves the data from the backend for the creation of the page
  const getData = async () => {
    try{
      const res = await ServiceDetailsAPI.getServiceDetails(props.code);

      if(!res.success) return;
      
      const data = res.data?.data;

      setData(data);
    }catch(err) {
      console.log(err);
    }
  }

  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>

      <WorkPath workPathInfo={{
          base: ["Home","https://stellanello.nemea.cloud/"],
          medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
          end: "Richiesta mensa scolastica"
        }}/> 
      

      <div className="container" id="main-container">
        <div className="row justify-content-center">
            {
              data && Array.isArray(data) &&
                <DynamicStepContent 
                  service={props.code}
                  successTitle={"Richiesta mensa scolastica"}
                  successDesc={`
                  Inserisci le informazioni necessarie per richiedere la mensa scolastica.<br>
                  Potrai ricontrollare tutti i dati nel riepilogo, prima di inviare la richiesta.`}
                  stepList={["INFORMATIVA SULLA PRIVACY", "DATI GENERALI","DATI SPECIFICI","RIEPILOGO"]} 
                  steps={[
                    {component:Privacy,info: {user,description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                    gestisce i dati personali forniti e
                    liberamente comunicati sulla base dell’articolo 13 del
                    Regolamento (UE) 2016/679 General data protection regulation
                    (Gdpr) e degli articoli 13 e successive modifiche e
                    integrazione del decreto legislativo (di seguito d.lgs)
                    267/2000 (Testo unico enti locali).`,
                    linkPrivacy: "" + JSON.parse(localStorage.getItem("configuration")).data.current.linkPrivacy,}},
                    {component:ServiziPagamentoDatiPersonali,info: data[1] ? {data: data[1], code: props.code} : {}},
                    {component:ServiziPagamentoDatiSpecifici,info: data[2] ? {data: data[2], code: props.code} : {}},
                    {component:ServiziPagamentoRiepilogo,info: data[2] ? {data: data[2], code: props.code} : {}},            
                ]}/>
            }
        </div>
      </div>
    </AuthenticatedTenant>
  );
}
