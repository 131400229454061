import React, { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import WorkPath from "../../UI-component/WorkPath";
import HelpBox from "../../UI-component/HelpBopx";
import CompactInfo from "../../UI-component/CompactInfo";
import SideList from "../../UI-component/SideList";
import { ServiceRequestAPI } from "../../Apis/ServiceRequestAPI";
import SavingForm from "../../UI-component/SavingForm";
import Privacy from "../_Partials/Privacy";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import PopupSelectField from "../../UI-component/ui-el/PopupSelectField";
import SuccessfullBox from "../../UI-component/SuccessfullBox";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import { onDataChange2, onSelectData2 } from "../../util/Dynamics";
import ErrorAlert from "../../UI-component/ErrorAlert";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import { CategoryAPI } from "../../Apis/CategoryAPI";

export default function RichiestaAssistenza(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");
  const [category, setCategory] = useState([]);
  const [service, setService] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [_,setSelection] = useState("");
  let users = JSON.parse(JSON.parse(localStorage.getItem("user")).data);

  const [formData,setFormData] = useState({
    richiedente: {
      Nome: users.name,
      Cognome: users.familyName,
      Email: "",
    },
    richiesta: {
      servizio: "",
      categoriaDiServizio: "",
      dettaglio: ""
    }
  });

  const [error,setError] =  useState({
    applicant: false,
    application: false
  });

  const [fail, setFail] = useState(false);
  const [saved, setSaved] = useState(false);

  const [successData,setSuccessData] = useState({
    title: "",
    message: ""
  });

  // const [selected,setSelected] = useState(null);

  const logFormRef = useRef(null);
  const btnLogRef = useRef(null);

  const submitLoginForm = async (event) => {
    event.preventDefault();

    //TODO: add form validation

    const formData = new FormData(logFormRef.current);
    const formDataJSON = Object.fromEntries(formData);

    btnLogRef.current.setAttribute("disabled", true);

    try {
        await ServiceRequestAPI.sendServiceRequest(
        { service_id: "SRA", status: "Inviato", data: formDataJSON },
        false
      );

      btnLogRef.current.removeAttribute("disabled");

      // setEmailToSend(response.data.data.email);
      setActiveStep(2);
    } catch (error) {
      btnLogRef.current.value = "Login";
      btnLogRef.current.removeAttribute("disabled");
      //TODO: change it
      alert("Oops! Some error occured.");
    }
  };

  //Send the request
  const send = async () => {
    dispatcher(stepSlice.actions.dynamicSave({id: "Richiesta",data: formData}));

    setActiveStep(prev => {
     if(prev > 0 && prev <= 2)
        return prev + 1;
    })


    try{
      const res = await ServiceRequestAPI.sendServiceRequest(
        { service_id: "SRA", status: "Inviato", data: formData },
        false
      );
        
      if(!res.success){
        setFail(true);
        reset();
        return;
      }    
      
      setSuccessData(prev => {
        return {...prev, title: res.data.title,message: res.data.message};
      });
      
    }catch(err) {
      setFail(true);
      reset();
    }
  }

  const reset = () => {
    setTimeout(() => {
        setFail(false);
        setSaved(false);
    }, 5000);
}
  //Save the request
  const saveRequest = async () => {
    try {

      const resp = await ServiceRequestAPI.sendServiceRequest(
        { service_id: "SRA", status: "Bozza", data: formData },
        false
      );
          
      if(!resp.success) {
        setFail(true);
        reset();
        return;
      }; 

      setSaved(true);
      reset();
    } catch (err) {
      //TODO: add Alert with message
      console.log("getCategory error", err);
      setFail(true);
      reset();
    }
  }

  //Retrieves categories from api and it sets them up
  const retrieveServiceCategory = async () => {
    // const data = dataForCtegoryEservices.data;

    const data = await CategoryAPI.getCategory();

    const categories = [];
    const services = new Map();

    data.data.forEach(itm => {
      categories.push(itm.title);
      const tmp = [];
      itm.services.forEach(el => {
        tmp.push(el.title);
      })  
      services.set(itm.title,tmp);
    })

    return {
      categories,
      services
    }
  }

  //Sets the associated services based on the choice
  //the user made, on the services select btn
  // const showAssociatedServices = () => {
  //   if(service) {  
  //     return <PopupSelectField onChange={(e) => {onSelectData2(e,"richiesta",setFormData,setSelection)}} 
  //       id="servizio" fieldTitle={"Servizio"} options={service.get(selection) ? service.get(selection) : ["coap","lolo","coap",]} 
  //       description="Seleziona il servizio per cui vuoi richiedere assistenza"/>

  //   } 
  // }

  const errorChecker = () => {  
    let isError = false;

    for(let i of Object.keys(formData.richiedente)) {
      if(formData.richiedente[i] === "undefined" || formData.richiedente[i] === ""){
        setError(prev => {
          return {...prev, applicant: true}
        });
        isError = true;
        break;
      }
      else {
        setError(prev => {
          return {...prev, applicant: false}
        });
      }
    }


    for(let i of Object.keys(formData.richiesta)) {
      if(i === "servizio") continue;

      if(!formData.richiesta[i]){
        setError(prev => {
          return {...prev, application: true}
        });
        isError = true;
        break;

      }
      else {
        setError(prev => {
          return {...prev, application: false}
        });
      }
    }

    dispatcher(stepSlice.actions.switchError({error: isError}))
  }
  
  useEffect(() => {
    errorChecker();
  },[formData])

  useEffect(() => {
    (async () => {

      //inizioalize first view first step
      if (localStorage.getItem("configuration")) {
        const config = JSON.parse(localStorage.getItem("configuration"));
        setHeader(config.data.current);
      }
      if (localStorage.getItem("user")) {
        const user = JSON.parse(localStorage.getItem("user"));
        setUser(user); 
        // setApplicant(JSON.parse(user.data)); 
      }
  
      const {categories,services} = await retrieveServiceCategory();
      
      setCategory(categories);
      setService(services);
    })();

  }, []);


  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath
          workPathInfo={{
            base: ["Home", "https://stellanello.nemea.cloud/"],
            medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
            end: "Richiesta assistenza",
          }}
        />

        {
          activeStep === 1 &&
            <div className="container" id="main-container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                  <CompactInfo
                    title={"Richiesta assistenza"}
                    description={`I campi contraddistinti dal simbolo asterisco sono obbligatori.`}
                  />
                </div>
              </div>
            </div>
        }

        {
          activeStep === 2 ? 
            <div id="second-step">
              <SuccessfullBox emailToSend={formData?.richiedente?.Email} service="SRA"  successData={successData} />
            </div> :
            <div id="first-step">
              <div className="container container-assistenza">
                <div className="row mt-lg-50">
                  <SideList
                    infoList={[
                      { element: "Richiedente", id: "#applicant" },
                      { element: "Richiesta", id: "#request" },
                    ]}
                  />

                  <div className="col-12 col-lg-8 offset-lg-1">
                    <Form
                      ref={logFormRef}
                      onSubmit={submitLoginForm}
                      className="steppers-content"
                      aria-live="polite"
                      id="justValidateForm"
                      noValidate="novalidate"
                    >
                      {
                          <ErrorAlert errors={[{ name: "Richiedente", id: "#isee-info" }]} err={error.applicant}/>
                      }

                      {
                          <ErrorAlert errors={[{ name: "Richiesta", id: "#isee-info" }]} err={error.application}/>
                      }
                      
                      <div className="it-page-sections-container">
                        <SummaryCard mod={false} type="Richiedente">
                          <PopupTextField 
                            disabled={true} 
                            defaultValue={users.name} 
                            onChange={(e) => {onDataChange2(e,setFormData)}}
                            fieldType="text"
                            id="Nome"
                            fieldTitle="Nome"
                            description="Inserisci il nome"/>

                          <PopupTextField 
                            disabled={true} 
                            defaultValue={users.familyName} 
                            onChange={(e) => {onDataChange2(e,setFormData)}}
                            fieldType="text"
                            id="Cognome"
                            fieldTitle="Cognome"
                            description="Inserisci il cognome"/>

                          <PopupTextField
                            defaultValue={""} 
                            onChange={(e) => {onDataChange2(e,setFormData)}}
                            fieldType="text"
                            id="Email"
                            fieldTitle="Email"
                            description="Inserisci la mail"/>
                        </SummaryCard>

                        <SummaryCard mod={false} type="Richiesta">
                          <PopupSelectField 
                            defaultValue={formData.richiesta.categoriaDiServizio ? formData.richiesta.categoriaDiServizio : "Non Compilato"} 
                              onChange={(e) => {onSelectData2(e,"richiesta",setFormData,setSelection)}}
                              id="categoriaDiServizio"
                              fieldTitle={"Categoria di servizio"} 
                              options={category}
                            description="Seleziona la categoria del servizio per cui vuoi richiedere assistenza"/> 

                          <PopupSelectField defaultValue={formData.richiesta?.servizio ? formData.richiesta.servizio : "Non Compilato"}
                                  onChange={(e) => {onSelectData2(e,"richiesta",setFormData,setSelection)}} 
                                  id="servizio"
                                  fieldTitle={"Servizio"} 
                                  options={service?.get(formData.richiesta.categoriaDiServizio) ? service?.get(formData.richiesta.categoriaDiServizio) : []} 
                                  description="Seleziona il servizio per cui vuoi richiedere assistenza"/>
                        
                          <PopupTextField onChange={(e) => {onDataChange2(e,setFormData)}} 
                            fieldType="text" fieldTitle="Dettagli*" id="dettaglio"
                            description="Inserire massimo 600 caratteri"/>
                        </SummaryCard>

                        <Privacy 
                            service={"assistenza"}
                            info={{
                            linkPrivacy: "" + JSON.parse(localStorage.getItem("configuration")).data.current.linkPrivacy,
                            description: `Il ${JSON.parse(localStorage.getItem("configuration")).data.current.description}
                                gestisce i dati personali forniti e
                                liberamente comunicati sulla base dell’articolo 13 del
                                Regolamento (UE) 2016/679 General data protection regulation
                                (Gdpr) e degli articoli 13 e successive modifiche e
                                integrazione del decreto legislativo (di seguito d.lgs)
                                267/2000 (Testo unico enti locali).`}}/> 

                        <div className="mvr">
                          {
                              saved &&
                                  <SuccessfullPopup/>
                          }

                          {
                              fail &&
                                  <SuccessfullPopup isError={true}/>
                          }
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="rqs-first">
                  <SavingForm step={activeStep} totalSteps={2} send={send} saveRequest={saveRequest} service={'assistenza'}/>
                </div>
              </div>
            </div>
        }
    
        <HelpBox
          helpBoxInfo={{
            muniQuestionLink:
              "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "tel:+39 0182 668000",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: "",
          }}
        />
      </main>
    </AuthenticatedTenant>
  );
}