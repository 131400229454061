import React, { useEffect, useState } from "react";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import { useSelector } from "react-redux";
import { ImuApi } from "../../Apis/ImuApi";
import { uniqueIdBuilder } from "../../util/Dynamics";

function PagamentiIMUAnteprimaF24(props) {
  const ctx = useSelector(state => state.stepSlice.data);
  const [data,setData] = useState([]);
  
  useEffect(() => {
    getData();
  },[]);

  const getData = async () => {
    try{  
      const res = await ImuApi.sendRequest({imu: ctx.imu,f_24: true});

      if(!res.success) {
        alert(res.message);
        document.querySelector(".btn-left").click();
        return;
      }
      setData(res.data);
    }catch(err) {
      console.log(err);
    }
  }


  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 d-lg-none mb-40 mt-4">
          <p className="text-paragraph">
            Rivedi le informazioni da inserire nel documento F24. Conferma e
            continua per scaricare il documento: lo troverai sempre disponibile
            nella tua area personale.
          </p>
        </div>
        <div className="col-12 col-lg-8">
          {
            ctx.imu.contribuente.map(ctr => {
                  if(ctr.selected) {
                    return (
                      <SummaryCard 
                        mod={false} cardTitle={ctr.anagrafica.Nome +" " + ctr.anagrafica.Cognome} 
                        type="Contribuenti" 
                        taxID={ctr.anagrafica.CodiceFiscale} 
                        service={props.info.code}
                        key={uniqueIdBuilder()}>
                        <FormBoxField title="Nome" value={ctr.anagrafica.Nome} />
                        <FormBoxField title="Cognome" value={ctr.anagrafica.Cognome} />
                        <FormBoxField title="Codice Fiscale" value={ctr.anagrafica.CodiceFiscale} />
                        <FormBoxField title="Data Di Nascita" value={ctr.anagrafica["Data Di Nascita"]} />
                        <FormBoxField title="Luogo Di Nascita" value={ctr.anagrafica["Luogo Di Nascita"]} />
                        <FormBoxField title="Telefono" value={ctr.contatti.Telefono} />
                        <FormBoxField title="Email" value={ctr.contatti.Email} />
                        <FormBoxField title="Residenza" value={ctr.indirizzi.Residenza} />
                        <FormBoxField title="Domicilio" value={ctr.indirizzi.Domicilio} />
                        <FormBoxField title="Carta di identitá" value={ctr.documenti["Carta Di Identita"]} />
                      </SummaryCard>
                    )
                  }
                })
            }

          {
            data.map((itm,idx) => {
              return (
                <SummaryCard key={uniqueIdBuilder()} mod={false} cardTitle={itm.imposta_immobili.nome_tipologia} type="Immobili">
                  <FormBoxField title="Ravvedimento" value={itm.imposta_immobili.ravvedimento} />
                  <FormBoxField title="Imm. var.:" value={ctx.imu?.immobili[idx]?.dati_immobile.immobile_variato} />
                  <FormBoxField title="Anno di rif:" value={itm.imposta_immobili.anno_riferimento} />
                  <FormBoxField title="Totale importo" value={itm.imposta_immobili.totale_da_versare} />
                </SummaryCard>
              )
            })
          }
        </div>
      </div>
    </>
  );
}

export default PagamentiIMUAnteprimaF24;
