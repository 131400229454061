import { useEffect, useState } from "react";
import LinkSkipper from "../UI-component/ui-el/LinkSkipper";
import LowerHeader from "../UI-component/LowerHeader";
import BaseFooter from "../UI-component/BaseFooter";
import { ConfigurationAPI } from "../Apis/ConfigurationAPI";

export default function Guest(props) {
  const [header, setHeader] = useState("");

  function setPrimary(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--primary", newColor);
  }

  function setSecondary(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--secondary", newColor);
  }
  function setBsPrimaryRgb(newColor) {
    if (newColor)
      document.documentElement.style.setProperty("--bs-primary-rgb", newColor);
  }

  function setBsSecondaryRgb(newColor) {
    if (newColor)
      document.documentElement.style.setProperty(
        "--bs-secondary-rgb",
        newColor
      );
  }
  useEffect(() => {
    if (localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);

      setPrimary(config.data.current.theme?.primary);
      setSecondary(config.data.current.theme?.secondary);

      setBsPrimaryRgb(config.data.current.theme?.primaryRGB);
      setBsSecondaryRgb(config.data.current.theme?.secodaryRGB);
    } else {
      (async () => {
        try {
          await ConfigurationAPI.getConfiguration(false).then((config) => {
            localStorage.setItem("configuration", JSON.stringify(config));
            setHeader(config.data.current);

            setPrimary(config.data.current.theme?.primary);
            setSecondary(config.data.current.theme?.secondary);
            setBsPrimaryRgb(config.data.current.theme?.primaryRGB);
            setBsSecondaryRgb(config.data.current.theme?.secodaryRGB);
          });
        } catch (err) {
          console.log("Configuration error", err);
        }
      })();
    }
  }, []);

  return (
    <div>
      <LinkSkipper />
      <header
        className="it-header-wrapper"
        data-bs-target="#header-nav-wrapper"
      >
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <a
                    className="d-lg-block navbar-brand"
                    target="_blank"
                    href="#"
                    aria-label={
                      "Vai al portale" +
                      header.country +
                      "- link esterno - apertura nuova scheda"
                    }
                    title={"Vai al portale" + header.country}
                  >
                    {header.country}
                  </a>
                  <div
                    className="it-header-slim-right-zone"
                    role="navigation"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LowerHeader
          description={header?.description}
          siteUrl={header?.siteUrl}
        />
      </header>
      <main>{props.children}</main>
      <BaseFooter />
    </div>
  );
}
