import React, { useEffect, useState } from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { useSelector } from "react-redux";
import { uniqueIdBuilder } from "../../util/Dynamics";
import { DisserviceApi } from "../../Apis/DisserviceApi";

export default function SegnalazioneRiepilogo(props) {
  const data = useSelector(state => state.stepSlice.data.autore_della_segnalazione);
  const disserviceCtx = useSelector(state => state.stepSlice.data.disservizio);
  const [disservices,setDisservices] = useState([]);

  const getData = async () => {
    try{
      const res = await DisserviceApi.getDisservices();

      if(!res.success) {
        alert("Ce' stato un problema")
      }

      const disserviceTitles = [];
      for(let i of res.data)
        disserviceTitles.push(i.title);

      setDisservices(disserviceTitles);
    }catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  },[]);

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            <h2>Segnalazione</h2>
            <br></br>

            <SummaryCard mod={false} cardTitle="" type="Disservizio" key={uniqueIdBuilder()} disserviceData={disservices}>
                <FormBoxField title={"Indirizzo"} value={disserviceCtx?.cerca_un_luogo ? disserviceCtx?.cerca_un_luogo : "Non Compilato" } key={uniqueIdBuilder()}/>
                <FormBoxField title={"Tipo di disservizio"} value={disserviceCtx?.tipo_di_disservizio ? disserviceCtx?.tipo_di_disservizio : "Non Compilato" } key={uniqueIdBuilder()}/>
                <FormBoxField title={"Titolo"} value={disserviceCtx?.titolo ? disserviceCtx?.titolo : "Non Compilato" } key={uniqueIdBuilder()}/>
                <FormBoxField title={"Dettagli"} value={disserviceCtx?.dettagli ? disserviceCtx?.dettagli : "Non Compilato" } key={uniqueIdBuilder()}/>
                <FormBoxField title={"Immagini"} value={disserviceCtx?.immagini ? disserviceCtx?.immagini : "Non Compilato" } key={uniqueIdBuilder()}/>
            </SummaryCard>
            {
                data.map(itm => {
                    if(itm?.selected) {
                        return (
                            <React.Fragment key={uniqueIdBuilder()}>
                                <h2>Dati generali</h2>
                                <br></br>
                                <SummaryCard taxID={itm?.anagrafica.CodiceFiscale} mod={true} cardTitle="" 
                                type="Autore della segnalazione" key={uniqueIdBuilder()} service={props.info.code}>
                                {
                                    Object.keys(itm).map((key) => {
                                        return Object.keys(itm[key]).map(iKey => {
                                            return <FormBoxField title={iKey.includes("Fiscale") ? "Codice Fiscale" : iKey} value={itm[key][iKey] ? itm[key][iKey] : "Non Compilato" } key={uniqueIdBuilder()}/>
                                        })
                                    })
                                }
                                </SummaryCard>
                            </React.Fragment>
                        )
                    }
                })
            }
          </div>
        </div>
      </div>
    </div>
  );
}
