import React, { useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import SavingForm from "../../UI-component/SavingForm";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import AreaFormBox from "../../UI-component/AreaFormBox";
import { OfficeAPI } from "../../Apis/OfficeAPI";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../context/StepsContext";
import ErrorAlert from "../../UI-component/ErrorAlert";

//OFFICE SELECTOR
function Third() {
    const dispatcher = useDispatch();

    const ctx = useSelector(state => state.stepSlice.data.ufficio);
    const [reasons,setReasons] = useState(null);
    const [error,setError] = useState({
        motive: false,
        details: false,
    })


    useEffect(() => {
        getReasons();
        errorChecker();
    },[])

    const getReasons = async () => {
        try{
            const res = await OfficeAPI.getReasons(ctx.ufficio.id);

            if(!res.success) {
                return;
            }

            setReasons(res.data.map(itm => {return {id: itm.id,value: itm.title}}))
        }catch(err) {
            console.log(err);
        }
    }

    const errorChecker = () => {  
        let isError = false;
    
        if(ctx?.motivo) 
            setError(prev => {return {...prev, motive: false}})
        else {
            isError = true;
            setError(prev => {return {...prev, motive: true}})
        }
        if(ctx?.dettagli) 
            setError(prev => {return {...prev, details: false }})
        else {
            isError = true;
            setError(prev => {return {...prev, details: true}})
        }
    
        dispatcher(stepSlice.actions.switchError({error: isError}))
    }

    return (
        <React.Fragment>
            <SideList infoList={[{element: "Motivo", id: "#motive" },{element: "Dettagli", id: "#details" }]}/>
            <section className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <div className="cmp-card mb-40" id="reason">

                    {
                        <ErrorAlert errors={[{ name: "Motivo", id: "#isee-info" }]} err={error.motive} K={"MOTIVE"}/>
                    }
                    {
                        <ErrorAlert errors={[{ name: "Dettagli", id: "#isee-info" }]} err={error.details} K="DETAILS"/>
                    }
                    <SelectionFormBox
                        showTitle="Motivo*"
                        firstSelection={"Seleziona una motivazione"}
                        title="Motivo"
                        description="Scegli il motivo dell'appuntamento"
                        selectTitle="Seleziona il motivo"
                        choices={reasons}
                        defaultValue={ctx?.motivo ? ctx?.motivo : "Non Compilato" }
                        oKey={"Ufficio"}
                        />
                    <AreaFormBox defaultValue={ctx?.dettagli} oKey={"Ufficio"} title="Dettagli" description="Aggiungi ulteriori dettagli"/>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Third;