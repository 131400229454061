import React, { useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";
import PopupSelectField from "../ui-el/PopupSelectField";
import { useSelector,useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";

import { onDataChange2,onSelectData2, titleNormalizer, uniqueIdBuilder } from "../../util/Dynamics";

function DisserviceEditPopup({title,fields,close,id}) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data[id]);

    const [disserviceData,setDisserviceData] = useState({ 
        disservizio: {
          cerca_un_luogo: ctx?.cerca_un_luogo,
          tipo_di_disservizio: ctx?.tipo_di_disservizio,
          titolo: ctx?.titolo,
          dettagli: ctx?.dettagli,
          immagini: ctx?.immagini
        }
    });

    const [_,setSelection] = useState("");

    const save = () => {
        dispatcher(stepSlice.actions.dynamicEdit({id: "Disservizio",data: disserviceData.disservizio}));
    }

    return (
        <>
            {
                <PopupBox save={save} close={close} title={title}> 
                    {
                        fields.map(itm => {
                            const name = titleNormalizer(itm?.name);
                            
                            if(itm.type === "normal") {
                                return <PopupTextField 
                                    defaultValue={disserviceData.disservizio[name] ? disserviceData.disservizio[name] : ctx[name] }
                                    onChange={(e) => {onDataChange2(e,setDisserviceData)}}
                                    fieldType="text"
                                    fieldTitle={itm.name === "cerca_un_luogo" ? "Indirizzo*" : itm.name}
                                    id={itm.id}
                                    key={uniqueIdBuilder()}
                                    />
                            }else if(itm.type === "select") {
                                return (
                                    <PopupSelectField 
                                        defaultValue={disserviceData.disservizio[name] ? disserviceData.disservizio[name] : ctx[name]}
                                        onChange={(e) => {onSelectData2(e,"disservizio",setDisserviceData,setSelection)}} 
                                        id={name} fieldTitle={name} 
                                        options={itm?.options}
                                        description={itm?.description}
                                        key={uniqueIdBuilder()}
                                        />
                                )
                            }else {
                                return (
                                    <PopupSelectField 
                                        id={itm?.name} 
                                        fieldTitle={itm?.name} options={itm?.options}
                                        description={itm?.description}
                                        key={uniqueIdBuilder()}
                                        />
                                ) 
                            }
                        })               
                    }           
                </PopupBox>
            }
        </>
    )
}

export default DisserviceEditPopup;