import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function CompletitionBoxMsg(props){
    const navigate = useNavigate();
    const [open,setOpen] = useState(false);

    const click = () => {
    }

    const onPay = () => {
      navigate("/payment-page", {state: {data: props}});
    }

    return (
        <div className="accordion-item" onClick={click}>
            <div className="accordion-header" id="heading7">
            
                    <div>
                        <button 
                            className="accordion-button collapsed title-snall-semi-bold"
                            type="button"
                            onClick={() => setOpen(prev => !prev)}
                            >
                            <div className="button-wrapper">
                                {props.title}
                                <div className="icon-wrapper">
                            
                                {
                                    props.pay ?
                                    <>
                                    <img
                                            className="icon-folder"
                                            src="../assets/images/folder-to-pay.svg"
                                            alt="folder Da pagare"
                                        />
                                        <span className="u-main-error">Da pagare</span> 
                                    </>:
                                    <>
                                        <img
                                            className="icon-folder"
                                            src="../assets/images/folder-pay.svg"
                                            alt="folder Pagato"
                                        />
                                        <span className="">Pagato</span>
                                    </>

                                }
                                
                                </div>
                            </div>
                        </button>
                    <p className="accordion-date title-xsmall-regular mb-0">{props.date}</p>
                    </div>
                    {
                        open &&
                         <div>
                           <div className="accordion-body">
                             <p className="mb-2 fw-normal">Pagamento:
                               <span className="label"> {props?.fileN?.toUpperCase()}</span>
                             </p>
                             <p className="mb-2 fw-normal">
                               Metodo: <span className="label"> {props.paymentMethod}</span>
                             </p>
                             <p className="mb-2 fw-normal">
                               Importo: <span className="label"> {props.amount}€</span>
                             </p>
                             <a style={{marginRight: "10px"}} href={props?.link} className="mb-2">
                               <span className="t-primary">
                                 Scheda servizio
                               </span>
                             </a>
                             {/* <a className="chip chip-simple" href={props.serviceLink}>
                               <span className="chip-label">
                                {props.serviceNameLink}
                               </span>
                             </a> */}
                             <div className="cmp-icon-list">
                               <div className="link-list-wrapper">
                                 <ul className="link-list">
                                  {
                                    !props.pay &&
                                      <li style={{  width: "442px",marginLeft: "-22px"}} className="shadow p-0">
                                        <a
                                          className="list-item icon-left t-primary title-small-semi-bold"
                                          href="#"
                                          aria-label="Scarica la Ricevuta richiesta"
                                        >
                                          <span className="list-item-title-icon-wrapper">
                                            <svg
                                              className="icon icon-sm align-self-start icon-color"
                                              aria-hidden="true"
                                            >
                                              <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-clip" />
                                            </svg>
                                            <span style={{padding: "10px"}} className="list-item-title title-small-semi-bold">
                                              Ricevuta richiesta
                                            </span>
                                          </span>
                                        </a>
                                      </li>
                                  }
                                 </ul>
                               </div>
                             </div> 
                             {
                                props.pay &&
                                  <button onClick={onPay} style={{padding: "8px",background: "var(--primary)"}} type="button" className="btn btn-icon btn-re square" data-focus-mouse="false">Vai al pagamento</button>                          
                             }
                            </div>
                         </div>

                    }
        </div>

      </div>
    )
}

export default CompletitionBoxMsg;