import React, { useState } from "react";

import CardContainer from "../registryForms/CardContainer";
import PropertyEditPopup from "../editForms/PropertyEditPopup";
import RegistryEditPopup from "../editForms/RegistryEditPopup";
import PreferenceRegistryPopup from "../PreferenceRegistryPopup";
import DisserviceEditPopup from "../editForms/DisserviceEditPopup";
import IseeEditPopup from "../editForms/IseeEditPopup";
import { titleExtractor } from "../../util/Dynamics";
import CoobEditPopup from "../editForms/CoobEditPopup";

function SummaryCard(props) {
    const [edit,showEdit] = useState(false);

    const close = () => {
        showEdit(false);
    }
    
    const open = () => {
        showEdit(true);
    }

    //Filters the summary based on the type of the summary found in the config page
    //renders the box based on the type
    const SummaryTypeShower = () =>  {
        switch(props.type) {
            case "Preferenze":
                return <PreferenceRegistryPopup close={close} choiceData={props.choiceData} service={props.service}  />
            case "richiedente":
                return <RegistryEditPopup close={close} id={"Richiedente"} taxID={props?.taxID} service={props.service} title={props?.title}/>
            case "beneficiario":
                return <RegistryEditPopup close={close} id={"Beneficiario"} taxID={props?.taxID} service={props.service} />
            case "Contribuenti": 
                return <RegistryEditPopup close={close} id={"Contribuente"} taxID={props?.taxID} service={props.service}/>
            case "Autore della segnalazione":
                return <RegistryEditPopup close={close} id={"Autore della segnalazione"} taxID={props?.taxID} service={props.service}/>    
            case "Disservizio":
                const fields = [{id:"cerca_un_luogo", name: "cerca_un_luogo", type: "normal", description: "", options: []},
                        {id:"tipo_di_disservizio",name: "Tipo di disservizio", type: "normal", description: ""},
                        {id:"titolo",name: "Titolo", type: "normal", description: "", options: []},
                        {id:"dettagli",name: "Dettagli", type: "normal", description: "", options: []}];

                return <DisserviceEditPopup close={close} title={"Disservizio"} id={"disservizio"} fields={fields}/>;
            case "Coobligato": 
                return  (
                    <CoobEditPopup close={close} title="Coobligato" cobData={props.cobData}/>
                )
            case "isee": 
                return  (
                    <IseeEditPopup close={close} id={"ISEE"} />
                ) 
            case "Immobili":
            return (
                <PropertyEditPopup close={close} immData={props.immData}/>
            )
            default: 
        }
    }

    return (
        <CardContainer title={props?.type === "isee" ? "ISEE"  : titleExtractor(props?.type)}>
            <div className="card-body p-0">
                <div className="cmp-info-summary bg-white mb-4 mb-lg-30 p-3 p-lg-4">
                    <div className="card">
                        <div className="card-header border-light p-0 mb-0 d-flex justify-content-between d-flex justify-content-end">
                            <h4 className="title-large-semi-bold mb-3">{props?.cardTitle}</h4>
                            {
                                props.mod && 
                                    <a className="text-decoration-none">
                                        <span className="text-button-sm-semi t-primary" onClick={open}>
                                            Modifica
                                        </span>
                                    </a>
                            }
                        </div>
                        <div className="card-body p-0">
                            {props?.children}
                        </div>
                    </div>
                </div>
            </div>
            {
                edit &&
                    SummaryTypeShower()
            }
        </CardContainer>
    )
}

export default SummaryCard;