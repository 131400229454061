import React, { useEffect, useState } from "react";
import { ConfigurationAPI } from "../Apis/ConfigurationAPI";


function BaseFooter() {
    const [footer,setFooter] = useState(null);

    function b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(atob(str).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  }

    const footerDynamic = async () => {
        try {
          const res = await ConfigurationAPI.getConfiguration(false);
    
          if(!res.success) {
            return;
          }
    
          const foot = b64DecodeUnicode(res.data.footer); 
          setFooter(foot);
        }catch(err) {
          console.log(err);
        }
      }
    
      useEffect(() => {
        footerDynamic();
      },[])

    return (
        <div >
            {
                footer &&
                    <div dangerouslySetInnerHTML={{ __html: footer }}></div>        
            }
        </div>
    )
}


export default BaseFooter;