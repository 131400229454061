import React, {useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PopupBox from "./PopupBox";
import PopupSelectField from "./ui-el/PopupSelectField";
import stepSlice from "../context/StepsContext";
import { onSelectData2, titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import PopupTextField from "./ui-el/PopupTextField";


function PreferenceRegistryPopup(props) {
    const dispatcher = useDispatch();
    const preferencesCtx = useSelector(state => state.stepSlice.data.preferenze);

    const [_,setSelection] = useState("");
    const [data,setData] = useState({
        "scuola":"",
        "tariffa_esonero":"",
        "tariffa_riduzione": "",
        "frequenza": "",
        "dieta_diversificata":"",
        "classe": "",
        "certificato_disabilita": ""
    });

    const classChange = (e) => {
        setData(prev => {
            return {...prev, classe: e.target.value};
        })
    }

    const edit = () => {
        dispatcher(stepSlice.actions.dynamicEdit({id: "Preferenze",data: data}));
        props.close();
    }

    useEffect(() => {
        setData(preferencesCtx);
    },[])

    return (
        <PopupBox close={props.close} save={edit} title="Preferenze"> 
            {
                Object.keys(preferencesCtx).map(key => {
                    const options = props.choiceData.filter(itm => {
                        if(itm.title === "DISABILITÀ") {
                            itm.title = "disabilita";
                        }

                        if( key.includes(titleNormalizer(itm.title)))
                            return true;
                        else 
                            return false;
                    });
                 
                    if(key === "classe" && data[key] !== "" && props.service === "RMS") {
                        return <PopupTextField 
                                    defaultValue={data[key] ? data[key] : preferencesCtx[key]}
                                    onBlur={classChange} 
                                    id={key}
                                    fieldTitle={key}
                                    key={uniqueIdBuilder()}/>
                    }

                    if(options.length > 0) {
                        let values = [];
                        for(let i of options) 
                            values = i.options.map(itm => itm.value);

                        return <PopupSelectField 
                                    defaultValue={data[key] ? data[key] : preferencesCtx[key]}
                                    onChange={(e) => {onSelectData2(e,"preferenze",setData,setSelection)}} 
                                    id={key}
                                    fieldTitle={titleExtractor(key)}
                                    options={values}
                                    description="Seleziona la preferenza"
                                    key={uniqueIdBuilder()}/>
                    }
                })
            }
        </PopupBox>
    )
}

export default PreferenceRegistryPopup;