import React, { useEffect, useState } from "react";
import PopupBox from "../PopupBox";
import PopupTextField from "../ui-el/PopupTextField";

import stepSlice from "../../context/StepsContext";
import { useDispatch,useSelector } from "react-redux";
import { onDataChange2,titleNormalizer } from "../../util/Dynamics";


function RegistryEditPopup(props) {
    const dispatcher = useDispatch();
    const [error,setError] = useState(false);
    const anagraficData = useSelector(state => {
        if(props.id === "Contribuente") {
            return state.stepSlice.data.imu[titleNormalizer(props.id)]
        }


        if(props.service === "SPA") {
            return state.stepSlice.data[titleNormalizer(props.title)][titleNormalizer(props.id)]
        }

        if(state.stepSlice.data[titleNormalizer(props.id)])
            return state.stepSlice.data[titleNormalizer(props.id)]
        else 
            return state.stepSlice.data[titleNormalizer(props.title)][titleNormalizer(props.id)]
    });
    let user = JSON.parse(JSON.parse(localStorage.getItem("user")).data);

    const dynamicBaseObjectCalculation = () => {
        let obj = {};

        switch(props.service) {
            case 'RTS':
                if(props?.id === "Beneficiario") {
                    obj =   {
                        anagrafica:{
                            "Nome": anagraficData.Nome,
                            "Cognome": "",
                            "CodiceFiscale": "",
                            "Data Di Nascita": "",
                            "Luogo Di Nascita": "",
                            },
                        indirizzi: {
                            "Residenza": "",
                            "Domicilio": "",
                        },
                        documenti: {
                            "Certificato disabilità": "",
                        },
                    type: props?.typeData ? props.typeData : ""
                    }
                    break;
                }
                
                obj = {
                    anagrafica:{
                        "Nome": user.name,
                        "Cognome": user.familyName,
                        "CodiceFiscale": user.fiscalNumber,
                        "Data Di Nascita": "",
                        "Luogo Di Nascita": "",
                    },
                    indirizzi: {
                        "Residenza": "",
                        "Domicilio": "",
                    },
                    contatti: {
                        "Telefono": "",
                        "Email": "",
                    },
                    documenti: {
                        "Carta Di Identita": "",
                    }
                };
                break;
            case 'RMS':
                if(props?.id === "Beneficiario") {
                    obj = {
                        anagrafica:{
                            "Nome": anagraficData[0].anagrafica.Nome,
                            "Cognome": anagraficData[0].anagrafica.Cognome,
                            "CodiceFiscale": anagraficData[0].anagrafica.CodiceFiscale,
                            "Data Di Nascita": anagraficData[0].anagrafica["Data Di Nascita"],
                            "Luogo Di Nascita": anagraficData[0].anagrafica["Luodo Di Nascita"],
                        },
                        indirizzi: {
                            "Residenza": anagraficData[0].indirizzi.Residenza,
                            "Domicilio": anagraficData[0].indirizzi.Domicilio,
                        },
                        documenti: {
                            "Carta Di Identita": anagraficData[0].documenti["Carta Di Identita"],
                            "Certificato dieta patologica": anagraficData[0].documenti["Carta Di Identita"]
                        },
                        type: props?.typeData ? props.typeData : ""
                    }
                    break;
                }
                
                obj = {
                    anagrafica:{
                        "Nome": user.name,
                        "Cognome": user.familyName,
                        "CodiceFiscale": user.fiscalNumber,
                        "Data Di Nascita": anagraficData[0].anagrafica["Data Di Nascita"],
                        "Luogo Di Nascita": anagraficData[0].anagrafica["Luodo Di Nascita"],
                    },
                    indirizzi: {
                        "Residenza": anagraficData[0].indirizzi.Residenza,
                        "Domicilio": anagraficData[0].indirizzi.Domicilio,
                    },
                    contatti: {
                        "Telefono": anagraficData[0].contatti.Telefono,
                        "Email": anagraficData[0].anagrafica.Email
                    },
                    documenti: {
                        "Carta Di Identita": anagraficData[0].documenti["Carta Di Identita"],
                    }
                };
                break;
            case 'PTI':
              obj = {
                anagrafica:{
                    "Nome": user.name,
                    "Cognome": user.familyName,
                    "CodiceFiscale": user.fiscalNumber,
                    "Data Di Nascita": anagraficData[0].anagrafica["Data Di Nascita"],
                    "Luogo Di Nascita": anagraficData[0].anagrafica["Luodo Di Nascita"],
                },
                indirizzi: {
                    "Residenza": anagraficData[0].indirizzi.Residenza,
                    "Domicilio": anagraficData[0].indirizzi.Domicilio,
                },
                contatti: {
                    "Telefono": anagraficData[0].contatti.Telefono,
                    "Email": anagraficData[0].anagrafica.Email
                },
                documenti: {
                    "Carta Di Identita": anagraficData[0].documenti["Carta Di Identita"],
                }
            };
            break;
            case 'SSD': 
                obj = {
                    anagrafica:{
                        "Nome": user.name,
                        "Cognome": user.familyName,
                        "CodiceFiscale": user.fiscalNumber,
                    },
                    contatti: {
                        "Telefono":  anagraficData[0].contatti.Telefono,
                        "Email": anagraficData[0].contatti.Email
                    }
                }
                break;
            case 'SPA':
                obj = {
                    anagrafica:{
                        "Nome": user.name,
                        "Cognome": user.familyName
                    },
                    contatti: {
                        "Email": anagraficData.Email
                    }
                }
                break;
            
            default : 
                break;
        }
        return obj;
    }
 
    const [data,setData] = useState(dynamicBaseObjectCalculation());

    useEffect(() => {
        let obj = {}

        if(props.service === "SPA") {
            obj = structuredClone(anagraficData)
            return;
        }

        for(let i of anagraficData) {
            if(i.anagrafica.CodiceFiscale === props.taxID) {
                obj = structuredClone(i)
                break;
            }
        }
        setData(obj);
    },[])

    const edit = () => {
        if(props.service === "SPA") {
            const newData = {
                Nome: data.anagrafica.Nome,
                Cognome: data.anagrafica.Cognome,
                Email: data.contatti.Email
            }

            dispatcher(stepSlice.actions.dynamicEdit({id: "Ufficio",data: newData}));
            props.close();
            return;
        }

        dispatcher(stepSlice.actions.dynamicEdit({id: props.id,type:"anagrafica",data: data}));
        props.close();
    }

    const dynamicObjectCalculation = () => {
        switch(props.service) {
            case 'RTS':
                return (
                    <PopupBox close={props.close} save={edit} title={props?.id}> 
                    {
                        error &&
                            <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                    }
                 
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Nome" 
                            fieldTitle="Nome*"
                            defaultValue={data.anagrafica.Nome}
                            disabled={props.id === "Beneficiario" ? false : true}
                            description={props.id === "Beneficiario" ? "" : " "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Cognome" 
                            fieldTitle="Cognome*"
                            defaultValue={data.anagrafica.Cognome}
                            disabled={props.id === "Beneficiario" ? false : true}
                            description={props.id === "Beneficiario" ? "" : " "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="CodiceFiscale" 
                            fieldTitle="Codice Fiscale*"
                            defaultValue={data.anagrafica.CodiceFiscale}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="date"  
                            id="Data Di Nascita" 
                            fieldTitle="Data di nascita*"
                            defaultValue={data.anagrafica["Data Di Nascita"]}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Luogo Di Nascita" 
                            fieldTitle="Luogo di nascita*"
                            defaultValue={data.anagrafica["Luogo Di Nascita"]}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Residenza" 
                            fieldTitle="Indirizzo residenza*"
                            defaultValue={data.indirizzi.Residenza}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Domicilio" 
                            fieldTitle="Comune residenza*"
                            defaultValue={data.indirizzi.Domicilio}
                            />

                        {
                            props?.id !== "Beneficiario" && 
                                <>
                                    <PopupTextField 
                                        onChange={(e) => {onDataChange2(e,setData)}}
                                        fieldType="text"  
                                        id="Telefono"
                                        fieldTitle="Telefono"
                                        defaultValue={data.contatti.Telefono}
                                        />
                                    <PopupTextField 
                                        onChange={(e) => {onDataChange2(e,setData)}}
                                        fieldType="email" 
                                        id="Email"
                                        fieldTitle="Email*"
                                        defaultValue={data.contatti.Email}
                                        />
                                </>
                        }
            
                        <PopupTextField onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="file"  
                            id={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identita"} 
                            fieldTitle={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identità*"}/>
                    
                    
                </PopupBox>
                )
            case 'RMS':
                return (
                    <PopupBox close={props.close} save={edit} title={props?.id}> 
                        {
                            error &&
                                <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                        }
                    <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Nome" 
                            fieldTitle="Nome*"
                            defaultValue={data.anagrafica.Nome}
                            disabled={props.id === "Beneficiario" ? false : true}
                            description={props.id === "Beneficiario" ? "" : " "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Cognome" 
                            fieldTitle="Cognome*"
                            defaultValue={data.anagrafica.Cognome}
                            disabled={props.id === "Beneficiario" ? false : true}
                            description={props.id === "Beneficiario" ? "" : " "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="CodiceFiscale" 
                            fieldTitle="Codice Fiscale*"
                            defaultValue={data.anagrafica.CodiceFiscale}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="date"  
                            id="Data Di Nascita" 
                            fieldTitle="Data di nascita*"
                            defaultValue={data.anagrafica["Data Di Nascita"]}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Luogo Di Nascita" 
                            fieldTitle="Luogo di nascita*"
                            defaultValue={data.anagrafica["Luogo Di Nascita"]}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Residenza" 
                            fieldTitle="Indirizzo residenza*"
                            defaultValue={data.indirizzi.Residenza}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Domicilio" 
                            fieldTitle="Comune residenza*"
                            defaultValue={data.indirizzi.Domicilio}
                            />
                        {
                            props?.id !== "Beneficiario" && 
                                <>
                                    <PopupTextField 
                                        onChange={(e) => {onDataChange2(e,setData)}}
                                        fieldType="text"  
                                        id="Telefono"
                                        fieldTitle="Telefono"
                                        defaultValue={data.contatti.Telefono}
                                         />
                                    <PopupTextField 
                                        onChange={(e) => {onDataChange2(e,setData)}}
                                        fieldType="email" 
                                        id="Email"
                                        fieldTitle="Email*"
                                        defaultValue={data.contatti.Email}
                                        />
                                </>
                        }
            
                        {
                            props?.id === "Beneficiario" &&
                                <PopupTextField onChange={(e) => {onDataChange2(e,setData)}} 
                                    fieldType="file"  
                                    id="Certificato dieta patologica"
                                    fieldTitle={"Certificato dieta patologica"}/>
                        }
            
                        <PopupTextField onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="file"  
                            id={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identita"} 
                            fieldTitle={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identità*"}/>
                    </PopupBox>
                )
            case 'PTI':
                return (
                    <PopupBox close={props.close} save={edit} title={props?.id}> 
                        {
                            error &&
                                <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                        }
                    
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Nome" 
                            fieldTitle="Nome*"
                            defaultValue={data.anagrafica.Nome}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Cognome" 
                            fieldTitle="Cognome*"
                            defaultValue={data.anagrafica.Cognome}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="CodiceFiscale" 
                            fieldTitle="Codice Fiscale*"
                            defaultValue={data.anagrafica.CodiceFiscale}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="date"  
                            id="Data Di Nascita" 
                            fieldTitle="Data di nascita*"
                            defaultValue={data.anagrafica["Data Di Nascita"]}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Luogo Di Nascita" 
                            fieldTitle="Luogo di nascita*"
                            defaultValue={data.anagrafica["Luogo Di Nascita"]}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Residenza" 
                            fieldTitle="Indirizzo residenza*"
                            defaultValue={data.indirizzi.Residenza}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="text"  
                            id="Domicilio" 
                            fieldTitle="Comune residenza*"
                            defaultValue={data.indirizzi.Domicilio}
                            />
                        {
                            props?.id !== "Beneficiario" && 
                                <>
                                    <PopupTextField 
                                        onChange={(e) => {onDataChange2(e,setData)}}
                                        fieldType="text"  
                                        id="Telefono"
                                        fieldTitle="Telefono"
                                        defaultValue={data.contatti.Telefono}
                                         />
                                    <PopupTextField 
                                        onChange={(e) => {onDataChange2(e,setData)}}
                                        fieldType="email" 
                                        id="Email"
                                        fieldTitle="Email*"
                                        defaultValue={data.contatti.Email}
                                        />
                                </>
                        }
            
                        {
                            props?.id === "Beneficiario" &&
                                <PopupTextField onChange={(e) => {onDataChange2(e,setData)}} 
                                    fieldType="file"  
                                    id="Certificato dieta patologica"
                                    fieldTitle={"Certificato dieta patologica"}/>
                        }
            
                        <PopupTextField onChange={(e) => {onDataChange2(e,setData)}} 
                            fieldType="file"  
                            id={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identita"} 
                            fieldTitle={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identità*"}/>
                    </PopupBox>
                )
            case 'SSD': 
                return (
                    <PopupBox close={props.close} save={edit} title={props?.id}> 
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="text"
                            id="Nome" 
                            fieldTitle="Nome*"
                            defaultValue={data.anagrafica.Nome}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="text"
                            id="Cognome" 
                            fieldTitle="Cognome*"
                            defaultValue={data.anagrafica.Cognome}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="text"
                            id="CodiceFiscale" 
                            fieldTitle="Codice fiscale*"
                            defaultValue={data.anagrafica.CodiceFiscale}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="text"
                            id="Telefono" 
                            fieldTitle="Telefono"
                            defaultValue={data.contatti.Telefono}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="email"
                            id="Email"  
                            fieldTitle="Email*"
                            defaultValue={data.contatti.Email}
                            />
                    </PopupBox>
                )
            case 'SPA': 
                return (
                    <PopupBox close={props.close} save={edit} title={props?.id}> 
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="text"
                            id="Nome" 
                            fieldTitle="Nome*"
                            defaultValue={data.anagrafica.Nome}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="text"
                            id="Cognome" 
                            fieldTitle="Cognome*"
                            defaultValue={data.anagrafica.Cognome}
                            disabled={true}
                            description={" "}
                            />
                        <PopupTextField 
                            onChange={(e) => {onDataChange2(e,setData)}}
                            fieldType="email"
                            id="email" 
                            fieldTitle="Email*"
                            defaultValue={data.contatti.Email}
                            />
                    </PopupBox>
                )        
            default : 
                break;
        }
    }
    return (
        <>
            {
                dynamicObjectCalculation()
            }
        </>
    )
}

export default RegistryEditPopup;