import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import ProtectedRoute from "./util/ProtectedRoute";
import reportWebVitals from "./reportWebVitals";
import AccessoServizi from "./Pages/_Auth/AccessoServizi";
import Login from "./Pages/_Auth/Login";
import Loading from "./Pages/Loading";
import MensaScolastica from "./Pages/Servizi-a-pagamento/MensaScolastica";
import TrasportoScolastico from "./Pages/Servizi-a-pagamento/TrasportoScolastico";
import IscrizioneScuolaInfanzia from "./Pages/Iscrizione-a-graduatoria/IscrizioneScuolaInfanzia";
import AreaPersonale from "./Pages/_Utenti/AreaPersonale";
import PrenotaAppuntamento from "./Pages/Commons/PrenotaAppuntamento";
import SegnalazioneDisservizio from "./Pages/Commons/SegnalazioneDisservizio";
import RichiestaAssistenza from "./Pages/Commons/RichiestaAssistenza";
import PagamentoIMU from "./Pages/Pagamenti-dovuti-F24/PagamentoIMU";
import Services from "./Pages/Services";
import store from "./context/store/ContextStore";
import { Provider } from "react-redux";
import PaymentPage from "./Pages/_Partials/PaymentPage";

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route path="/login-spid" element={<AccessoServizi />} />
          <Route path="/SPIDlogin/:idp" element={<Loading />} />
          <Route path="/authentication/:token" element={<Loading />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<App />}>
          <Route
              path="/payment-page"
              element={
                <ProtectedRoute>
                  <PaymentPage/>
                </ProtectedRoute>
              }
            />
            <Route
              path=""
              element={
                <ProtectedRoute>
                  <AreaPersonale />
                </ProtectedRoute>
              }
            />
             <Route
              path="/servizi"
              element={
                <ProtectedRoute>
                  <Services />
                </ProtectedRoute>
              }
            />
            <Route
              path="/prenota-appuntamento"
              element={
                <ProtectedRoute>
                  <PrenotaAppuntamento code="SPA"/>
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/segnalazione-disservizio"
              element={
                <ProtectedRoute>
                  <SegnalazioneDisservizio code="SSD"/>
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/richiesta-assistenza"
              element={
                <ProtectedRoute>
                  <RichiestaAssistenza code="SRA"/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/pagamento-imu"
              element={
                <ProtectedRoute>
                  <PagamentoIMU  code={"PTI"}/>
                </ProtectedRoute>
              }
            />{" "}
            <Route
              path="/mensa-scolastica"
              element={
                <ProtectedRoute>
                  <MensaScolastica code="RMS"/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/trasporto-scolastico"
              element={
                <ProtectedRoute>
                  <TrasportoScolastico code="RTS" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/iscrizione-scuola-infanzia"
              element={
                <ProtectedRoute>
                  <IscrizioneScuolaInfanzia />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
