import { useEffect, useState } from "react";

import AuthenticatedTenant from "../../Layouts/AuthenticatedLayoutTenant";
import HelpBox from "../../UI-component/HelpBopx";
import WorkPath from "../../UI-component/WorkPath";
import DynamicStepContent from "../../UI-component/DynamicStepContent";
import First from "../_Partials/First";
import Second from "../_Partials/Second";
import Third from "../_Partials/Third";
import Fourth from "../_Partials/Fourth";
import Fifth from "../_Partials/Fifth";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";

export default function PrenotaAppuntamento(props) {
  const dispatcher = useDispatch();

  const [header, setHeader] = useState("");
  const [user, setUser] = useState("");

  //initialize localStorage conf once when component mounts ?
  useEffect(() => {
    //inizioalize first view first step
    if(localStorage.getItem("configuration")) {
      const config = JSON.parse(localStorage.getItem("configuration"));
      setHeader(config.data.current);
    }
    if(localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      setUser(user);
    }
  }, []);

  useEffect(() => {
    clearStore();    
  },[]);

  const clearStore = () => {
    dispatcher(stepSlice.actions.clear())
  } 
  
  return (
    <AuthenticatedTenant auth={user} errors={props.errors} header={header}>
      <main>
        <WorkPath workPathInfo={{
          base: ["Home","https://stellanello.nemea.cloud/"],
          medium: ["Servizi", "https://stellanello.nemea.cloud/servizi/"],
          end: "Prenotazione appuntamento"
        }}/>

        <div className="container" id="main-container">
          <div className="row justify-content-center" >
              <DynamicStepContent noPriv={true} service={props.code}
               successTitle={"Prenotazione appuntamento"}
               successDesc={`
                   Inserisci le informazioni necessarie per prenotare l'appuntamento.<br />
                   Potrai ricontrollare tutti i dati nel riepilogo, prima di
                   inviare la richiesta.`}
                stepList={["Luogo", "Data e orario","Dettagli appuntamento","Richiedente","Riepilogo"]}
                steps={[
                  {component: First,info: {}},
                  {component: Second,info: {disp: "disp"}},
                  {component: Third,info: {}},
                  {component: Fourth,info: {}},
                  {component: Fifth,info: {}},
                ]}/>
            </div>
          </div>
        
        <HelpBox helpBoxInfo={{
            muniQuestionLink: "https://stellanello.nemea.cloud/domande-frequenti/",
            muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
            greenNumber: "tel:+39 0182 668000",
            bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
            rDisservice: ""
          }}/>
      </main>
    </AuthenticatedTenant>
  );
}
