import React, { useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import { checkValidity, dynamicRenderer, onDataChange2, uniqueIdBuilder } from "../../util/Dynamics";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { useSelector, useDispatch } from "react-redux";
import { OfficeAPI } from "../../Apis/OfficeAPI";
import ErrorAlert from "../../UI-component/ErrorAlert";
import stepSlice from "../../context/StepsContext";
 
//OFFICE SELECTOR
function Second(props) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data.ufficio);
    const [officeDetails,setOfficeDetails] = useState({});
    const [appointmentDetails,setAppointmentDetails] = useState(null);
    const [error,setError] = useState({
        appointment: true,
        availability: true,
    })

    useEffect(() => {
        getOfficeDetails();
        errorChecker();
    },[])

    useEffect(() => {
        if(ctx?.appuntamenti_disponibili?.id ) 
            getAppointmentDetails();
    },[ctx])


    const getOfficeDetails = async () => {
        try{
            const res = await OfficeAPI.getOfficesById(ctx.ufficio.id);

            if(!res.success) {
                return;
            }

            setOfficeDetails(res.data)
        }catch(err) {
            console.log(err);
        }
    }

    const getAppointmentDetails = async () => {
        try{
            const res = await OfficeAPI.getAppointments(ctx.ufficio.id,ctx.appuntamenti_disponibili.id);

            if(!res.success) 
                return;
            
            let items = [];

            for(let [x,y] of res.data.entries()) 
                items.push({id: x,value: y.startDate})
        
            setAppointmentDetails(items);

        }catch(err) {
            console.log(err);
        }
    }

    const calculateDate = () => {
        const dates = [];
    
        for(let i = 0; i < 14; i++) {
            const date = new Date()
    
            date.setDate(date.getDate() + i);
            const itm = date.toLocaleDateString("IT-it",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
            if(itm.includes("sabato") || itm.includes("domenica"))
                continue;
                
                
            
            var start = new Date(date.getFullYear(), 0, 0);
            var diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
            var oneDay = 1000 * 60 * 60 * 24;
            var day = Math.floor(diff / oneDay);
            
            dates.push({value: date.toLocaleDateString("IT-it",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }), id: day})
        }
        return dates;
    }

    const errorChecker = () => {  
        let isError = false;

        if(ctx?.appuntamenti_disponibili) 
            setError(prev => {return {...prev, appointment: !checkValidity(ctx?.appuntamenti_disponibili,true)}})
        else
            isError = true;
        if(ctx?.disponibilita) 
            setError(prev => {return {...prev, availability: !checkValidity(ctx?.disponibilita,true)}})
        else
            isError = true;

        dispatcher(stepSlice.actions.switchError({error: isError}))
    }
    
    return (
        <React.Fragment>
            <SideList
             infoList={[{element: "Appuntamenti disponibili", id: "#appuntamenti-disponibili" },{element: "Ufficio", id: "#office" }]}/>
            <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <section>
                    {
                        <ErrorAlert errors={[{ name: "Appuntamenti disponibili", id: "#isee-info" }]} err={error.appointment} />
                    }


                    <SelectionFormBox
                        firstSelection={"Seleziona un giorno"}
                        showTitle={"Appuntamenti disponibili*"}
                        title="Appuntamenti Disponibili"
                        description=""
                        selectTitle="Seleziona l'ufficio"
                        choices={calculateDate()}
                        defaultValue={ctx?.appuntamenti_disponibili?.value ? ctx?.appuntamenti_disponibili?.value : "Seleziona un'opzione"}
                        oKey={"Ufficio"}
                        objectify={true}
                        trs={getAppointmentDetails}
                        >
                        <div>
                        {
                            appointmentDetails ?
                            <>
                                <SelectionFormBox
                                disp={props.info.disp}
                                firstSelection={"Seleziona un orario"}
                                showTitle={""}
                                title="disponibilita"
                                description=""
                                selectTitle="Seleziona l'ufficio"
                                choices={appointmentDetails}
                                defaultValue={ctx?.disponibilita?.value ? ctx?.disponibilita?.value : "Seleziona un'opzione"}
                                oKey={"Ufficio"}
                                objectify={true}/>
                            </>:
                             <SelectionFormBox
                             disp={props.info.disp}
                             showTitle={""}
                             firstSelection={"Seleziona un orario"}
                             title="disponibilita"
                             description=""
                             selectTitle="Seleziona l'ufficio"
                             choices={[]}
                             defaultValue={ctx?.disponibilita?.value ? ctx?.disponibilita?.value : "Nessunn appuntamento disponibile."}
                             oKey={"Ufficio"}
                             objectify={true}/>
                        }
                        </div>      
                    
                    </SelectionFormBox>


                    <SummaryCard  mod={false} cardTitle={officeDetails.title} type="Ufficio" key={uniqueIdBuilder()}>
                        <FormBoxField value={officeDetails.address ? officeDetails.address  : "Non Compilato" } title={"Indirizzo"}  key={uniqueIdBuilder()}/>
                        <FormBoxField value={officeDetails.timetables  ? officeDetails.timetables : 'Non Compilato' } title={"Apertura"}  key={uniqueIdBuilder()}/>
                    </SummaryCard>

                </section>
            </div>
        </React.Fragment>
    )
}

export default Second;