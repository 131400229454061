import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import "../../global.css";

export default function Privacy(props) {
  const dispatcher = useDispatch();

  //Updates the context pricavy 
  const privacyAgreement = (e) => {
    dispatcher(stepSlice.actions.togglePvt(e.target.checked));
  }

  //Updates the context whenever the (privacy) button is ticked
  useEffect(() => {
    dispatcher(stepSlice.actions.togglePvt(false));    
  },[dispatcher])

  useEffect(() => {
    dispatcher(stepSlice.actions.switchError({error: false}))
  },[])

  console.log(props);

  return (
    <div id="step1" className="container">
      <div className="row justify-content-center">
        <div className={`col-12 col-lg-8 pb-40 pb-lg-80 ${props.service === "assistenza" ? "mediaq" : ""}`}>
          <p className="text-paragraph mb-lg-4">{props.info?.description ? props.info?.description : ""}</p>
          {
            props.info?.linkPrivacy &&
              <p className={`text-paragraph mb-0`}>
                Per i dettagli sul trattamento dei dati personali consulta&nbsp;
                <a href={props.info?.linkPrivacy && props.info?.linkPrivacy} 
                  className="t-primary">{props.info?.title?.toLowerCase() ? props.info?.title?.toLowerCase() : ""}
                  l'informativa sulla privacy                  
                </a>
              </p>

          }
          <div className="form-check mt-4 mb-3 mt-md-40 mb-lg-40">
            <div className="checkbox-body d-flex align-items-center">
              <input
                type="checkbox"
                id="privacy"
                name="privacy-field"
                onClick={privacyAgreement}
              />
              <label
                className="title-small-semi-bold pt-1"
                htmlFor="privacy"
              >
                Ho letto e compreso l’informativa sulla privacy
              </label>
            </div>
          </div>
        </div>
      </div>
     </div>
  );
}
