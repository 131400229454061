import React from "react";
import "../global.css";

function SuccessfullPopup(props) {
    return (
        <>
        {
            !props.isError ? 
            <>
                <div style={{padding: "20px"}}></div>
                <div className="first">
                    <div className="internal"></div>
                    <div>
                        <p className="succP">RICHIESTA SALVATA CON SUCCESSO</p>
                    </div>
                </div>
                <div style={{padding: "20px"}}></div> 
            </>:
            <>
                <div style={{padding: "30px"}}></div>
                <div className="first err">
                    <div className="internal-err"></div>
                    <div>
                        <p className="succP">{props.msg || "C'E STATO UN ERRORE CON LA RICHIESTA"}</p>
                    </div>
                </div>
                <div style={{padding: "20px"}}></div>
            </>
            
        }
           
        </>
    )
}

export default SuccessfullPopup;