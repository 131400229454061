import React, { useEffect, useState } from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import FormSlider from "../../UI-component/ui-el/FormSlider";
import CustomerPropertyFormBox from "../../UI-component/registryForms/CustomerPropertyFormBox";
import SideList from "../../UI-component/SideList";
import { useSelector,useDispatch } from "react-redux";
import ErrorAlert from "../../UI-component/ErrorAlert";
import stepSlice from "../../context/StepsContext";

export default function PagamentiIMUDatiPersonali(props) {
  const dispatcher = useDispatch();
  const taxPayerCtx = useSelector(state => state.stepSlice.data.imu.contribuente);
  const realtyCtx = useSelector(state => state.stepSlice.data.imu.immobili);
  const [error,setError] = useState({
    applicant: true,
    property: true
  });

  const errorChecker = () => {   
    let isError = false;
    let idx = 0;
    if(taxPayerCtx.length >= 0) {
      for(let itm of taxPayerCtx) {
        if(itm?.selected) {
          setError(prev => {return{...prev,applicant: false}})
          break;
        }
      
        if(idx === taxPayerCtx.length - 1)
          isError = true;
        idx += 1;
      }
  
    }else
      isError = true;

    if(realtyCtx.length > 0)
      setError(prev => {return{...prev,property: false}})
    else
      isError = true;
    
    dispatcher(stepSlice.actions.switchError({error: isError}))
  }

  useEffect(() => {
    errorChecker();
  },[])

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Contribuente", id: "#taxpayer" },
          { element: "Coobligato", id: "#co-obliged" },
          { element: "Immobili", id: "#properties" },
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1">
        <div className="it-page-sections-container">

          {
              <ErrorAlert errors={[{ name: "Contribuente", id: "#isee-info" }]} err={error.applicant}/>
          }
          {
              <ErrorAlert errors={[{ name: "Immobili", id: "#isee-info" }]} err={error.property}/>
          }
          <CustomerFormBox 
            id="contribuente"
            cards={[taxPayerCtx]}
            type={true}
            description="Seleziona o aggiungi il contribuente che pagherà l’imposta"
            title="Contribuente"
            service={props.info.code}
          />

          <FormSlider  id="co-obliged" />

          <CustomerPropertyFormBox  id="properties"
            cards={[realtyCtx]}
          />
        </div>
      </div>
    </div>
  );
}
