import { apiAuth } from "./Configs/AxiosConfigs"

export const PaymentApi = {

    getPayments: async function () {
        const response = await apiAuth.request({
          url: "/payments/",
          method: "GET",
        })  
        return response.data;
      }
 
}
