import React,{useRef} from "react";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import GuestLayout from '../../Layouts/GuestLayout';

export default function Login() {
  const navigate = useNavigate();
  const logFormRef = useRef(null);
  const btnLogRef = useRef(null); 

  const submitLoginForm = async (event) => {
    event.preventDefault();

    const loginAPI = "http://localhost:8000/api/login";

    const formData = new FormData(logFormRef.current);
    const formDataJSON = Object.fromEntries(formData);
    
    btnLogRef.current.value = "Please wait..";
    btnLogRef.current.setAttribute("disabled", true);
    
    try{
      const response = await axios.post(loginAPI, formDataJSON);

      btnLogRef.current.value = "Login";
      btnLogRef.current.removeAttribute('disabled');

      const data = response.data.data;
      const token = data.access_token;

      if (!token) {
        alert("Unable to login. Please try after some time.");
        return;
      }

      localStorage.clear();
      localStorage.setItem("access_token", token);

      setTimeout(() => navigate("/"), 500);
    }catch(error) {
      console.log("Login error:",error);
      btnLogRef.current.value = "Login";
      btnLogRef.current.removeAttribute('disabled');
      alert("Oops! Some error occured.");
    }
  };

  return (
    <GuestLayout>
      <Container className="my-5">
        <h2 className="fw-normal mb-5"> Login </h2>{" "}
        <Row>
          <Col md={{ span: 6 }}>
            <Form ref={logFormRef} id="loginForm" onSubmit={submitLoginForm}>
              <FormGroup className="mb-3">
                <FormLabel htmlFor={"login-username"}> Username </FormLabel>{" "}
                <input
                  type={"text"}
                  className="form-control"
                  id={"login-username"}
                  name="email"
                  required
                />
              </FormGroup>{" "}
              <FormGroup className="mb-3">
                <FormLabel htmlFor={"login-password"}> Password </FormLabel>{" "}
                <input
                  type={"password"}
                  className="form-control"
                  id={"login-password"}
                  name="password"
                  required
                />
              </FormGroup>{" "}
              <Button ref={btnLogRef} type="submit" className="btn-success mt-2" id="login-btn">
                {" "}
                Login{" "}
              </Button>{" "}
              <a href="/register">
                Registrati
              </a>
            </Form>{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>{" "}
    </GuestLayout>
  );
}
