import React from "react";


function CompactInfo(props) {
    const innerHtml = props.description;
    //  TODO: UFFICIO SELEZIONATO SELEZIONE SE GIA DENTRO UN SERVIZIO
    return (
        <div className="cmp-heading pb-3 pb-lg-4">
            <h1 className="title-xxxlarge">{props.title}</h1>
             <p className="subtitle-small" dangerouslySetInnerHTML={{ __html: innerHtml }} />
            <p className="subtitle-small mt-lg-2 mb-4">
            Hai bisogno di assistenza?&nbsp;
            <a className="title-small-semi-bold t-primary" href="https://servizi.stellanello.nemea.cloud/richiesta-assistenza/">Contattaci</a>
            </p>
      </div>
    )
}

export default CompactInfo