import {createSlice} from "@reduxjs/toolkit";

const initState = {
    privacy: false,
    data: {
        richiedente: [],
        beneficiario: [],
        "autore_della_segnalazione": [],
        disservizio: {},
        isee: [],
        preferenze: {
            "scuola":"",
            "classe": "",
            "tariffa_esonero":"",
            "tariffa_riduzione": "",
            "frequenza": "",
            "dieta_diversificata":"",
            certificato_disabilita: ""
        },
        richiesta: {
            applicant: {

            },
            request: {

            }
        },  
        ufficio: {
            richiedente: {}
        },
        imu: {
            contribuente: [],
            immobili: [],
            coobbligato: {}
        },
        error: false
    }
}

const stepSlice = createSlice({
    name: "stepSlice",
    initialState: initState,
    reducers: {
        togglePvt,
        dynamicSave,
        dynamicEdit,
        changeSelected,
        switchError,
        clear,
        onDraftData
    }
})

//updaed the privacy (given session)
function togglePvt(state,action) {
    state.privacy = action.payload;
}

function dynamicSave(state,action) {
    switch(action.payload.id) {
        case "Beneficiario": 
            state.data.beneficiario.push(action.payload.data);
            break;
        case "Richiedente":
            state.data.richiedente.push(action.payload.data);
            break;
        case "ISEE": 
            state.data.isee.push(action.payload.data)
            break;
        case "Richiesta": 
            state.data.richiesta = action.payload.data;
            break;
        case "disservizio":    
            // const id = action.payload.internalId;
            // state.data.disservizio[id] = action.payload.data;
            state.data.disservizio = action.payload.data;
            break;
        case "Ufficio":
            if(action.payload.external === "richiedente") {
                state.data.ufficio.richiedente = action.payload.data;
                return;
            }

            if(action.payload.external){
                state.data.ufficio[action.payload.external][action.payload.internalId] = action.payload.data;
                return;
            }
            state.data.ufficio[action.payload.internalId] = action.payload.data;

            break;
        case "Autore della segnalazione": 
            state.data.autore_della_segnalazione.push(action.payload.data);
            break;
        case "Contribuente":
            state.data.imu.contribuente.push(action.payload.data);
            break;
        case "Immobili":
            state.data.imu.immobili.push(action.payload.data);
            break;
        case "Coobbligato":
                state.data.imu.coobbligato = action.payload.data;
            break;
        case "Preferenze":
            const internalID = action.payload.internalId;
            const data = action.payload.data;
            state.data.preferenze[internalID] = data;
            break;
        default: 
            break;
    }
}

function dynamicEdit(state,action) {
    switch(action.payload.id) {
        case "Beneficiario": 

            functionalEditFilter(state,action,"beneficiario")
            break;
        case "Richiedente":
            functionalEditFilter(state,action,"richiedente");
            break;
        case "ISEE": 
            state.data.isee[0] = action.payload.data;
            break;
        case "Autore della segnalazione": 
    
            for(let i = 0; i < state.data.autore_della_segnalazione.length; i++) {
    
                if(state.data.autore_della_segnalazione[i].anagrafica.CodiceFiscale === action.payload.data.anagrafica.CodiceFiscale) {
                    state.data.autore_della_segnalazione[i] = action.payload.data;
                    break;
                } 
            }
            // functionalEditFilter(state,action,"richiedente");
            break;
        case "Disservizio":
            state.data.disservizio = action.payload.data;
            break;
        case "Preferenze":
            const data = action.payload.data;
            state.data.preferenze = data;
            break;
        case "Contribuente":
            for(let i = 0; i < state.data["imu"]["contribuente"].length; i++) {
                if(state.data['imu']["contribuente"][i].anagrafica.CodiceFiscale === action.payload.data.anagrafica.CodiceFiscale) {
                    state.data['imu']["contribuente"][i] = action.payload.data;
                    break;
                } 
            }
            break;
        case "Immobili":

            if(action.payload.add) {
                let i = 0; 
                Object.keys(action.payload.data[0]).forEach(key => {
                    state.data.imu.immobili[i][key] = action.payload.data[i][key];
                    i +=1;
                })
            
                return;
            }

            for(let i = 0; i < state.data.imu.immobili.length; i++) {
                if(state.data.imu.immobili[i].id === action.payload.internalID)
                    state.data.imu.immobili[i] = action.payload.data;
            }
            break;
        case "Coobbligato":
            state.data.imu.coobbligato = action.payload.data;
            break;
        case "Ufficio": 
            state.data.ufficio.richiedente = action.payload.data;
            break;
        default: 
            break;
    }
}

function functionalEditFilter(state,action,id) {
    for(let i = 0; i < state.data[id].length; i++) {
        if(state.data[id][i].anagrafica.CodiceFiscale === action.payload.data.anagrafica.CodiceFiscale) {
            state.data[id][i] = action.payload.data;
            break;
        } 
    }
}

//updates the selection of the customer (session)
function changeSelected(state,action) {
    filtering(action.payload.id.replaceAll(" ","_").toLowerCase(),state,action);
}

function switchError(state,action) {
    state.data.error = action.payload.error;
}

//clears the store back to default (init state)
function clear(state) {
    state.privacy = false;

    state.data = {
        richiedente: [],
        beneficiario: [],
        isee: [],
        preferenze: {
            "scuola":"",
            "classe": "",
            "tariffa_esonero":"",
            "tariffa_riduzione": "",
            "frequenza": "",
            "dieta_diversificata":"",
            certificato_disabilita: ""
        },
        richiesta: {  
            applicant: {

            },
            request: {

            }
        },
        "autore_della_segnalazione": [],
        disservizio: {},
        ufficio: {richiedente: {}},
        imu: {
            contribuente: [],
            immobili: [],
            coobbligato: {}
        },
        error: false
    }
}

function onDraftData(state,action) {
    state.privacy = false;

    const data = action.payload.data;
    const saved = action.payload.saved;

    if(!saved) {
        state.data = {
            richiedente: data.richiedente,
            beneficiario: data.beneficiario,
            isee: data.isee,
            preferenze: data.preferenze,
            richiesta: data.richiesta,
            "autore_della_segnalazione": data.autore_della_segnalazione,
            disservizio: {},
            ufficio: data.ufficio,
            imu: data.imu,
            error: data.error
        }
    }else {
        state.data.beneficiario = data?.beneficiario ? data.beneficiario : state.data.beneficiario;
        state.data.richiedente = data?.richiedente ? data.richiedente : state.data.richiedente;;
        state.data.isee = data?.isee ? data.isee : state.data.isee;;
        //missing author, properties, taxpayer
    }
}


//used to filter customers by TAXID
function filtering(id,state,action) {
    if(!Array.isArray(state.data[id])) {
        for(let i of state.data['imu'][id]) {
            if(i.anagrafica.CodiceFiscale === action.payload?.taxID) 
                i.selected = true;
             else 
                i.selected = false;  
        } 
        return;
    }


    for(let i of state.data[id]) {
        if(i.anagrafica.CodiceFiscale === action.payload?.taxID) 
            i.selected = true;
         else 
            i.selected = false;  
    } 
}

export default stepSlice;