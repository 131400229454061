import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {  Alert } from "react-bootstrap";
import { ConfigurationAPI } from "../Apis/ConfigurationAPI";

const ProtectedRoute = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [config, setConfig] = useState(false);

  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUserToken = () => {
    const userToken = localStorage.getItem("access_token");

    if (!userToken) {
      setIsLoggedIn(false);
      if (config.current === null) {
        return <Alert> Dominio non gestito</Alert>;
      } else {
        localStorage.setItem("destination",window.location.pathname); 
        return navigate("/login-spid");
      }
    }

    setIsLoggedIn(true);
  };

  useEffect(() => {
    let abortController;

    (async () => {
      abortController = new AbortController();
      let signal = abortController.signal;

      try {
        const config = await ConfigurationAPI.getConfiguration(signal);
         localStorage.removeItem("configuration");
        localStorage.setItem("configuration", JSON.stringify(config));
        setConfig(config);
        checkUserToken();
      }catch(err) {
        console.log("Configuration error", err);
      }
    })();

    return () => abortController.abort();
  }, []);

  return (
    <React.Fragment>
      {
        isLoggedIn && 
          props.children 
      }
    </React.Fragment>
  );
};
export default ProtectedRoute;
