import React, { useState } from "react";
import IseeCard from "../registryForms/IseeCard";
import { uniqueIdBuilder } from "../../util/Dynamics";
import { IseeAddPopup } from "../AddingForms/IseeAddPopup";
import { useSelector } from "react-redux";


function IseeFormBox(props) {
    const iseeCtx = useSelector(state => state.stepSlice.data.isee);

    const [show,setShow] = useState(false);

    const addIsee = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }

    return ( 
       <>
            <section className="it-page-section" id={props.id}>
                <div className="cmp-card mb-40">
                    <div className="card has-bkg-grey shadow-sm p-big">
                    { 
                        show &&
                            <IseeAddPopup 
                                close={close}
                                id={props.title}
                                service={props.service}/>    
                    }  

                        <div className="card-header border-0 p-0 mb-lg-30 m-0">
                            <div className="d-flex">
                                <h2 className="title-xxlarge mb-1">ISEE {props.isee}</h2>
                            </div>
                            <p className="subtitle-small mb-0">
                                Informazioni sul valore dell’ISEE del tuo nucleo familiare basato sulla tua ultima dichiarazione
                                Se la certificazione ISEE non è stata richiesta per l'anno corrente, per il calcolo dell'importo 
                                non verrà applicata nessuna tariffa di riduzione
                            </p>
                            <br></br>
                        </div>

                        <div className="card-body p-0">
                            {
                                iseeCtx.length > 0 &&
                                    <IseeCard
                                        id={props.id}
                                        card={iseeCtx[0]}
                                        key={uniqueIdBuilder()}/>
                            }
                        </div>
                        {
                            iseeCtx.length === 0 &&
                                <button type="button" className="btn plus-text mt-20 p-0" onClick={addIsee}>
                                    <span className="rounded-icon">
                                        <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                        </svg>
                                    </span>
                                    <span className>Aggiungi {props?.id}</span>
                                </button>
                        }
                    </div> 

                </div>
            </section>
       </>
  )
}

export default IseeFormBox;