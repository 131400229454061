import React, { useState } from "react";
import ReactDOM  from "react-dom";

import PopupTextField from "../ui-el/PopupTextField";
import PopupBox from "../PopupBox";
import "../../global.css";

import stepSlice from "../../context/StepsContext";
import { useDispatch } from "react-redux";
import { checkValidity, onDataChange2, onFileSet2 } from "../../util/Dynamics";

// Popup that gets created to add to that specific form box
function AddConsumerPopup(props) {
    const dispatcher = useDispatch();
    const [error,setError] = useState(false);
    let user = JSON.parse(JSON.parse(localStorage.getItem("user")).data);

    const dynamicBaseObjectCalculation = () => {
        let obj = {};
        switch(props.service) {
            case 'RTS':
                if(props?.id === "Beneficiario") {
                    obj =   {
                        anagrafica:{
                            "Nome": "",
                            "Cognome": "",
                            "CodiceFiscale": "",
                            "Data Di Nascita": "",
                            "Luogo Di Nascita": "",
                            },
                        indirizzi: {
                            "Residenza": "",
                            "Domicilio": "",
                        },
                        documenti: {
                            "base64_certificato_disabilita": "",
                            "Certificato disabilita": "",
                        },
                    type: props?.typeData ? props.typeData : ""
                    }
                    break;
                }
                
                obj = {
                    anagrafica:{
                        "Nome": user.name,
                        "Cognome": user.familyName,
                        "CodiceFiscale": user.fiscalNumber,
                        "Data Di Nascita": "",
                        "Luogo Di Nascita": "",
                    },
                    indirizzi: {
                        "Residenza": "",
                        "Domicilio": "",
                    },
                    contatti: {
                        "Telefono": "",
                        "Email": "",
                    },
                    documenti: {
                        "base64_carta_di_identita": "",
                        "Carta Di Identita": "",
                    }
                };
                break;
            case 'RMS':
                if(props?.id === "Beneficiario") {
                    obj = {
                        anagrafica:{
                            "Nome": "",
                            "Cognome": "",
                            "CodiceFiscale": "",
                            "Data Di Nascita": "",
                            "Luogo Di Nascita": "",
                            },
                        indirizzi: {
                            "Residenza": "",
                            "Domicilio": "",
                        },
                        documenti: {
                            "base64_certificato_disabilita": "",
                            "Certificato disabilita": "",
                            "base64_certificato_dieta_patologica": "",
                            "Certificato dieta patologica": ""
                        },
                        type: props?.typeData ? props.typeData : ""
                    }
                    break;
                }
                
                obj = {
                    anagrafica:{
                        "Nome": user.name,
                        "Cognome": user.familyName,
                        "CodiceFiscale": user.fiscalNumber,
                        "Data Di Nascita": "",
                        "Luogo Di Nascita": "",
                    },
                    indirizzi: {
                        "Residenza": "",
                        "Domicilio": "",
                    },
                    contatti: {
                        "Telefono": "",
                        "Email": "",
                    },
                    documenti: {
                        "base64_carta_di_identita": "",
                        "Carta Di Identita": "",
                    }
                };
                break;
            case 'PTI':
              obj = {
                anagrafica:{
                    "Nome": props.typeData === "Persona giuridica" ? "" : user.name,
                    "Cognome": props.typeData === "Persona giuridica" ? "" : user.familyName,
                    "CodiceFiscale": props.typeData === "Persona giuridica" ? "" : user.fiscalNumber,
                    "Data Di Nascita": "",
                    "Luogo Di Nascita": "",
                },
                indirizzi: {
                    "Residenza": "",
                    "Domicilio": "",
                },
                contatti: {
                    "Telefono": "",
                    "Email": "",
                },
                documenti: {
                    "base64_carta_di_identita": "",
                    "Carta Di Identita": "",
                }
            };
            break;
            case 'SSD': 
            obj = {
                anagrafica:{
                    "Nome": user.name,
                    "Cognome": user.familyName,
                    "CodiceFiscale": user.fiscalNumber,
                },
                contatti: {
                    "Telefono": "",
                    "Email": ""
                },
                selected:  true
            }
            break;
            default : 
                break;
        }
        return obj;
    }

    const [customerData,setCustomerData] = useState(dynamicBaseObjectCalculation());

    const save = () => {
        if(checkValidity(customerData))
            dispatcher(stepSlice.actions.dynamicSave({id: props.id,data: customerData}));
        else
            setError(true);
    }

    const dynamicObjectCalculation = () => {
        switch(props.service) {
            case 'RTS':
                return (
                    <PopupBox close={props.close} save={save} title={props?.id}> 
                    {
                        error &&
                            <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                    }
                
                    <PopupTextField 
                        disabled={props.id === "Beneficiario" ? false : true}
                        defaultValue={props.id !== "Beneficiario" ? user.name : ""}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}} 
                        fieldType="text"
                        id="Nome"
                        fieldTitle="Nome*"
                        description={props.id === "Beneficiario" ? "" : " "}
                        />
                    <PopupTextField
                        defaultValue={props.id !== "Beneficiario" ? user.familyName : ""}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="Cognome"
                        fieldTitle="Cognome (denominazione, rag.sociale)*"
                        disabled={props.id === "Beneficiario" ? false : true}
                        description={props.id === "Beneficiario" ? "" : " "}
                        />
                    <PopupTextField
                        description={props.id === "Beneficiario" ? "" : " "}
                        defaultValue={props.id !== "Beneficiario" ? user.fiscalNumber : ""}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="CodiceFiscale"
                        fieldTitle="Codice Fiscale*"
                        disabled={props.id === "Beneficiario" ? false : true}
                        />
                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="date"  id="Data Di Nascita" fieldTitle="Data di nascita*"/>
                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Luogo Di Nascita" fieldTitle="Luogo di nascita*"/>
                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Residenza" fieldTitle="Indirizzo residenza*"/>
                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Domicilio" fieldTitle="Comune residenza*"/>
                    {
                        props?.id !== "Beneficiario" && 
                            <>
                                <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Telefono" fieldTitle="Telefono"/>
                                <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="email" id="Email"  fieldTitle="Email*"/>
                            </>
                    }
        
                    <PopupTextField onChange={(e) => {onFileSet2(e,setCustomerData)}} 
                        fieldType="file"  id={props.id === "Beneficiario" ? "Certificato disabilita" : "Carta Di Identita"}  
                        fieldTitle={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identità*"}/>
                </PopupBox>
                )
            case 'RMS':
                return (
                    <PopupBox close={props.close} save={save} title={props?.id}> 
                        {
                            error &&
                                <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                        }
                    

                    <PopupTextField 
                        disabled={props.id === "Beneficiario" ? false : true}
                        defaultValue={props.id !== "Beneficiario" ? user.name : ""}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}} 
                        fieldType="text"
                        id="Nome"
                        fieldTitle="Nome*"
                        description={props.id === "Beneficiario" ? "" : " "}
                        />
                    <PopupTextField
                        defaultValue={props.id !== "Beneficiario" ? user.familyName : ""}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="Cognome"
                        fieldTitle="Cognome (denominazione, rag.sociale)*"
                        disabled={props.id === "Beneficiario" ? false : true}
                        description={props.id === "Beneficiario" ? "" : " "}
                        />
                    <PopupTextField
                        description={props.id === "Beneficiario" ? "" : " "}
                        defaultValue={props.id !== "Beneficiario" ? user.fiscalNumber : ""}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="CodiceFiscale"
                        fieldTitle="Codice Fiscale*"
                        disabled={props.id === "Beneficiario" ? false : true
                    
                        }
                        />
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="date"  id="Data Di Nascita" fieldTitle="Data di nascita*"/>
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Luogo Di Nascita" fieldTitle="Luogo di nascita*"/>
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Residenza" fieldTitle="Indirizzo residenza*"/>
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Domicilio" fieldTitle="Comune residenza*"/>
                        {
                            props?.id !== "Beneficiario" && 
                                <>
                                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Telefono" fieldTitle="Telefono*"/>
                                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="email" id="Email"  fieldTitle="Email*"/>
                                </>
                        }
            
                        {
                            props?.id === "Beneficiario" &&
                                <PopupTextField onChange={(e) => {onFileSet2(e,setCustomerData)}} 
                                    fieldType="file"  id="Certificato dieta patologica"  fieldTitle={"Certificato dieta patologica"}/>
                        }
            
                        <PopupTextField onChange={(e) => {onFileSet2(e,setCustomerData)}} 
                            fieldType="file"  id={props.id === "Beneficiario" ? "Certificato disabilita" : "Carta Di Identita"}
                            fieldTitle={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identità*"}/>
                    </PopupBox>
                )
            case 'PTI':
                return (
                    <PopupBox close={props.close} save={save} title={props?.id}> 
                        {
                            error &&
                                <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                        }
                    
                    <PopupTextField 
                        defaultValue={props.typeData === "Persona giuridica" ? "" :  user.name}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}} 
                        fieldType="text"
                        id="Nome"
                        fieldTitle="Nome*"
                        description={" "}
                        />
                    <PopupTextField
                        defaultValue={props.typeData === "Persona giuridica" ? "" : user.familyName}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="Cognome"
                        fieldTitle="Cognome (denominazione, rag.sociale)*"
                        description={" "}
                        />
                    <PopupTextField
                        defaultValue={props.typeData === "Persona giuridica" ? "" : user.fiscalNumber}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="CodiceFiscale"
                        fieldTitle="Codice Fiscale*"
                        description={" "}

                        />
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="date"  id="Data Di Nascita" fieldTitle="Data di nascita*"/>
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Luogo Di Nascita" fieldTitle="Luogo di nascita*"/>
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Residenza" fieldTitle="Indirizzo residenza*"/>
                        <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Domicilio" fieldTitle="Comune residenza*"/>
                        {
                            props?.id !== "Beneficiario" && 
                                <>
                                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Telefono" fieldTitle="Telefono"/>
                                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="email" id="Email"  fieldTitle="Email*"/>
                                </>
                        }
            
                        {
                            props?.id === "Beneficiario" &&
                                <PopupTextField 
                                    onChange={(e) => {onFileSet2(e,setCustomerData)}} 
                                    fieldType="file"  
                                    id="Certificato dieta patologica"  
                                    fieldTitle={"Certificato dieta patologica"}/>
                        }
            
                        <PopupTextField 
                            onChange={(e) => {onFileSet2(e,setCustomerData)}} 
                            fieldType="file" 
                            id={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta Di Identita"} 
                            fieldTitle={props.id === "Beneficiario" ? "Certificato disabilità" : "Carta di identità*"}/>
                    </PopupBox>
                )
            case 'SSD': 
            return (
                <PopupBox close={props.close} save={save} title={props?.id}> 
                    {
                        error &&
                            <p style={{color: "red", fontWeight: "bolder"}}>Controlla i campi email o codice fiscale/Completa tutti i campi</p>
                    }

                    <PopupTextField 
                        disabled={true}
                        defaultValue={user.name}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}} 
                        fieldType="text"
                        id="Nome"
                        fieldTitle="Nome*"
                        description={" "}
                        />
                    <PopupTextField
                        defaultValue={user.familyName}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="Cognome"
                        fieldTitle="Cognome (denominazione, rag.sociale)*"
                        disabled={true}
                        description={" "}
                        />
                    <PopupTextField
                        defaultValue={user.fiscalNumber}
                        onChange={(e) => {onDataChange2(e,setCustomerData)}}
                        fieldType="text"
                        id="CodiceFiscale"
                        fieldTitle="Codice Fiscale*"
                        disabled={true}
                        /> 
                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="text"  id="Telefono" fieldTitle="Telefono*"/>
                    <PopupTextField onChange={(e) => {onDataChange2(e,setCustomerData)}} fieldType="email" id="Email"  fieldTitle="Email*"/>
                </PopupBox>
            )
            default : 
                break;
        }
    }

    return (
        <>
            {
                dynamicObjectCalculation()
            }
        </>
    )
}

const AddConsumerPopupModal = (props) => {
    return (
        <React.Fragment>
            {
                ReactDOM.createPortal(<AddConsumerPopup close={props.close} id={props.id} typeData={props.typeData} service={props.service}/>,
                    document.getElementById("overlay-root"))
            }
        </React.Fragment>
    )
}

export { AddConsumerPopupModal as AddConsumerPopup};