import { api, apiAuth } from "./Configs/AxiosConfigs"

export const ImuApi = {

    getImuConfig: async function (type) {
        const response = await apiAuth.request({
          url: `/category-imu/${type}`,
          method: "GET",
        });

        return response.data;
      },

    sendRequest: async function (imuObj) {
      const response = await apiAuth.request({
        url: `/sendRequest`,
        method: "POST",
        data: imuObj
      });
      return response.data;
    },
    
    downloadPDF:  async function (imuObj) {
      const response = await apiAuth.request({
        url: `/getDocument`,
        method: "POST",
        data: imuObj
      });
      return response.data;
    }

}