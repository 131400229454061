import React from "react";
import CustomerRegistry from "./CustomerRegistry";
import stepSlice from "../../context/StepsContext";
import { useDispatch, useSelector } from "react-redux";
import { uniqueIdBuilder } from "../../util/Dynamics";

function CustomerCard(props) {
    const dispatcher = useDispatch();
    const data = useSelector(state => state.stepSlice?.data); 

    const onChoice = (e) => {
        dispatcher(stepSlice.actions.changeSelected({id: props?.title, taxID: e.target.getAttribute("data-taxID")}))
    }

    const cardCreation = (itm,y,idx) => {
        
        if(props.title === "Contribuente") {
            if(data?.imu[props?.title.toLowerCase().replaceAll(" ","_")][y]?.selected) {
                return (
                    <>
                        <input checked="checked" onChange={onChoice} 
                            className="radio-input" name={props?.title}
                            type="radio" id={"a" + idx}
                            data-taxID={itm[props?.title.toLowerCase().replaceAll(" ","_")]?.anagrafica?.CodiceFiscale}/>
                        <label htmlFor={"a" + idx}>
                        <h3 className="big-title mb-0">{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                        </label> 
                    </>
                )
            }else {
                return (
                    <>
                        <input onChange={onChoice} 
                            className="radio-input"
                            name={props?.title} 
                            type="radio" id={"a" + idx} 
                            data-taxID={itm?.anagrafica?.CodiceFiscale}/>
                        <label htmlFor={"a" + idx}>
                        <h3 className="big-title mb-0">{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                        </label>
                    </>
                )
            }
        }

        if(props.title === "Autore della segnalazione") {
            return(
                <>
                    <label htmlFor={"a" + idx}>
                        <h3 >{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                    </label>
                </>
            )
        }

        if(data?.[props?.title.toLowerCase().replaceAll(" ","_")]) {
            return (data?.[props?.title.toLowerCase().replaceAll(" ","_")][y]?.selected ?
            <>
                <input checked="checked" onChange={onChoice} className="radio-input" name={props?.title} type="radio" id={"a" + idx} data-taxID={itm?.anagrafica?.CodiceFiscale}/>
                <label htmlFor={"a" + idx}>
                    <h3 className="big-title mb-0">{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                </label> 
            </>:
            <>
                <input onChange={onChoice} className="radio-input" name={props?.title} type="radio" id={"a" + idx} data-taxID={itm?.anagrafica?.CodiceFiscale}/>
                <label htmlFor={"a" + idx}>
                    <h3 className="big-title mb-0">{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                </label>
            </>)
        }else if(data?.[props?.id.toLowerCase().replaceAll(" ","_")]) {
            return (data?.[props?.id.toLowerCase().replaceAll(" ","_")][props?.title.toLowerCase().replaceAll(" ","_")][y]?.selected ?
            <>
                <input checked="checked" onChange={onChoice} className="radio-input" name={props?.title} type="radio" id={"a" + idx} data-taxID={itm[props?.title.toLowerCase().replaceAll(" ","_")]?.anagrafica?.CodiceFiscale}/>
                <label htmlFor={"a" + idx}>
                    <h3 className="big-title mb-0">{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                </label> 
            </>:
            <>
                <input onChange={onChoice} className="radio-input" name={props?.title} type="radio" id={"a" + idx} data-taxID={itm[props?.title.toLowerCase().replaceAll(" ","_")]?.anagrafica?.CodiceFiscale}/>
                <label htmlFor={"a" + idx}>
                    <h3 className="big-title mb-0">{itm?.anagrafica?.Nome} {itm?.anagrafica?.Cognome}</h3>
                </label>
            </>)
        }
     
    }
    
    return ( 
        <>
            {
                props.info &&
                    props.info.map((itm,y) => {
                        const idx = uniqueIdBuilder();
                        return (
                            <div className="cmp-info-button-card radio-card" 
                                key={uniqueIdBuilder()}>
                                <div className="card p-3 p-lg-4">
                                <div className="card-header mb-0 p-0">
                                    <div className="form-check m-0">
                                        {
                                            cardCreation(itm,y,idx)
                                        }
                                    </div>
                                </div>
                                    <div className="card-body p-0">
                                        {
                                            itm?.anagrafica?.CodiceFiscale &&
                                                <p className="card-info">Codice Fiscale <br />
                                                    <span>{itm?.anagrafica?.CodiceFiscale}</span>
                                                </p>
                                        }
                                        <CustomerRegistry 
                                            registryData={itm}
                                            key={uniqueIdBuilder()}
                                            id={props.title}
                                            taxID={itm?.anagrafica?.CodiceFiscale}
                                            title={props.id} service={props.service}/>
                                    </div>
                                </div>
                            </div>
                        )})
            }
        
        </>
  )
}

export default CustomerCard;