import React from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { useSelector } from "react-redux";
import { titleExtractor, uniqueIdBuilder } from "../../util/Dynamics";

export default function ServiziPagamentoRiepilogo(props) {
  const data = useSelector(state => state.stepSlice.data);

  // Renders customer preferences summary
  // based on the data that resides in the ctx
  const preferencesRenderer = () => {
    return (
      <SummaryCard mod={true} 
        cardTitle="Preferenze di servizio"
        type="Preferenze"
        choiceData={props.info.data.components}
        key={uniqueIdBuilder()}
        service={props.info.code}>
        {
          Object.keys(data.preferenze).map(el => {

            if(Array.isArray(data.preferenze[el])){
              return data.preferenze[el].map(itm => {
                return <FormBoxField 
                  title={titleExtractor(el)} value={itm}
                  key={uniqueIdBuilder()}/>
              })
            }else {
              if(data.preferenze[el]) {
                return <FormBoxField 
                  title={el.toLocaleLowerCase().includes("certificato") ? "Certificato disabilità" :  titleExtractor(el)} 
                  value={data.preferenze[el] ? data.preferenze[el] : "Non Compilato" }
                  key={uniqueIdBuilder()}/>
              }
            }
          })
        }
      </SummaryCard>
    )
  } 

  const toRemove = (el) => {
    const title = titleExtractor(el).replace("isee", "ISEE");

    return title;
  }

  // renders customer summary (isee, applicant,beneficiary)
  //based on the data that resides in the ctxS
  const customerRenderer = (key) => {  
    return (
      <div key={uniqueIdBuilder()}>
          {
            data[key] !== undefined &&
              data[key].map(itm => {                
                if(itm?.selected) {
                  const cardTitle = key !== "isee" ? (itm?.anagrafica?.Nome || "") + " " + (itm?.anagrafica?.Cognome || "") : "Dati ISEE";
                  return (
                    <SummaryCard 
                      taxID={itm?.anagrafica?.CodiceFiscale}
                      mod={true} cardTitle={cardTitle}
                      type={key.toLowerCase()} 
                      key={uniqueIdBuilder()}
                      service={props.info.code}>
                      {
                        Object.keys(itm).map(el => {
                          if(el.toLowerCase().includes("base64")) return;

                          if(el === "selected") return;

                          if(key === "isee") {
                            return <FormBoxField 
                              title={el.includes("isee") ? toRemove(el) : titleExtractor(el)} 
                              value={itm[el] ? itm[el] :  "Non Compilato" }
                              key={uniqueIdBuilder()}/>
                          } else {
                              return Object.keys(itm[el]).map(internalItm => {
                                if(internalItm.toLowerCase()
                                    .includes("base64")) 
                                  return;

                                if(internalItm.toLocaleLowerCase() === "residenza") {
                                  return <FormBoxField 
                                  title="Comune residenza" 
                                  value={itm[el][internalItm] ? itm[el][internalItm] :  "Non Compilato" } 
                                    key={uniqueIdBuilder()}/>
                                }else if (internalItm.toLocaleLowerCase() === "domicilio") {
                                  return <FormBoxField 
                                    title="Indirizzo residenza" 
                                    value={itm[el][internalItm] ? itm[el][internalItm] :  "Non Compilato" } 
                                    key={uniqueIdBuilder()}/>
                                }
                                else {
                                  let title = titleExtractor(internalItm.toLowerCase().includes("carta") 
                                    || internalItm.toLowerCase().includes("disabilit")  
                                    ? internalItm.substring(0,internalItm.length - 1) + "à" : internalItm);

                                    if(title.includes("fiscale"))
                                      title = "Codice Fiscale";
                                    //optimize

                                  return <FormBoxField 
                                    title={title} 
                                    value={itm[el][internalItm] ? itm[el][internalItm] :  "Non Compilato" } 
                                    key={uniqueIdBuilder()}/>
                                }
                              })
                          }
                        })
                      }
                    </SummaryCard>
                    )
                }
              }) 
          }
      </div>
    )
  } 

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              Object.keys(data).map(key => {
                if(data[key] !== undefined || data[key] !== []) {
                  if(key === "isee" || key === "richiedente" || key === "beneficiario") 
                    return customerRenderer(key);
                  else if (key === "preferenze"){
                    return preferencesRenderer("Preferenze");
                  }
                }
              }) 
            }
          </div>
        </div>
      </div>
    </div>
  );
}
