import React from "react";
import { useDispatch } from "react-redux";
import stepSlice from "../context/StepsContext";
import { titleExtractor, titleNormalizer, uniqueIdBuilder } from "../util/Dynamics";
import PopupTextField from "./ui-el/PopupTextField";

import "../global.css"

function SelectionFormBox(props) {
    const dispatcher = useDispatch();

    const dynamicChanges = (e) => {
        const id = titleNormalizer(e.target.parentNode.children[0].textContent);

        if(props.objectify) {
            const selected = e.target.options[e.target.selectedIndex];

            dispatcher(stepSlice
                .actions
                .dynamicSave({id:props.oKey,internalId: id, data: {id: selected.value,value: selected.textContent},remove: false}));

                return;
        }

        dispatcher(stepSlice
            .actions
            .dynamicSave({id:props.oKey,internalId: id, data: e.target.options[e.target.selectedIndex].textContent,remove: false}));
    }

    const classSet = (e) => {
        const id = titleNormalizer(e.target.parentNode.children[0].textContent);
 
        dispatcher(stepSlice
            .actions
            .dynamicSave({id:props.oKey,internalId: id, data: e.target.value,remove: false}));

    }

    return (
        <section className="it-page-section" id="school-choice">
            <div className="cmp-card mb-40">
            <div className={`card has-bkg-grey p-big p-4  ${props.disp === "disp" ? "box-remover" : ""}`}>
                <div className={`card-header border-0 p-0 mb-lg-30` }>
                <div className="d-flex">
                    <h2 className="title-xxlarge mb-0">
                    {titleExtractor(props.showTitle)}
                    </h2>
                </div>
                <p className="subtitle-small mb-0">
                    {props.description}
                </p>
                </div>
                <div className={`card-body p-0  ${props.disp === "disp" ? "box-enlighter" : ""}`}>
                    <div className="select-wrapper p-big p-4 select-partials">
                        <p style={{display: "none"}}>{props.title}</p>
                        <label htmlFor="select-school" className="visually-hidden">
                            {props.selectTitle}
                        </label>
                        <select id="select-school" required onChange={dynamicChanges}>
                            <option selected="selected" disabled>
                                {props?.firstSelection ? props.firstSelection :  "Seleziona una scuola"}
                            </option>
                            {
                                Array.isArray(props?.choices) &&
                                    props.choices?.map(option => {
                                        if(props.defaultValue === option.value) {

                                            return (
                                                <option selected="selected" value={option.id} key={uniqueIdBuilder()}>
                                                    {option.value}
                                                </option>
                                            )
                                        } else {
                                            return (
                                                <option value={option.id} key={uniqueIdBuilder()}>
                                                    {option.value}
                                                </option>
                                            )
                                        }
                                    })
                            }
                        </select>
                    </div>
                </div>
                <br/>
                {
                    props.title === "SCUOLA" &&
                        <PopupTextField 
                            id="classe"
                            defaultValue={props.classDefaultValue}
                            onBlur={classSet}
                            fieldType="text" 
                            fieldTitle="Classe"
                            description="Inserisci la classe*"/>                }
                {props.children}
            </div>
            </div>
        </section>
    )
}

export default SelectionFormBox;