import { useSelector } from "react-redux";
import { ImuApi } from "../Apis/ImuApi";
import HelpBox from "./HelpBopx";
import SuccessfullPopup from "./SuccessfullPopup";
import { useEffect, useState } from "react";

//TODO: ADD EMAIL ADDRS
function SuccessfullBox(props) {
    const ctx = useSelector(state => state.stepSlice.data.imu);
    const [fail,setFail]  = useState(false);
    
    const onTest = async () => {
        try{  
            if(props.service !== "PTI") {
                if(props.link) {
                    const linkSource = `data:application/pdf;base64,${props.link}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "ricevuta.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    return;
                }
            } 

            const res = await ImuApi.downloadPDF({imu: ctx,f_24: true});

            const linkSource = `data:application/pdf;base64,${res.data}`;
            const downloadLink = document.createElement("a");
            const fileName = "F24.pdf";
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
          }catch(err) {
            console.log("errore");
            console.log(err);
            setFail(true);

            setTimeout(() =>{
                setFail(false);
            },5000);
          }   
    }

    const pdfBtnShower = () => {
        let pdfShower = true;

        switch(props.service) {
            case "SPA": 
                pdfShower = false;
                break;
            case "SRA":
                pdfShower = false;
                break;
            case "SSD": 
                pdfShower = false;
                break;

            default: 
                break;
        }

        return pdfShower;
    }

    useEffect(() => {
        window.scroll(0,0);
    },[])
    
    return (
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-12 col-lg-10">
                <div className="cmp-hero">
                    <section className="it-hero-wrapper bg-white align-items-start">
                        <div className="it-hero-text-wrapper pt-0 ps-0 pb-40 pb-lg-60">
                        <div className="categoryicon-top d-flex">
                            <svg
                            className="icon icon-success mr-10 icon-sm mb-1"
                            aria-hidden="true"
                            >
                            <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use>
                            </svg>
                            <h1
                            className="text-black hero-title"
                            data-element="page-name"
                            >
                            {props.successData.title}
                            </h1>
                        </div>
                        <p className="titillium hero-text">
                            {props.successData.message}
                            <br />
                            <br />
                            Abbiamo inviato il riepilogo all’email:
                            <br />
                            <strong id="email-recap">{props.emailToSend}</strong>
                        </p>
                        </div>
                        
                    </section>
                {/* scarica ricevuta */}
                {
                    pdfBtnShower() &&
                        <button onClick={onTest}
                            type="button" className="btn btn-outline-primary bg-white fw-bold">
                            <span className="rounded-icon">
                            <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-download"></use>
                            </svg>
                            </span>
                        
                            {
                                props.service === "PTI" ?
                                    <span className="">Scarica F24</span> :
                                    <span className="">Scarica la ricevuta</span>
                            }
                        </button>
                }
  
                {
                    fail &&
                        <SuccessfullPopup 
                        isError={true} 
                        msg="F24 NON PRESENTE SE IMPOSTA E' 0"/>
                }
                </div>
                {
                    props.service !== "SPA" && props.service !== "SSD" && props.service !== "SRA" &&
                        <p class="mt-3" style={{marginTop: '50px !important'}}>
                            <a href="https://servizi.stellanello.nemea.cloud/" class="t-primary text-paragraph">Consulta la richiesta</a>
                            <span class="text-paragraph"> nella tua area riservata</span>
                        </p>
                }
               </div>
            </div>


            <div className="row justify-content-center mb-3 mb-md-5">
    
            {
                props.service === "SPA" || props.service === "SSD" || props.service === "SRA" ?
                    <></> :
                    <>
                        <hr className="d-none d-lg-block mt-40 mb-0"></hr>
                            <div className="col-12 col-lg-10">
                            <div className="cmp-icon-list">
                                <h2 className="title-xxlarge mt-40 mb-2 mb-lg-4 mb-lg-4" id="related-service">Servizi correlati</h2>
                                <div className="link-list-wrapper">
                                    <ul className="link-list">
                                    <li className="shadow mb-4">
                                        <a className="list-item icon-left t-primary title-small-semi-bold" href="#">
                                        <span className="list-item-title-icon-wrapper">
                                            <svg className="icon icon-sm align-self-start icon-color" aria-hidden="true"><use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-settings"></use></svg>
                                            <span className="list-item-title title-small-semi-bold">Richiesta appuntamento</span>
                                        </span>
                                        </a>
                                
                                    </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
        
        <div >
            {/* <StarForm/> */}
            <HelpBox
            helpBoxInfo={{
                muniQuestionLink:
                "https://stellanello.nemea.cloud/domande-frequenti/",
                muniHelpLink: "https://stellanello.nemea.cloud/servizi/assistenza/",
                greenNumber: "tel:+39 0182 668000",
                bookLink: "https://stellanello.nemea.cloud/servizi/prenotazioni/",
                rDisservice: "",
            }}
            />
        </div>
        </div>
        
    )
}


export default SuccessfullBox;

