import React, { useState } from "react";
import CustomerCardHeader from "../registryForms/CustomerCardHeader";
import CustomerCard from "../registryForms/CustomerCard";
import {AddConsumerPopup} from "../AddingForms/AddConsumerPopup";
import { uniqueIdBuilder } from "../../util/Dynamics";

function CustomerFormBox(props) {
    const [show,setShow] = useState(false);
    const [typeData,setTypeData] = useState("");
    
    const setType = (type) => {
        setTypeData(type);
    }

    const addCustomer = () => {
        setShow(true);
    }

    const close = () => {
        setShow(false);
    }
    
    return ( 
       <>
        <section className="it-page-section" id={props.id}>
            <div className="cmp-card mb-40">
                { 
                    show &&
                        <AddConsumerPopup close={close} id={props.title} typeData={typeData} service={props.service}/>    
                }    
                    
                <div className="card has-bkg-grey shadow-sm p-big">
                    <CustomerCardHeader description={props.description} type={props.type} title={props.title} setType={setType}/>

                    <div className="card-body p-0">
                        {
                            props?.cards !== undefined &&
                                props.cards.map(card => {
                                    if(card && card.length > 0) {
                                        return <CustomerCard
                                            info={card} key={uniqueIdBuilder()}
                                            title={props.title}
                                            id={props.id}
                                            service={props.service}/>  
                                    }
                                })
                        }
                        {
                            props.id === "Autore della segnalazione" && props.cards[0].length === 0 &&
                                <button type="button" className="btn plus-text mt-20 p-0" onClick={addCustomer}>
                                    <span className="rounded-icon">
                                        <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                        </svg>
                                    </span>
                                    <span className>Aggiungi {props?.id.toLowerCase()}</span>
                                </button>
                        }
                         {
                           props.id.toLowerCase() === "richiedente" && props.cards[0].length === 0 &&
                                <button type="button" className="btn plus-text mt-20 p-0" onClick={addCustomer}>
                                    <span className="rounded-icon">
                                        <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                        </svg>
                                    </span>
                                    <span className>Aggiungi {props?.id.toLowerCase()}</span>
                                </button>
                        }
                        {
                           props.id !== "Autore della segnalazione" && props.id.toLowerCase() !== "richiedente" &&
                                <button type="button" className="btn plus-text mt-20 p-0" onClick={addCustomer}>
                                    <span className="rounded-icon">
                                        <svg className="icon icon-primary icon-sm" aria-hidden="true">
                                        <use href="../assets/bootstrap-italia/dist/svg/sprites.svg#it-plus-circle" />
                                        </svg>
                                    </span>
                                    <span className>Aggiungi {props?.id.toLowerCase()}</span>
                                </button>
                        }
                       
                    </div>
                </div> 
            
            </div>
        </section>
       </>
  )
}


export default CustomerFormBox;