import { apiAuth } from "./Configs/AxiosConfigs"

export const MessageApi = {

    getMessages: async function () {
        const response = await apiAuth.request({
          url: "/messages/",
          method: "GET",
        })  
        return response.data;
      }
 
}
