import React, { useEffect, useState } from "react";
import SideList from "../../UI-component/SideList";
import SelectionFormBox from "../../UI-component/SelectionFormBox";
import { OfficeAPI } from "../../Apis/OfficeAPI";
import { useSelector} from "react-redux";
import { checkValidity } from "../../util/Dynamics";
import { useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import ErrorAlert from "../../UI-component/ErrorAlert";

function First() {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data.ufficio);
    const [offices,setOffices] = useState([]);
    const [error,setError] = useState({
        office: true,
    });

    const getData = async () => {
        const offices = [];

        try{
            const res = await OfficeAPI.getOffices();

            if(!res.success) {
                return;
            }

            res?.data.forEach(i => {
                offices.push({id: i.id,value: i.title + " - " +  i.address})
            });            
            
            setOffices(offices);
        }catch(err) {
            console.log(err);
        }
    }

    const errorChecker = () => {  
        let isError = true;

        if(ctx?.ufficio) 
            isError = !checkValidity(ctx.ufficio);
        
        setError(prev => {
            return {...prev,office: isError};
        });

        dispatcher(stepSlice.actions.switchError({error: isError}))
      }
    

    useEffect(() => {
        getData();
        errorChecker()
    },[])

    return (
        <React.Fragment>
            <SideList infoList={[{element: "Ufficio", id: "#office" }]}/>

            <div className="col-12 col-lg-8 offset-lg-1 section-wrapper">
                <div className="steppers-content" aria-live="polite">
                  <div className="it-page-sections-container">
                    {
                        <ErrorAlert errors={[{ name: "Ufficio", id: "#isee-info" }]} err={error.office} />
                    }

                    <SelectionFormBox
                        showTitle={"Ufficio*"}
                        title="Ufficio"
                        description="Scegli l'ufficio a cui vuoi richiedere l'appuntamento"
                        selectTitle="Seleziona l'ufficio"
                        choices={offices}
                        defaultValue={ctx?.ufficio?.value ? ctx?.ufficio.value : "Seleziona un'opzione"}
                        oKey={"Ufficio"}
                        objectify={true}
                    />
                    <br></br>
                  </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default First;