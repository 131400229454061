import React, {useState,useEffect} from "react";
import SideList from "../../UI-component/SideList";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import PopupTextField from "../../UI-component/ui-el/PopupTextField";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import ErrorAlert from "../../UI-component/ErrorAlert";

import stepSlice from "../../context/StepsContext";
import { useSelector,useDispatch } from "react-redux";

import { onDataChange2,onFileSet2, titleNormalizer } from "../../util/Dynamics";

function DatiSegnalazione(props) {
  const dispatcher = useDispatch();
  const disserviceCtxData = useSelector(state => state.stepSlice.data.disservizio);
  const authorData = useSelector(state => state.stepSlice.data.autore_della_segnalazione);
  const ctxErr = useSelector(state => state.stepSlice.data.error);
  
  const [error,setError] = useState({
    autore: false,
    location: false,
    disservice: false
  });
  
  const [disserviceData,setDisserviceData] = useState({ 
    disservizio: {
      cerca_un_luogo: disserviceCtxData?.cerca_un_luogo ? disserviceCtxData?.cerca_un_luogo : "",
      tipo_di_disservizio: disserviceCtxData?.tipo_di_disservizio ? disserviceCtxData?.tipo_di_disservizio :  "",
      titolo: disserviceCtxData?.titolo ? disserviceCtxData?.titolo : "",
      dettagli: disserviceCtxData?.dettagli ? disserviceCtxData?.dettagli :  "",
      base64_immagini:  disserviceCtxData?.base64_immagini ? disserviceCtxData?.base64_immagini :  "",
      immagini:  disserviceCtxData?.immagini ? disserviceCtxData?.immagini :  ""
    }
  });

  const onBlur = (e) => {
    const id = titleNormalizer(e.target.id);

    dispatcher(stepSlice.actions.dynamicSave({id: "disservizio",internalId: id, data: disserviceData.disservizio[id]})); 
  }

  const errorChecker = () => { 
    let isError = false;

      if(authorData.length <= 0) {
        setError(prev => {
          return {...prev,autore: true};
        });
        isError = true;
      }
  
      for(let i of authorData) {
        if(i?.selected) {
          setError(prev => {
            return {...prev,autore: false};
          }); 
        }else {
          setError(prev => {
            return {...prev,autore: true};
          });
          isError = true;
        }
      }
  
      if(disserviceData.disservizio.cerca_un_luogo === "") {
        setError(prev => {
          return {...prev,location: true};
        });
  
        isError = true;
      }else {
        setError(prev => {
          return {...prev,location: false};
        });
      }
  
      if(!disserviceData.disservizio.tipo_di_disservizio
         || !disserviceData.disservizio.titolo 
         || !disserviceData.disservizio.dettagli
         || !disserviceData.disservizio.immagini) {

        setError(prev => {
          return {...prev,disservice: true};
        });
  
        isError = true;
      } else {
        setError(prev => {
          return {...prev,disservice: false};
        });
      }  

      if(!isError)
          document.getElementById('step1Next').removeAttribute("disabled");
      else if(!ctxErr && isError) 
        document.getElementById('step1Next').setAttribute("disabled","disabled");         
  }

  useEffect(() => {
    errorChecker();
  },[disserviceData])

  useEffect(() => {
    return () => {
      document.getElementById('step1Next').removeAttribute("disabled");
    }
  },[])
  setTimeout(() => {
    document.getElementById("step1Next")
      .addEventListener("focusin", () => {
        dispatcher(stepSlice.actions
          .dynamicSave({id: "disservizio", data: disserviceData.disservizio})); 
      },{once: true});
  }, 100);


  return (
      <div className="row">    
        <SideList
          infoList={[
            { element: "Luogo", id: "#report-place" },
            { element: "Disservizio", id: "#report-info" },
            { element: "Autore della segnalazione", id: "#report-author" },
          ]}/>

        <div className="col-12 col-lg-8 offset-lg-1">
          <div className="steppers-content" aria-live="polite">
            <div className="it-page-sections-container"> 

              {
                  <ErrorAlert errors={[{ name: "Autore della segnalazione", id: "#isee-info" }]} err={error.autore}/>
              }

              {
                  <ErrorAlert errors={[{ name: "Luogo", id: "#isee-info" }]} err={error.location}/>
              }

              {
                  <ErrorAlert errors={[{ name: "Disservizio", id: "#isee-info" }]} err={error.disservice}/>
              }
   
              <CustomerFormBox id="Autore della segnalazione"
                cards={[authorData]}
                type={false}
                description="Informazione su di te"
                title="Autore della segnalazione"
                service={props.info.code}
              />

              <SummaryCard mod={false} type="Luogo">
                <PopupTextField defaultValue={disserviceCtxData?.cerca_un_luogo ? disserviceCtxData?.cerca_un_luogo : ""} 
                  onChange={(e) => {onDataChange2(e,setDisserviceData)}} 
                  id="cerca_un_luogo" fieldType="text" fieldTitle="Indirizzo*" description="Inserisci l'indirizzo"/>

                {/* <PositionButton/> */}
              </SummaryCard>

              <SummaryCard mod={false} cardTitle="" type="Disservizio">
                <PopupTextField 
                  defaultValue={disserviceData.disservizio?.tipo_di_disservizio ? disserviceData.disservizio.tipo_di_disservizio : disserviceCtxData?.tipo_di_disservizio}
                  id="tipo_di_disservizio"
                  onChange={(e) => {onDataChange2(e,setDisserviceData)}}
                  fieldType="text" 
                  fieldTitle="Tipo di disservizio*"
                  description="Inserire il tipo di disservizio"/>

                <PopupTextField 
                  defaultValue={disserviceCtxData?.titolo ? disserviceCtxData.titolo : ""}
                  id="titolo"
                  fieldTitle="Titolo*"
                  onChange={(e) => {onDataChange2(e,setDisserviceData)}}
                  fieldType="text" description="Inserire il titolo per il disservizio"/>
                <PopupTextField 
                  defaultValue={disserviceCtxData?.dettagli ? disserviceCtxData.dettagli : ""} 
                  onChange={(e) => {onDataChange2(e,setDisserviceData)}} fieldType="text" 
                  id="dettagli"
                  fieldTitle="Dettagli*" 
                  description="Inserire al massimo 200 caratteri"/>
                <PopupTextField 
                  onChange={(e) => {onFileSet2(e,setDisserviceData)}} 
                  fieldType="file" 
                  id="immagini"
                  fieldTitle="Immagini*" description="Seleziona un' immagine allegare alla segnalazione (formato consentito .jpg)"/>
              </SummaryCard>
            </div>
          </div>
        </div>
      </div>
  )
}


export default DatiSegnalazione;